// @ts-ignore

import {z} from '@cheddarup/util'

import {orgMetadataIdentifierSchema} from './auth'

export const authTokenTypeSchema = z.enum(['TabMember', 'User'])

export const authTokenSchema = z.object({
  id: z.number().optional(),
  claims: z.object({
    jti: z.string(),
    exp: z.number(),
    insecure: z.boolean().optional(),
    data: z.object({
      client_id: z.string(),
      payload: z.discriminatedUnion('tokenType', [
        z.object({
          tokenType: authTokenTypeSchema.extract(['TabMember']),
          member: z.object({
            id: z.number(),
            email: z.string(),
          }),
        }),
        z.object({
          tokenType: authTokenTypeSchema.extract(['User']),
          member: z
            .object({
              id: z.number(),
              email: z.string(),
            })
            .optional(),
          user: z
            .object({
              email: z.string(),
              uuid: z.string(),
            })
            .optional(),
        }),
      ]),
    }),
  }),
  header: z.discriminatedUnion('id_typ', [
    z.object({
      id_typ: z.literal('id'),
      typ: authTokenTypeSchema,
      sub: z.number(),
      alg: z.literal('HS256'),
    }),
    z.object({
      id_typ: z.literal('uuid'),
      typ: authTokenTypeSchema,
      sub: z.string(),
      alg: z.literal('HS256'),
    }),
  ]),
  expires_at: z.string(),
})

export const orgBrandingSchema = z.object({
  heading: z.string(),
  subheading: z.string(),
  formSubmitButtonColor: z.string(),
  backgroundColor: z.string(),
  contentWhite: z.boolean(),
  contentColor: z.string(),
})

export const orgAssetsSchema = z.object({
  logo: z.string(),
  backgroundImage: z.string(),
  faqUrl: z.string(),
  howItWorksVideoUrl: z.string(),
  partnerBenefitsLink: z.string(),
  partnerUrl: z.string(),
})

export const orgMetadataSchema = z.object({
  domain: z.string(),
  name: z.string(),
  country: z.string(),
  type: z.string(),
  organizationIdentifier: orgMetadataIdentifierSchema.nullable(),
  cobranding: z.object({
    enabled: z.boolean(),
  }),
  branding: orgBrandingSchema.nullable(),
  assets: orgAssetsSchema.nullable(),
  setupCompleted: z.boolean(),
  disableSignup: z.boolean(),
  requireInvitation: z.boolean(),
  formCountrySelectDisplayed: z.boolean(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type AuthTokenType = z.infer<typeof authTokenTypeSchema>
    // @ts-ignore
    type AuthToken = z.infer<typeof authTokenSchema>
    // @ts-ignore
    type OrgBranding = z.infer<typeof orgBrandingSchema>
    // @ts-ignore
    type OrgAssets = z.infer<typeof orgAssetsSchema>
    // @ts-ignore
    type OrgMetadata = z.infer<typeof orgMetadataSchema>
  }
}
