import {Filter, TimeDimensionRanged} from '@cubejs-client/core'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useState} from 'react'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {CubeFilterField} from 'src/components/CubeFilters'
import {CubeTable, CubeTableColumn} from 'src/components/CubeTable'
import {LinkButton} from 'src/components/LinkButton'
import {SearchForm} from 'src/components'

import {CubeFiltersPopover} from '../../ReportsPage/ReportsPage'
import {CreateCubeReportExportButton} from '../../ReportsPage/components/CubeReports'

export const ActiveCollectorsTable = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [filters, setFilters] = useState<Filter[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [timeDimensions, setTimeDimensions] = useState<TimeDimensionRanged[]>(
    [],
  )

  const query: Api.CubeQuery = useMemo(
    () => ({
      dimensions: [
        'Users.uuid',
        'Users.id',
        'Users.status',
        'Users.profilePicturePath',
        'Users.profilePictureMetadata',
        'Users.banksLast4',
        'Users.name',
        'Users.unit',
        'Users.email',
        'Users.collectionCount',
        'Users.balanceAvailable',
        'Users.createdAt',
      ],
      measures: ['Payments.totalPaid'],
      filters: [
        ...filters,
        searchValue.length > 0
          ? {
              or: [
                {
                  member: 'Users.name',
                  operator: 'contains' as const,
                  values: [searchValue],
                },
                {
                  member: 'Users.email',
                  operator: 'contains' as const,
                  values: [searchValue],
                },
              ],
            }
          : undefined,
      ].filter((f) => !!f),
      timeDimensions,
    }),
    [filters, searchValue, timeDimensions],
  )

  const sortingInitialState = useMemo(
    () => [{id: 'Users.createdAt', desc: true}],
    [],
  )

  const columns: CubeTableColumn[] = useMemo(
    () => [
      {
        path: 'Users.name',
        headerTitle: 'Organizer',
        // @ts-expect-error
        Cell: ({row: {original: user}}) => (
          <WebUI.HStack className="gap-4">
            <SharpAvatar
              image={user['Users.profilePicturePath'] as any}
              name={user['Users.name'] as any}
              size={36}
            />
            <div>
              <div className="font-normal text-ds-sm">{user['Users.name']}</div>
              <div className="font-normal text-ds-xs text-gray400">
                {user['Users.email']}
              </div>
            </div>
          </WebUI.HStack>
        ),
      },
      {
        path: 'Users.unit',
        headerTitle: 'Unit',
        maxSize: 160,
      },
      {path: 'Users.createdAt', maxWidth: 160},
      {path: 'Users.balanceAvailable', maxWidth: 160},
      {path: 'Payments.totalPaid', maxWidth: 180},
    ],
    [],
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <span className="font-normal text-ds-md">Activly Collecting Users</span>
        <WebUI.HStack className="gap-3">
          <SearchForm
            className="h-9 w-[360px]"
            size="compact"
            placeholder="Enter name or Email"
            onSubmit={(values) => setSearchValue(values.term)}
          />
          <CubeFiltersPopover
            aria-label="Active collectors filters"
            filters={filters}
            timeDimensions={timeDimensions}
            onFiltersApply={(newFilters) => {
              setFilters(newFilters.filters)
              setTimeDimensions(newFilters.timeDimensions)
            }}
          >
            <CubeFilterField dimension="Users.createdAt" />
            <CubeFilterField dimension="Users.collectionCount" />
          </CubeFiltersPopover>
          <CreateCubeReportExportButton
            query={query}
            countMeasure="Users.count"
          />
        </WebUI.HStack>
      </WebUI.HStack>

      <WebUI.Panel>
        <CubeTable
          className="[&_.TableView-headerGroup]:px-2 [&_.TableViewRow]:px-2"
          initialState={{sorting: sortingInitialState}}
          query={query}
          columns={columns}
          countMeasure="Users.count"
          getRowProps={(row) =>
            ({
              as: LinkButton,
              className: 'px-0 h-auto [font-size:inherit] text-left',
              to: `/reporting/collectors/${row.original['Users.uuid']}`,
            }) as any
          }
        />
      </WebUI.Panel>
    </WebUI.VStack>
  )
}
