import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {AccountProfileItem} from 'src/views/CollectionsPage/components/ManagerAccounts'
import {isAccountDetailsFieldsFilled} from '@cheddarup/core'

import config from '../config'
import {AbsoluteLinkButton, LinkButton} from './LinkButton'
import {useManagerRole} from './ManageRoleProvider'
import {CurrentUserAvatar} from './CurrentUserAvatar'

export interface UserMenuProps {
  forNewNav?: boolean // TODO: Temporary workaround to show additional items in UserMenu till completion of Gated Feature
}

export const UserMenu = React.forwardRef<HTMLButtonElement, UserMenuProps>(
  ({forNewNav = false, ...restProps}, forwardedRef) => {
    const sessionQuery = api.auth.session.useQuery()
    const alertsCountQuery = api.alerts.list.useQuery(undefined, {
      select: (alerts) => alerts.length,
    })

    const isAnyFieldNeeded =
      sessionQuery.data &&
      sessionQuery.data.stripe_data.fieldsNeeded.length > 0 &&
      isAccountDetailsFieldsFilled(sessionQuery.data.user)

    const org = sessionQuery.data?.organization_data

    const [managerRole, setManagerRoleId] = useManagerRole()

    return (
      <WebUI.Menu>
        <WebUI.MenuButton
          ref={forwardedRef}
          className="px-1"
          iconAfter={<WebUI.PhosphorIcon icon="caret-down" />}
          variant="text"
          size="large"
          {...restProps}
        >
          <UserAvatar />
        </WebUI.MenuButton>

        <WebUI.MenuList className="min-w-[200px]">
          {(forNewNav ||
            (sessionQuery.data &&
              sessionQuery.data.manager_roles.length > 0)) && (
            <>
              <WebUI.MenuItem className="my-1 [height:unset]">
                {managerRole ? (
                  <AccountProfileItem
                    className="py-1"
                    showInUserMenu
                    managerRole={managerRole}
                  />
                ) : (
                  <AccountProfileItem
                    className="py-1"
                    isPersonal
                    showInUserMenu
                    showPlan={forNewNav}
                    plan={sessionQuery.data?.capabilities?.plan}
                    managerRole={
                      {
                        id: sessionQuery.data?.user.id,
                        email: sessionQuery.data?.user.email,
                        name: sessionQuery.data?.user.display_name,
                        full_name: sessionQuery.data?.user.display_name,
                        profile_pic:
                          sessionQuery.data?.user.profile_pic ?? null,
                        canCreate: true,
                        profile: {
                          uiClientFlags:
                            sessionQuery.data?.user.profile.uiClientFlags,
                        },
                      } as any
                    }
                  />
                )}
              </WebUI.MenuItem>
              <WebUI.Separator variant="primary" className="mb-1" />
            </>
          )}
          {!managerRole && !!alertsCountQuery.data && (
            <WebUI.MenuItem
              as={AbsoluteLinkButton}
              iconBefore={
                <WebUI.PhosphorIcon
                  className="text-orange-50"
                  icon="warning-circle-fill"
                />
              }
              to="my-account/alerts"
            >
              {`You have ${alertsCountQuery.data} ${
                alertsCountQuery.data > 1 ? 'alerts' : 'alert'
              }`}
            </WebUI.MenuItem>
          )}
          {!managerRole && (
            <WebUI.MenuItem
              className={WebUI.cn(isAnyFieldNeeded && 'text-error')}
              as={AbsoluteLinkButton}
              preserveSearch
              iconBefore={
                isAnyFieldNeeded && (
                  <WebUI.PhosphorIcon
                    className="text-orange-50"
                    icon="warning-circle-fill"
                  />
                )
              }
              to="my-account"
            >
              My Account
            </WebUI.MenuItem>
          )}
          {sessionQuery.data?.partner_admin?.access && (
            <WebUI.MenuItem as={LinkButton} to="/reporting/dashboard">
              Partner Dashboard
            </WebUI.MenuItem>
          )}
          {!forNewNav &&
            !managerRole &&
            !sessionQuery.data?.capabilities.subscribed_to_team && (
              <WebUI.MenuItem as={AbsoluteLinkButton} to="i/plans">
                Upgrade
              </WebUI.MenuItem>
            )}
          <WebUI.MenuItem
            as={WebUI.AnchorButton}
            href={(org as any)?.faqUrl ?? config.links.supportPage}
            target="_blank"
            rel="noreferrer"
          >
            Knowledge Center
          </WebUI.MenuItem>
          <WebUI.MenuItem as={LinkButton} to="/logout">
            Log Out
          </WebUI.MenuItem>
          {sessionQuery.data && sessionQuery.data.manager_roles.length > 0 && (
            <div className="-mx-1 !-mb-1 flex flex-col bg-natural-80">
              <WebUI.Separator variant="primary" className="mb-1" />
              <div className="px-4 py-1 font-normal text-ds-xs uppercase">
                Switch To
              </div>
              {sessionQuery.data.manager_roles
                .filter((mr) => mr.id !== managerRole?.id)
                .map((mr) => (
                  <WebUI.MenuItem
                    key={mr.id}
                    className="!hover:bg-natural-100 [height:unset]"
                    onClick={() => setManagerRoleId(mr.id)}
                  >
                    <AccountProfileItem
                      className="py-1"
                      showInUserMenu
                      managerRole={mr}
                    />
                  </WebUI.MenuItem>
                ))}
              {!!managerRole && sessionQuery.data && (
                <WebUI.MenuItem
                  className="!hover:bg-natural-100 [height:unset]"
                  onClick={() => setManagerRoleId(null)}
                >
                  <AccountProfileItem
                    className="py-1"
                    isPersonal
                    showInUserMenu
                    managerRole={
                      {
                        id: sessionQuery.data.user.id,
                        email: sessionQuery.data.user.email,
                        name: sessionQuery.data.user.display_name,
                        full_name: sessionQuery.data.user.display_name,
                        profile_pic: sessionQuery.data.user.profile_pic ?? null,
                        canCreate: true,
                        profile: {
                          uiClientFlags:
                            sessionQuery.data.user.profile.uiClientFlags,
                        },
                      } as any
                    }
                  />
                </WebUI.MenuItem>
              )}
            </div>
          )}
        </WebUI.MenuList>
      </WebUI.Menu>
    )
  },
)

// MARK: – UserAvatar

export interface UserAvatarProps
  extends Pick<WebUI.ImageProps, 'errorFallback'>,
    Util.SetOptional<Pick<WebUI.AvatarProps, 'size'>, 'size'>,
    React.ComponentPropsWithoutRef<'div'> {}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  errorFallback,
  size = 30,
  className,
  ...restProps
}) => {
  const sessionQuery = api.auth.session.useQuery()
  const hasAlertsQuery = api.alerts.list.useQuery(undefined, {
    select: (alerts) => alerts.length > 0,
  })
  const [managerRole] = useManagerRole()

  const alertVisible =
    !managerRole &&
    !!sessionQuery.data &&
    (sessionQuery.data.stripe_data.fieldsNeeded.length > 0 ||
      !isAccountDetailsFieldsFilled(sessionQuery.data.user) ||
      !!hasAlertsQuery.data)

  return (
    <div className={WebUI.cn('relative', className)} {...restProps}>
      <CurrentUserAvatar size={size} errorFallback={errorFallback} />
      {alertVisible && (
        <WebUI.PhosphorIcon
          className="-right-[7px] -top-[5px] absolute text-orange-50"
          width={14}
          icon="warning-circle-fill"
        />
      )}
    </div>
  )
}
