import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import {useFormik, useUpdateEffect} from '@cheddarup/react-util'
import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {
  InferResponse,
  api,
  endpoints,
  useCreateFormMutation,
  useUpdateFormMutation,
} from '@cheddarup/api-client'
import {FieldGroupTypePicker} from 'src/components/FieldsEdit/FieldGroupTypePicker'
import {FieldGroupsPreview} from 'src/components/FieldsEdit/FieldGroupsPreview'
import {FieldsEdit, FieldsEditValue} from 'src/components/FieldsEdit/FieldsEdit'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import {FieldGroupEditList} from 'src/components/FieldsEdit/FieldGroupEditList'
import FormBuilderIcon from 'src/images/FormBuilderIcon.svg'
import {useSaveFields} from 'src/hooks/fields'

import AddWaiverPage from './AddWaiverPage'

const FormFormPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const formId = Number(urlParams.form)
  const formFormRef = useRef<FormFormInstance>(null)
  const [submitting, setSubmitting] = useState(false)
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const dialogDefaultHide = useRef<(() => void) | null>(null)
  const alertRef = useRef<WebUI.DialogInstance>(null)

  let form: InferResponse<typeof endpoints.tabForms.detail> | undefined
  if (formId) {
    form = api.tabForms.detail.useSuspenseQuery({
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        formId,
      },
    }).data
  }

  useEffect(() => {
    // HACK: prevent dialog hide
    if (dialogRef.current) {
      dialogDefaultHide.current = dialogRef.current.hide
      dialogRef.current.hide = () => {
        if (formFormRef.current?.isDirty()) {
          alertRef.current?.show()
        } else {
          dialogDefaultHide.current?.()
        }
      }
    }
  }, [])

  if (form?.options.isWaiver) {
    return <AddWaiverPage waiver={form} />
  }

  return (
    <WebUI.Modal
      ref={dialogRef}
      aria-label="Form form"
      className="[&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader className="border-b-0">
        <WebUI.PageHeader
          graphics={<img src={FormBuilderIcon} alt="" />}
          subheading="Click on your selected question types to customize your form."
        >
          Form Builder
        </WebUI.PageHeader>
      </WebUI.ModalHeader>
      <FormForm
        ref={formFormRef}
        className="flex-auto overflow-hidden"
        collectionId={Number(urlParams.collection)}
        formId={urlParams.form ? Number(urlParams.form) : null}
        onDidSave={() => navigate('..')}
      />

      <WebUI.PageToolbar>
        <WebUI.PageToolbarSubmitButton
          arrow={false}
          loading={submitting}
          onClick={async () => {
            setSubmitting(true)
            try {
              await formFormRef.current?.submit()
            } finally {
              setSubmitting(false)
            }
          }}
        >
          Save Form
        </WebUI.PageToolbarSubmitButton>
      </WebUI.PageToolbar>

      <DirtyFormConfirmAlert
        ref={alertRef}
        onProceed={() => dialogDefaultHide.current?.()}
      />
    </WebUI.Modal>
  )
}

// MARK: – FormForm

interface FormFormInstance {
  submit: () => Promise<any>
  isDirty: () => boolean
}

interface FormFormProps extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formId: number | null
  onDidSave: () => void
}

const FormForm = React.forwardRef<FormFormInstance, FormFormProps>(
  (
    {collectionId, formId, onDidSave, className, ...restProps},
    forwardedRef,
  ) => {
    const media = WebUI.useMedia()
    const navigate = useNavigate()
    const growlActions = WebUI.useGrowlActions()
    const formQuery =
      formId == null
        ? {data: null}
        : api.tabForms.detail.useSuspenseQuery({
            pathParams: {
              tabId: collectionId,
              formId,
            },
          })
    const form = formQuery.data
    const fieldsQuery =
      formId == null
        ? {data: null}
        : api.fields.formList.useSuspenseQuery(
            {
              pathParams: {
                tabId: collectionId,
                formId,
              },
            },
            {
              select: (fields) => Util.sort(fields).asc((f) => f.position),
            },
          )
    const {data: collection} = api.tabs.detail.useSuspenseQuery({
      pathParams: {
        tabId: collectionId,
      },
    })
    const createFormMutation = useCreateFormMutation()
    const updateFormMutation = useUpdateFormMutation()
    const saveFields = useSaveFields()
    const fieldsEditValueRef = useRef<FieldsEditValue[]>([])
    const noQuestionsAlertRef = useRef<WebUI.DialogInstance>(null)
    const drawerRef = useRef<WebUI.DialogInstance>(null)
    const [isDirty, setIsDirty] = useState(false)
    const [previewEnabled, setPreviewEnabled] = useState(false)

    const isBasicCollection = !collection?.is_pro && !collection?.is_team
    const enforceAddlGatFeatures = !collection?.options?.doNotEnforceAddlGated
    const disableProFields =
      collection?.status !== 'draft' &&
      isBasicCollection &&
      enforceAddlGatFeatures

    const formik = useFormik({
      validationSchema: Yup.object().shape({
        name: Yup.string().required('Required'),
      }),
      initialValues: {
        name: form?.name ?? '',
      },
      onSubmit: async (values) => {
        const localFields = fieldsEditValueRef.current.flatMap(
          (fev) => fev.fields,
        )

        const someCheckboxOrMultipleChoiceFieldsEmpty = localFields
          .filter(
            (f) =>
              f.field_type === 'checkbox' || f.field_type === 'multiple_choice',
          )
          .some((f) => 'values' in f && !f.values)
        const someDescriptionEmpty = localFields
          .filter((f) => f.field_type === 'layout_description')
          .some(
            (f) =>
              'values' in f && Util.stripMarkdown(f.values ?? '').length === 0,
          )

        if (someCheckboxOrMultipleChoiceFieldsEmpty) {
          growlActions.show('error', {
            title: 'Error',
            body: 'Checkbox and dropdown questions require at least one option',
          })
          return
        }
        if (someDescriptionEmpty) {
          growlActions.show('error', {
            title: 'Error',
            body: "Description can't be empty",
          })
          return
        }
        if (!noQuestionsAlertRef.current?.visible && localFields.length === 0) {
          noQuestionsAlertRef.current?.show()
        } else {
          const saveFormMutation = form
            ? updateFormMutation
            : createFormMutation
          const savedForm = await saveFormMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              formId: form?.id as any,
            },
            body: {
              name: values.name,
              description: '',
              options: {
                isWaiver: false,
                fieldSets: fieldsEditValueRef.current.map(
                  (fev) => fev.fieldSet,
                ),
              },
            },
          })

          await saveFields({
            tabId: collectionId,
            tabObjectId: savedForm.id,
            tabObjectType: 'form',
            existingFields: fieldsQuery.data ?? [],
            newFields: localFields,
          })

          onDidSave()
        }
      },
    })

    useImperativeHandle(
      forwardedRef,
      () => ({
        isDirty: () => isDirty,
        submit: formik.submitForm,
      }),
      [formik.submitForm, isDirty],
    )

    useUpdateEffect(() => {
      setIsDirty(formik.dirty)
    }, [formik.dirty])

    return (
      <FieldsEdit
        initialFieldSets={form?.options.fieldSets ?? undefined}
        initialFields={
          form?.description
            ? [
                ...(fieldsQuery.data ?? []),
                {
                  id: -1,
                  name: 'description',
                  required: false,
                  position: -1,
                  field_type: 'layout_description',
                  values: form.description,
                  metadata: {fieldSetId: null},
                } as Api.TabObjectField,
              ]
            : (fieldsQuery.data ?? [])
        }
        onInit={(initialFieldsEditValue) => {
          fieldsEditValueRef.current = initialFieldsEditValue
        }}
        onChange={(newFieldsEditValue) => {
          const localFieldSets = newFieldsEditValue.map((fev) => fev.fieldSet)
          const localFields = newFieldsEditValue.flatMap((fev) => fev.fields)
          setIsDirty(
            formik.dirty ||
              !Util.deepEqual(localFieldSets, form?.options.fieldSets) ||
              !Util.deepEqual(localFields, fieldsQuery.data),
          )

          fieldsEditValueRef.current = newFieldsEditValue

          drawerRef.current?.hide()
        }}
      >
        <div className={WebUI.cn('flex', className)} {...restProps}>
          {media.sm && !previewEnabled && (
            <FieldGroupTypePicker
              disableProFields={disableProFields}
              showBadgeOnProFields={isBasicCollection && enforceAddlGatFeatures}
            />
          )}

          <form
            className="grow overflow-auto px-4 pb-4 sm:px-8 sm:pb-8"
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <div className="flex flex-col gap-4">
              <div className="sticky top-0 z-[1] flex flex-col gap-4 bg-natural-100 pt-4">
                <div className="flex gap-4">
                  {previewEnabled ? (
                    <WebUI.Heading className="flex-[1]" as="h2">
                      {formik.values.name}
                    </WebUI.Heading>
                  ) : (
                    <WebUI.FormField
                      className="flex-[1]"
                      error={formik.errors.name}
                    >
                      <WebUI.Input
                        name="name"
                        placeholder="Give your form a name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WebUI.FormField>
                  )}

                  <WebUI.Switch
                    size="compact"
                    state={previewEnabled}
                    onChange={(event) =>
                      setPreviewEnabled(event.target.checked)
                    }
                  >
                    Preview
                  </WebUI.Switch>
                </div>

                <WebUI.Separator />
              </div>

              {previewEnabled ? <FieldGroupsPreview /> : <FieldGroupEditList />}

              {!media.sm && (
                <WebUI.Drawer
                  ref={drawerRef}
                  disclosure={
                    !previewEnabled && (
                      <WebUI.DialogDisclosure className="self-start">
                        Add Question
                      </WebUI.DialogDisclosure>
                    )
                  }
                >
                  <FieldGroupTypePicker
                    disableProFields={disableProFields}
                    showBadgeOnProFields={
                      isBasicCollection && enforceAddlGatFeatures
                    }
                  />
                </WebUI.Drawer>
              )}
            </div>
          </form>
        </div>

        <WebUI.Alert
          ref={noQuestionsAlertRef}
          aria-label="Saving form without questions confirmation"
        >
          <WebUI.AlertHeader>You haven't added questions</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text="Are you sure you'd like to save this form without any questions?"
            actions={
              <>
                <WebUI.AlertActionButton execute={() => formik.submitForm()}>
                  Save Form
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </WebUI.Alert>
        <UpgradeRequiredAlert
          visible={
            !formId &&
            collection &&
            collection.status !== 'draft' &&
            !collection.is_pro &&
            collection.reportsAvailable.activeFormsCount +
              collection.reportsAvailable.activeSignupsCount >=
              1
          }
          onDidHide={() => navigate('..')}
        />
      </FieldsEdit>
    )
  },
)

// MARK: – DirtyFormConfirmAlert

export interface DirtyFormConfirmAlertProps extends WebUI.AlertProps {
  onProceed?: () => void
}

export const DirtyFormConfirmAlert = React.forwardRef<
  WebUI.DialogInstance,
  DirtyFormConfirmAlertProps
>(({onProceed, className, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Confirm closing form modal"
    className={WebUI.cn('[&_.Alert-closeButton]:invisible', className)}
    {...restProps}
  >
    {(dialog) => (
      <>
        <WebUI.AlertHeader>
          Are you sure you want to close this form?
        </WebUI.AlertHeader>
        <WebUI.AlertContentView
          text="You haven’t saved your form and your information will be lost."
          actions={
            <>
              <WebUI.AlertActionButton onClick={() => onProceed?.()}>
                Close Form
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton
                onClick={(event) => {
                  event.preventDefault()
                  dialog.hide()
                }}
              >
                Cancel
              </WebUI.AlertCancelButton>
            </>
          }
        />
      </>
    )}
  </WebUI.Alert>
))

export default FormFormPage
