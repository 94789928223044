import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import {useLocation, useNavigate} from 'react-router-dom'
import React, {useState} from 'react'
import {useStripe} from '@stripe/react-stripe-js'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateOrgMemberInvitesMutation,
  useCreateOrgMemberTestInvitesMutation,
} from '@cheddarup/api-client'
import {Elements} from 'src/components/Stripe'
import {CreateTokenBankAccountData} from '@stripe/stripe-js'

import {InvitedCollectorsTable} from './InvitedCollectorsTable'
import {ActiveCollectorsTable} from './ActiveCollectorsTable'
import {RecipientsTableView} from './/RecipientsTableView'

const CollectorsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {data: userData} = api.auth.session.useQuery()

  const canInviteCollectors = userData?.partner_admin?.org?.canInviteCollectors
  return (
    <WebUI.SegmentedTabs
      initialSelectedId="active"
      selectedId={location.pathname.includes('/active') ? 'active' : 'invited'}
      onChangeCurrentId={(newId) => navigate(`/reporting/collectors/${newId}`)}
    >
      <WebUI.VStack className="gap-4">
        <WebUI.HStack
          className="items-center justify-between gap-3 p-4"
          as={WebUI.Panel}
        >
          <WebUI.HStack className="items-center gap-10">
            <WebUI.Heading as="h2">Collectors</WebUI.Heading>
            <WebUI.SegmentedTabList
              className={WebUI.cn(
                'min-w-[180px]',
                canInviteCollectors ? 'block' : 'hidden',
              )}
            >
              <WebUI.SegmentedTab id="active">
                Actively Collecting
              </WebUI.SegmentedTab>
              <WebUI.SegmentedTab id="invited">All Members</WebUI.SegmentedTab>
            </WebUI.SegmentedTabList>
          </WebUI.HStack>

          {canInviteCollectors && (
            <Elements>
              <InviteCollectorsModal
                disclosure={
                  <WebUI.DialogDisclosure variant="default">
                    Invite Collectors
                  </WebUI.DialogDisclosure>
                }
              />
            </Elements>
          )}
        </WebUI.HStack>

        <WebUI.TabPanel id="active">
          <ActiveCollectorsTable />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="invited">
          <InvitedCollectorsTable />
        </WebUI.TabPanel>
      </WebUI.VStack>
    </WebUI.SegmentedTabs>
  )
}

// MARK: – InviteCollectorsModal

const InviteCollectorsModal = ({className, ...restProps}: WebUI.ModalProps) => {
  const [recipients, setRecipients] = useState<
    Array<Partial<InviteCollectorsFormValues>>
  >([])
  const stripe = useStripe()
  const growlActions = WebUI.useGrowlActions()
  const {data: userData} = api.auth.session.useQuery()
  const createOrgMemberInvitesMutation = useCreateOrgMemberInvitesMutation()
  const createOrgMemberTestInvitesMutation =
    useCreateOrgMemberTestInvitesMutation()

  const handleSendTest = async () => {
    try {
      const email = userData?.user?.email
      if (email) {
        await createOrgMemberTestInvitesMutation.mutateAsync({body: {email}})
        growlActions.show('success', {
          body: `A test invitation was successfully sent to ${email}.`,
        })
      }
    } catch {
      growlActions.show('error', {body: 'Failed to send the test invitation!'})
    }
  }

  return (
    <WebUI.Modal
      className={WebUI.cn(
        'min-h-[min(600px,100%)] [&_>_.ModalContentView]:max-w-screen-xl',
        className,
      )}
      initialVisible={false}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.HStack className="grow">
            <WebUI.VStack className="flex-[0_0_50%] gap-8 p-8">
              <WebUI.Heading as="h2">Invite Collectors</WebUI.Heading>
              <InviteCollectorsForm
                onSubmit={(recipient) =>
                  setRecipients((prevRecipients) => [
                    ...prevRecipients,
                    recipient,
                  ])
                }
              />
            </WebUI.VStack>
            <WebUI.Separator orientation="vertical" />
            <RecipientsTableView
              className="flex-[0_0_50%] p-8"
              recipients={recipients}
              onRecipientsChange={(newRecipients) =>
                setRecipients(newRecipients)
              }
            />
          </WebUI.HStack>
          <WebUI.Separator />
          <WebUI.HStack className="items-center justify-end gap-4 px-8 py-4">
            <WebUI.Button variant="link" onClick={handleSendTest}>
              Send me a test
            </WebUI.Button>
            <WebUI.Button
              variant="primary"
              loading={createOrgMemberInvitesMutation.isPending}
              disabled={!recipients.length}
              onClick={async () => {
                try {
                  const createStripeTokenFuncs = recipients.map(
                    (r) => () =>
                      r.bankAccountNumber && r.bankRoutingNumber
                        ? stripe?.createToken('bank_account', {
                            account_holder_name: `${r.first_name} ${r.last_name}`,
                            country: 'US',
                            account_number: r.bankAccountNumber,
                            routing_number: r.bankRoutingNumber,
                          } as CreateTokenBankAccountData)
                        : null,
                  )

                  const stripeResults = await Promise.all(
                    createStripeTokenFuncs.map((createST) => createST()),
                  )
                  const stripeErrors = stripeResults
                    .map((sr) => sr?.error)
                    .filter((error) => !!error)

                  if (stripeErrors.length > 0) {
                    const stripeError = stripeErrors[0]
                    growlActions.show('error', {
                      body: stripeError?.message ?? '',
                    })
                    return
                  }

                  await createOrgMemberInvitesMutation.mutateAsync({
                    body: {
                      profiles: recipients.map(
                        (
                          {bankAccountNumber, bankRoutingNumber, ...r},
                          idx,
                        ) => ({
                          ...r,
                          stripe: {
                            banks: stripeResults[idx]
                              ? [
                                  {
                                    nickname: `${r.first_name} ${r.last_name}`,
                                    token: stripeResults[idx]?.token?.id ?? '',
                                  },
                                ]
                              : undefined,
                          },
                        }),
                      ),
                    },
                  })
                  growlActions.show('success', {
                    body: `${recipients.length} invitation${
                      recipients.length > 1 ? 's were' : ' was'
                    } successfully sent!`,
                  })
                  setRecipients([])
                  dialog.hide()
                } catch {
                  growlActions.show('error', {
                    body: 'Failed to send the invitations!',
                  })
                }
              }}
            >
              Send Invitation
            </WebUI.Button>
          </WebUI.HStack>
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – InviteCollectorsForm

interface InviteCollectorsFormValues {
  email: string
  first_name: string
  last_name: string
  name: string
  currency: 'USD'
  business_name: string
  bankAccountNumber: string
  bankRoutingNumber: string
}

interface InviteCollectorsFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  onSubmit: (values: InviteCollectorsFormValues) => void
}

const InviteCollectorsForm = ({
  onSubmit,
  ...restProps
}: InviteCollectorsFormProps) => {
  const businessNamePrefixQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session?.partner_admin?.org?.defaultStripeAccountSettings?.company
        ?.name || session?.partner_admin?.org?.name,
  })

  const formik = useFormik<Omit<InviteCollectorsFormValues, 'currency'>>({
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid format').required('Required'),
      business_name: Yup.string().required('Required'),
    }),
    initialValues: {
      first_name: '',
      last_name: '',
      name: '',
      email: '',
      business_name: '',
      bankAccountNumber: '',
      bankRoutingNumber: '',
    },
    onSubmit: (values) => {
      onSubmit({
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        name: `${values.first_name} ${values.last_name}`,
        currency: 'USD',
        business_name: `${businessNamePrefixQuery.data}${values.business_name}`,
        bankAccountNumber: values.bankAccountNumber,
        bankRoutingNumber: values.bankRoutingNumber,
      })
    },
  })

  return (
    <WebUI.Form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.FormFieldGroup>
        <WebUI.FormField
          required
          label="First Name"
          error={formik.errors.first_name}
        >
          <WebUI.Input
            name="first_name"
            placeholder="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        <WebUI.FormField
          required
          label="Last Name"
          error={formik.errors.last_name}
        >
          <WebUI.Input
            name="last_name"
            placeholder="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>
      <WebUI.FormField required label="Email" error={formik.errors.email}>
        <WebUI.Input
          name="email"
          type="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField
        required
        label="Organization Name"
        error={formik.errors.business_name}
      >
        <WebUI.Input
          name="business_name"
          placeholder="Organization Name"
          value={`${businessNamePrefixQuery.data}${formik.values.business_name}`}
          onChange={(event) => {
            const newBusinessName = event.target.value.slice(
              businessNamePrefixQuery.data?.length,
            )
            formik.setFieldValue('business_name', newBusinessName)
          }}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormFieldGroup>
        <WebUI.FormField
          label="Bank Account Number"
          error={formik.errors.bankAccountNumber}
        >
          <WebUI.Input
            name="bankAccountNumber"
            placeholder="Bank Account Number (optional)"
            value={formik.values.bankAccountNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField
          label="Bank Routing Number"
          error={formik.errors.bankRoutingNumber}
        >
          <WebUI.Input
            name="bankRoutingNumber"
            placeholder="Bank Routing Number (optional)"
            value={formik.values.bankRoutingNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.Button className="self-start" type="submit">
        Add
      </WebUI.Button>
    </WebUI.Form>
  )
}

export default CollectorsPage
