// TODO: Cover with tests stage 1 – will need jsdom or similar env
const copyToClipboard = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    console.error('Failed to copy text to clipboard', err)
  }
}

export {copyToClipboard}
