import * as Yup from 'yup'
import React, {useMemo} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateCartTimeSlotMutation} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

import useCart, {
  useCreateCartIfNotExists,
  useEnhancedCreateCartTimeSlotMutation,
} from '../views/c/hooks/useCart'
import {Field, FieldValue, makeFieldsYupSchema} from './FieldSetList'
import {guessError} from 'src/helpers/error-utils'
import {PayerBrandKitColors} from '@cheddarup/core'

// MARK: – SignupSpotsViewFormModal

export const SIGNUP_NAME_FIELD_SENTINEL_VALUE = '__PLACEHOLDER__'

export interface SignupSpotsViewFormModalProps
  extends WebUI.ModalProps,
    Pick<SignupSpotsViewFormProps, 'signup' | 'spots' | 'onDidSubmit'> {
  brandKitColors: PayerBrandKitColors
}

export const SignupSpotsViewFormModal = React.forwardRef<
  WebUI.DialogInstance,
  SignupSpotsViewFormModalProps
>(
  (
    {
      'aria-label': ariaLabel = 'Sign up spots form',
      signup,
      spots,
      onDidSubmit,
      initialVisible = false,
      className,
      brandKitColors,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Modal
      aria-label={ariaLabel}
      ref={forwardedRef}
      className={WebUI.cn(
        'sm:[&_>_.ModalContentView]:max-w-[512px] sm:[&_>_.ModalContentView]:rounded-extended',
        className,
      )}
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />

          <SignupSpotsViewForm
            className="min-h-0 grow overflow-y-auto [&_>_.Form-inner]:h-full"
            brandKitColors={brandKitColors}
            signup={signup}
            spots={spots}
            onDidSubmit={() => {
              dialog.hide()
              onDidSubmit?.()
            }}
          />
        </>
      )}
    </WebUI.Modal>
  ),
)

// MARK: – SignupSpotsViewForm

export interface SignupSpotsViewFormTimeSlotValues {
  isNameFieldsVisible: boolean
  quantity: string
  fields: Record<number, FieldValue>
}

export type SignupSpotsViewFormValues = Record<
  number,
  SignupSpotsViewFormTimeSlotValues
>

export interface SignupSpotsViewFormProps
  extends React.ComponentPropsWithoutRef<'form'> {
  signup: Api.PublicTabSignup
  spots: Api.PublicSignUpSpot[]
  onDidSubmit?: () => void
  brandKitColors: PayerBrandKitColors
}

export function SignupSpotsViewForm({
  signup,
  spots,
  onDidSubmit,
  brandKitColors,
  ...restProps
}: SignupSpotsViewFormProps) {
  const urlParams = useParams()
  const {cart} = useCart()
  const userQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user,
  })
  const createCartIfNotExists = useCreateCartIfNotExists()
  const [, createCartTimeSlotAsync] = useEnhancedCreateCartTimeSlotMutation()
  const updateCartTimeSlotMutation = useUpdateCartTimeSlotMutation()
  const growlActions = WebUI.useGrowlActions()

  const collectionSlug = String(urlParams.tabSlug)

  const commentField = signup.options.comment.enabled
    ? (signup.fields.find((f) => f.metadata.timeSlotFieldType === 'comment') ??
      null)
    : null
  const nameFields = useMemo(
    () =>
      signup.fields.filter((f) => f.metadata.timeSlotFieldType !== 'comment'),
    [signup],
  )

  const isInEditMode = spots
    .flatMap((spot) => spot.time_slots)
    .every((ts) => cart?.time_slots.some((cTs) => cTs.time_slot.id === ts.id))

  const formik = useFormik({
    validationSchema: () =>
      Yup.object().shape(
        Util.mapToObj(
          spots.flatMap((spot) => spot.time_slots),
          (ts) => [
            ts.id,
            Yup.object().shape({
              isNameFieldsVisible: Yup.bool(),
              quantity: ts.available_quantity
                ? Yup.number()
                    .required('Required')
                    .min(1, 'Required')
                    .max(
                      ts.available_quantity ?? 1,
                      `Only ${Util.pluralize(
                        'spot',
                        ts.available_quantity ?? 1,
                        true,
                      )} available`,
                    )
                : Yup.number().required('Required').min(1, 'Required'),
              fields: Yup.object().when('isNameFieldsVisible', {
                is: true,
                // biome-ignore lint/suspicious/noThenProperty:
                then: (schema) =>
                  schema.shape(makeFieldsYupSchema(signup.fields)),
                otherwise: (schema) =>
                  schema.shape(
                    commentField ? makeFieldsYupSchema([commentField]) : {},
                  ),
              }),
            }),
          ],
        ),
      ),
    initialValues: Util.mapToObj(
      spots.flatMap((spot) => spot.time_slots),
      (ts) => {
        const cartTs = cart?.time_slots.find(
          (cTs) => cTs.time_slot.id === ts.id,
        )

        return [
          ts.id,
          {
            isNameFieldsVisible: cartTs?.cart_field_views.some(
              (ifv) =>
                (ifv.metadata.timeSlotFieldType === 'first_name' ||
                  ifv.metadata.timeSlotFieldType === 'last_name') &&
                !!ifv.value &&
                ifv.value !== SIGNUP_NAME_FIELD_SENTINEL_VALUE,
            ),
            quantity: String(cartTs?.quantity ?? 1),
            fields: Util.mapToObj(signup.fields, (field) => {
              const fieldView = cartTs?.cart_field_views?.find(
                (fv) => fv.item_field_id === field.id,
              )

              let value: string | undefined = ''

              if (fieldView) {
                value = fieldView.value
              }
              if (field.metadata.timeSlotFieldType === 'first_name') {
                value =
                  cart?.member?.name?.trim().split(/\s+/)[0] ||
                  userQuery.data?.first_name
              }
              if (field.metadata.timeSlotFieldType === 'last_name') {
                value =
                  cart?.member?.name?.trim().split(/\s+/)[1] ||
                  userQuery.data?.last_name
              }

              return [field.id, value]
            }),
          },
        ]
      },
    ),
    onSubmit: async (values) => {
      const cartTimeSlotsCount = Util.sumBy(
        cart?.time_slots.filter(
          (ts) => ts.time_slot.spot.signup.id === signup.id,
        ) ?? [],
        (ts) => ts.quantity,
      )
      const timeSlotsCount = Util.sumBy(Object.values(values), (tsValues) =>
        Number(tsValues.quantity),
      )

      if (
        signup.options.perPersonSpotLimit.enabled &&
        cartTimeSlotsCount + timeSlotsCount >
          signup.options.perPersonSpotLimit.value
      ) {
        growlActions.show('error', {
          body: `You can signup only up to ${Util.pluralize('spot', signup.options.perPersonSpotLimit.value, true)}`,
        })
        return
      }

      // Create a cart first manually (if not exists) otherwise the batched
      // create time slot mutations will create multiple carts
      const cartUuid = await createCartIfNotExists()

      const promises = Util.arrayFromObject(values, async (tsId, tsValues) => {
        const cartFieldValues = Util.arrayFromObject(
          tsValues.fields,
          (fieldId, fieldValue) => {
            const isHiddenField =
              !tsValues.isNameFieldsVisible &&
              nameFields.some((nameField) => nameField.id === Number(fieldId))

            return {
              item_field_id: Number(fieldId),
              value: isHiddenField
                ? SIGNUP_NAME_FIELD_SENTINEL_VALUE
                : ((fieldValue as string | undefined) ?? ''),
            }
          },
        )

        const body = {
          time_slot_id: Number(tsId),
          quantity: Number(tsValues.quantity),
          cart_field_values: cartFieldValues,
        }

        const cartTs = cart?.time_slots.find(
          (cTs) => cTs.time_slot.id === Number(tsId),
        )

        if (cartTs) {
          if (!cart) {
            return
          }

          return updateCartTimeSlotMutation.mutateAsync({
            pathParams: {
              timeSlotId: cartTs.id,
              tabId: collectionSlug,
              cartUuid: cart.uuid,
            },
            body,
          })
        }

        return createCartTimeSlotAsync({
          body,
          pathParams: {
            tabId: collectionSlug,
            cartUuid,
          },
        })
      })

      try {
        await Promise.all(promises)
        onDidSubmit?.()
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <WebUI.Form
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.VStack className="grow bg-teal-80">
        <WebUI.VStack className="grow gap-4 p-8">
          <WebUI.Heading className="text-ds-lg">
            {isInEditMode ? 'Edit' : 'Save'} your spots
          </WebUI.Heading>

          <WebUI.Separator variant="black" />

          <WebUI.VStack className="grow gap-4">
            {spots.flatMap((spot) =>
              spot.time_slots.map((timeSlot) => {
                const initialTsValues = formik.initialValues[timeSlot.id]

                return (
                  <React.Fragment key={timeSlot.id}>
                    <WebUI.VStack className="gap-3">
                      <WebUI.VStack className="gap-0_5">
                        <WebUI.Heading as="h4">{spot.name}</WebUI.Heading>
                        {timeSlot.options.startTime &&
                          timeSlot.options.endTime && (
                            <WebUI.Text className="text-ds-sm">
                              {Util.formatDateRange(
                                timeSlot.options.startTime,
                                timeSlot.options.endTime,
                                {
                                  dateTimeFormatOptions: {
                                    timeZoneName: 'short',
                                    timeZone: signup.options.timeZone,
                                  },
                                },
                              )}
                            </WebUI.Text>
                          )}
                      </WebUI.VStack>
                      {Util.stripMarkdown(spot.description ?? '').replaceAll(
                        '\n',
                        '',
                      ).length > 0 && (
                        <WebUI.MarkdownParagraph
                          className="text-ds-sm"
                          markdown={spot.description ?? ''}
                        />
                      )}

                      {!!commentField && (
                        <Field
                          field={commentField}
                          defaultValue={
                            initialTsValues?.fields?.[commentField.id] ??
                            undefined
                          }
                          onValueChange={(fieldId, newValue) =>
                            formik.setFieldValue(
                              `[${timeSlot.id}].fields[${fieldId}]`,
                              newValue,
                            )
                          }
                        />
                      )}
                      <WebUI.FormField
                        className="[&_>_.FormField-caption]:inline"
                        label="Qty"
                        caption={`${
                          timeSlot.available_quantity == null
                            ? 'Unlimited spots'
                            : Util.pluralize(
                                'spot',
                                timeSlot.available_quantity ?? 0,
                                true,
                              )
                        } available`}
                        error={(formik.errors[timeSlot.id] as any)?.quantity}
                      >
                        <WebUI.NumberStepper
                          className="w-18"
                          value={formik.values[timeSlot.id]?.quantity}
                          onValueChange={(newQuantity) =>
                            formik.setFieldValue(
                              `[${timeSlot.id}].quantity`,
                              newQuantity,
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </WebUI.FormField>

                      {nameFields.length > 0 && (
                        <WebUI.Checkbox
                          name={`[${timeSlot.id}].isNameFieldsVisible`}
                          state={
                            formik.values[timeSlot.id]?.isNameFieldsVisible
                          }
                          onChange={(event) =>
                            formik.setFieldValue(
                              `[${timeSlot.id}].isNameFieldsVisible`,
                              event.target.checked,
                            )
                          }
                          onBlur={formik.handleBlur}
                        >
                          Display an alternate name on the sign up
                        </WebUI.Checkbox>
                      )}

                      <WebUI.Disclosure
                        visible={
                          formik.values[timeSlot.id]?.isNameFieldsVisible
                        }
                      >
                        <WebUI.DisclosureContent className="pb-3">
                          <WebUI.FormFieldGroup>
                            {nameFields.map((field) => {
                              const initialFieldValue =
                                initialTsValues?.fields?.[field.id] ?? undefined

                              return (
                                <Field
                                  key={field.id}
                                  field={field}
                                  defaultValue={
                                    initialFieldValue ===
                                    SIGNUP_NAME_FIELD_SENTINEL_VALUE
                                      ? undefined
                                      : initialFieldValue
                                  }
                                  onValueChange={(fieldId, newValue) =>
                                    formik.setFieldValue(
                                      `[${timeSlot.id}].fields[${fieldId}]`,
                                      newValue,
                                    )
                                  }
                                />
                              )
                            })}
                          </WebUI.FormFieldGroup>
                        </WebUI.DisclosureContent>
                      </WebUI.Disclosure>
                    </WebUI.VStack>

                    <WebUI.Separator variant="black" />
                  </React.Fragment>
                )
              }),
            )}
          </WebUI.VStack>
        </WebUI.VStack>

        <WebUI.HStack
          className={
            'sticky bottom-0 justify-end border-t bg-natural-100 px-4 py-6 sm:bg-teal-80'
          }
        >
          <WebUI.Button
            className="grow sm:grow-0 sm:self-start"
            roundness="capsule"
            type="submit"
            loading={formik.isSubmitting}
            size="large"
            style={{backgroundColor: brandKitColors.primaryButton}}
          >
            Save Spots and Continue
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.VStack>
    </WebUI.Form>
  )
}

// MARK: – TimeSlotViewForm
