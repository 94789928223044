// TODO: Cover with tests stage 2
// Based on https://github.com/ariakit/ariakit/blob/edd7d19fc43645d73d92d2f7086eb759a131d156/packages/ariakit-core/src/utils/dom.ts#L272C8-L290C2
export function getScrollingElement(
  element?: Element | null,
): HTMLElement | Element | null {
  if (!element) {
    return null
  }
  if (element.clientHeight && element.scrollHeight > element.clientHeight) {
    const {overflowY} = getComputedStyle(element)
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'
    if (isScrollable) {
      return element
    }
  } else if (element.clientWidth && element.scrollWidth > element.clientWidth) {
    const {overflowX} = getComputedStyle(element)
    const isScrollable = overflowX !== 'visible' && overflowX !== 'hidden'
    if (isScrollable) {
      return element
    }
  }
  return (
    getScrollingElement(element.parentElement) ||
    document.scrollingElement ||
    document.body
  )
}
