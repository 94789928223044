import {ForwardRefComponent} from '@cheddarup/react-util'
import {memoize} from '@cheddarup/util'
import React, {useEffect, useMemo, useState} from 'react'

import {PhosphorIcon, PhosphorIconName} from '../icons'
import {cn, downloadFile} from '../utils'
import {Ellipsis} from './Ellipsis'
import {HStack, VStack} from './Stack'
import {IconButton} from './IconButton'
import {Image} from './Image'
import {Skeleton} from './Skeleton'
import {Tooltip, TooltipAnchor, TooltipContent} from './Tooltip'

export type RemoteFileOverviewSize = 'default' | 'compact'

export interface RemoteFileOverviewProps {
  size?: RemoteFileOverviewSize
  deleteable?: boolean
  // TODO: rename url to reflect it accepts blobs
  url: string | File | Blob
  onDelete?: () => void
}

export const RemoteFileOverview = React.forwardRef(
  (
    {
      as: Comp = 'div',
      size = 'default',
      deleteable,
      url,
      onDelete,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {metadata, isLoading: isMetaLoading} = useRemoteFileMetadata(url)

    const filename =
      typeof url === 'string'
        ? (getFileNameByUrl(url) ?? undefined)
        : 'name' in url
          ? url.name
          : undefined

    return (
      <Comp
        ref={forwardedRef}
        className={cn(
          `RemoteFileOverview RemoteFileOverview--${size}`,
          'flex flex-row items-center gap-2',
          className,
        )}
        {...restProps}
      >
        <HStack className="RemoteFileOverview-detailsContainer min-w-0 gap-3">
          <RemoteFileDisplay
            className="RemoteFileOverview-remoteFileDisplay flex-0"
            size={size}
            url={url}
          />

          {size === 'default' && (
            <VStack className="RemoteFileOverview-details min-w-0 gap-0_5 font-light text-ds-sm">
              <Ellipsis>
                {filename || (typeof url === 'string' ? url : '')}
              </Ellipsis>
              {isMetaLoading ? (
                <Skeleton width={60} height={12} />
              ) : (
                <Ellipsis>
                  {((metadata?.size ?? 0) * 0.001).toFixed(2)} KB
                </Ellipsis>
              )}
            </VStack>
          )}
        </HStack>
        {deleteable && (
          <Tooltip>
            <TooltipAnchor
              render={
                <IconButton
                  className="RemoteFileOverview-deleteButton text-ds-lg text-teal-50"
                  size="default_alt"
                  onClick={onDelete}
                >
                  <PhosphorIcon icon="trash" />
                </IconButton>
              }
            />
            <TooltipContent>Delete attachment</TooltipContent>
          </Tooltip>
        )}
        <Tooltip>
          <TooltipAnchor
            render={
              <IconButton
                className={cn(
                  'RemoteFileOverview-downloadButton flex-0 p-0 text-ds-lg text-teal-50',
                  size === 'default' && 'h-10 w-10',
                  size === 'compact' && 'h-[30px] w-[30px]',
                )}
                size="default_alt"
                onClick={() => downloadFile(url, filename)}
              >
                <PhosphorIcon icon="download-simple" />
              </IconButton>
            }
          />
          <TooltipContent>Download attachment</TooltipContent>
        </Tooltip>
      </Comp>
    )
  },
) as ForwardRefComponent<'div', RemoteFileOverviewProps>

// MARK: – RemoteFileDisplay

export type RemoteFileDisplaySize = RemoteFileOverviewSize

export interface RemoteFileDisplayProps {
  className?: string
  size?: RemoteFileDisplaySize
  url: string | File | Blob
}

export const RemoteFileDisplay = ({
  className,
  size = 'default',
  url,
}: RemoteFileDisplayProps) => {
  const {metadata, isLoading: isMetaLoading} = useRemoteFileMetadata(url)

  const objectURL = useMemo(
    () => (typeof url === 'string' ? null : URL.createObjectURL(url)),
    [url],
  )

  useEffect(() => {
    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL)
      }
    }
  }, [objectURL])

  const isImage = metadata?.contentType?.split('/')[0] === 'image'
  const height = {default: 40, compact: 30}[size]

  if (isMetaLoading) {
    return (
      <Skeleton className="!leading-[unset]" width={height} height={height} />
    )
  }

  return isImage ? (
    <Image
      className={cn(
        `RemoteFileDisplay-image RemoteFileDisplay-image--${size}`,
        'rounded',
        '[&_>_.Image-image]:object-cover',
        className,
      )}
      width={height}
      height={height}
      alt={
        typeof url === 'string'
          ? (getFileNameByUrl(url) ?? '')
          : 'name' in url
            ? url.name
            : ''
      }
      src={typeof url === 'string' ? url : objectURL}
    />
  ) : (
    <ContentTypeDisplay
      className={cn(
        `RemoteFileDisplay-contentTypeDisplay${`RemoteFileDisplay-contentTypeDisplay--${size}`}`,
        size === 'default' && 'p-2 text-ds-xl',
        size === 'compact' && 'text-ds-lg',
        className,
      )}
      style={size === 'compact' ? {width: height, height} : undefined}
      contentType={metadata?.contentType ?? null}
    />
  )
}

// MARK: – ContentTypeDisplay

export interface ContentTypeDisplayProps
  extends React.ComponentPropsWithoutRef<'div'> {
  contentType: string | null
}

export const ContentTypeDisplay = React.forwardRef<
  HTMLDivElement,
  ContentTypeDisplayProps
>(({contentType, className, ...restProps}, forwardedRef) => {
  const defaultParsedMimeType = {type: 'plain', subType: 'text'}
  const parsedMimeType = contentType
    ? (parseMimeType(contentType) ?? defaultParsedMimeType)
    : defaultParsedMimeType

  const iconName = ({
    pdf: 'file-pdf',
    csv: 'file-csv',
    msword: 'file-doc',
    'vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-doc',
    'vnd.ms-powerpoint': 'file-ppt',
    'vnd.openxmlformats-officedocument.presentationml.presentation': 'file-ppt',
    zip: 'file-zip',
  }[parsedMimeType.subType] ??
    {
      image: 'file-image',
      audio: 'file-audio',
      video: 'file-video',
      // application:
    }[parsedMimeType.type] ??
    'file-cloud') as PhosphorIconName

  return (
    <VStack
      ref={forwardedRef}
      className={cn(
        'ContentTypeDisplay',
        'items-center justify-center rounded bg-gray100 p-2 text-ds-xl',
        className,
      )}
      {...restProps}
    >
      <PhosphorIcon icon={iconName} className="ContentTypeDisplay-icon" />
    </VStack>
  )
})

// MARK: – Helpers

export function parseMimeType(mimeType: string) {
  const [type, rest] = mimeType?.split('/') ?? []
  const [subType] = rest?.split(';') ?? []

  if (type == null || subType == null) {
    return null
  }

  return {type, subType}
}

export function getFileNameByUrl(url: string) {
  try {
    const fileName = new URL(url).pathname.split('/').pop()
    if (!fileName) {
      return null
    }

    return decodeURIComponent(decodeURIComponent(fileName))
  } catch {
    return null
  }
}

export function useRemoteFileMetadata(url: string | File | Blob) {
  const [metadata, setMetadata] = useState<FileMetadata | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (typeof url === 'string') {
      getRemoteFileMetadata(url)
        .then((newMetadata) => setMetadata(newMetadata))
        .finally(() => setIsLoading(false))
        .catch(() => {
          // noop
        })
    } else {
      setIsLoading(false)
      setMetadata({size: url.size, contentType: url.type})
    }
  }, [url])

  return {metadata, isLoading}
}

export interface FileMetadata {
  size: number
  contentType: string | null
}

const getRemoteFileMetadataImpl = async (url: string) => {
  const xhr = new XMLHttpRequest()

  xhr.open('GET', url, true)
  xhr.send()

  return new Promise<FileMetadata>((resolve, reject) => {
    xhr.addEventListener('loadend', (event) => {
      if (event.lengthComputable) {
        const contentType = xhr.getResponseHeader('Content-Type')
        resolve({size: event.total, contentType})
      } else {
        reject(new Error('Missing `Content-Length` header'))
      }
      xhr.abort()
    })
  })
}

export const getRemoteFileMetadata = memoize(getRemoteFileMetadataImpl, {
  maxSize: Number.POSITIVE_INFINITY,
  isPromise: true,
})
