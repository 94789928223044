import {Helmet, HelmetProps} from 'react-helmet-async'
import {useMatch} from 'react-router-dom'
import ImagesUtils, {getSharpImageUrl} from 'src/helpers/ImagesUtils'
import * as Util from '@cheddarup/util'

import usePublicCollection from '../hooks/usePublicCollection'

const PayerPageHelmet = ({
  prioritizeSeoTags = true,
  ...restProps
}: HelmetProps) => {
  const {publicCollection} = usePublicCollection()
  const match = useMatch('/c/:tabSlug')
  const headerImageUrl = publicCollection.featuredImage
    ? ImagesUtils.getCroppedImageUrl(publicCollection.featuredImage, {
        width: 1024,
        height: 360,
      })
    : null

  const truncatedDescription = truncateStringWithFullWords(
    publicCollection.description ?? '',
  )
  window.prerenderReady = true

  return (
    <Helmet prioritizeSeoTags={prioritizeSeoTags} {...restProps}>
      {!publicCollection.allowIndexing && (
        <meta name="robots" content="noindex" />
      )}
      <meta
        name="description"
        property="description"
        content={truncatedDescription || ' ‏‏‎ '}
      />
      <meta property="og:description" content={truncatedDescription || ' ‏‏‎ '} />
      <meta
        property="twitter:description"
        content={truncatedDescription || ' ‏‏‎ '}
      />
      {headerImageUrl && <meta property="og:image" content={headerImageUrl} />}
      {headerImageUrl && <meta property="image" content={headerImageUrl} />}
      {headerImageUrl && (
        <meta property="twitter:image:src" content={headerImageUrl} />
      )}
      <meta property="og:url" content={`/c/${publicCollection.slug}`} />
      <meta itemProp="name" content={`${publicCollection.name} - Cheddar Up`} />
      <title>{publicCollection.name} - Cheddar Up</title>
      <meta
        property="og:title"
        content={`${publicCollection.name} - Cheddar Up`}
      />
      <meta
        property="twitter:title"
        content={`${publicCollection.name} - Cheddar Up`}
      />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@cheddarup" />
      <meta property="twitter:creator" content="@cheddarup" />
      <meta property="og:site_name" content="Cheddar Up" />
      <meta property="og:locale" content="en_US" />
      <meta property="fb:app_id" content="413118622200717" />
      <meta property="og:type" content="product.group" />
      {!!match && (
        <script type="application/ld+json">
          {payCollectionSD(publicCollection)}
        </script>
      )}
    </Helmet>
  )
}

// MARK: – Helpers

const truncateStringWithFullWords = (str: string) => {
  if (!str) {
    return ''
  }
  const regex = new RegExp(/^(.{155}\S*).*/)
  const trimmed = str.replace(regex, '$1')
  return `${trimmed}${trimmed === str ? '' : ' ...'}`
}

const payCollectionSD = (collection: Api.PublicTab) => {
  const image =
    (collection.headerImage &&
      getSharpImageUrl({path: collection.headerImage.upload_path})) ||
    collection.logo?.url ||
    'https://s3.amazonaws.com/assets.cheddarup.com/images/Logo_Color_Horizontal.png'
  const sd: Record<string, any> = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: Util.randomInteger(46, 50) / 10,
      ratingCount: Util.randomInteger(50, 300),
    },
    image,
    description: collection.description,
    name: collection.name,
    offers: collection.items?.map((item) => ({
      '@type': 'Offer',
      availability: `http://schema.org/${
        typeof item.available_quantity !== 'number' ||
        item.available_quantity > 0
          ? 'InStock'
          : 'SoldOut'
      }`,
      price: item.amount || 0,
      priceCurrency: 'USD',
    })),
  }

  return JSON.stringify(sd)
}

export default PayerPageHelmet
