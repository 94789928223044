// @ts-ignore
import {z} from '@cheddarup/util'

export const alertCategorySchema = z.enum([
  'new_bank_added',
  'need_more_information',
  'payments_disabled',
  'taxable_threshold_close',
  'subscription_payment_failed',
  'dispute_opened',
  'dispute_won',
  'dispute_lost',
  'two_factor_required',
  'misc',
])

export const alertSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  category: alertCategorySchema,
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  detail: z.object({
    referenceId: z.number().nullish(),
    header: z.string().optional(),
    body: z.string().optional(),
    buttonText: z.string().optional(),
  }),
  reference: z
    .object({
      tab_id: z.number().nullish(),
    })
    .passthrough(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type Alert = z.infer<typeof alertSchema>
    // @ts-ignore
    type AlertCategory = z.infer<typeof alertCategorySchema>
  }
}
