import {InferResponse, api, endpoints} from '@cheddarup/api-client'
import {
  excludeTicketHiddenFields,
  getTicketNumberFromTicketId,
} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useCallback, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import queryString from 'query-string'
import {ForwardRefComponent} from '@cheddarup/react-util'
import getCollectionFieldValueFormatted from 'src/helpers/getCollectionFieldValueFormatted'

import {Link} from './Link'

export type FormFieldViewsViewBy = 'respondents' | 'questions'

export interface FieldViewsListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  tabObjectId: number
  viewBy: string
  viewByMap?: Partial<Record<string, FormFieldViewsViewBy>>
  tabObjectType: QuestionListProps['tabObjectType']
}

export const FieldViewsList: React.FC<FieldViewsListProps> = ({
  viewBy: viewByProp,
  viewByMap,
  tabObjectType,
  ...restProps
}) => {
  const viewBy = viewByMap
    ? (((viewByMap as any)[viewByProp] as string | undefined) ?? viewByProp)
    : viewByProp

  switch (viewBy) {
    case 'respondents':
      return <RespondentList {...restProps} />
    case 'questions':
      return <QuestionList tabObjectType={tabObjectType} {...restProps} />
    default:
      return null
  }
}

// MARK: – RespondentList

export interface RespondentListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  tabObjectId: number
  RowComponent?: React.ComponentType<{
    tabObjectId: number
    tabMember?: InferResponse<typeof endpoints.tabMembers.list>[number]
  }>
}

export const RespondentList: React.FC<RespondentListProps> = ({
  collectionId,
  tabObjectId,
  RowComponent = RespondentListRow,
  className,
  ...restProps
}) => {
  const [sortBy] = useQueryParam('sortBy', StringParam)
  const [searchKeyword] = useQueryParam('search', withDefault(StringParam, ''))
  const respondentsQuery = api.tabMembers.list.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
      queryParams: {
        include_refunds: true,
      },
    },
    {
      select: (members) => {
        let sortedMembers = members
        if (sortBy === 'name') {
          sortedMembers = Util.sort(members).asc((m) =>
            m.name.split(/(\s+)/).slice().reverse().join(' '),
          )
        } else {
          sortedMembers = Util.sort(members).desc(
            (m) => m.payments[0]?.created_at,
          )
        }

        return sortedMembers
          .map((m) => ({
            ...m,
            payments: m.payments.map((p) => ({
              ...p,
              payment_items: p.payment_items.filter(
                (pi) =>
                  (pi.total === 0 ||
                    pi.amount_after_refund == null ||
                    pi.amount_after_refund > 0) &&
                  pi.tab_object_id === tabObjectId,
              ),
            })),
          }))
          .filter((m) =>
            m.payments.some((p) =>
              p.payment_items.some(
                (pi) =>
                  pi.item_field_views.length > 0 || pi.detail?.variant != null,
              ),
            ),
          )
          .filter(
            Util.fuzzyFilterIterator(searchKeyword, {iterator: (m) => m.name}),
          )
      },
    },
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-5', className)} {...restProps}>
      {respondentsQuery.isLoading ? (
        Array.from({length: 4})
          .fill(null)
          .map((_v, idx) => (
            <RowComponent key={idx} tabObjectId={tabObjectId} />
          ))
      ) : respondentsQuery.data && respondentsQuery.data.length > 0 ? (
        respondentsQuery.data?.map((tabMember) => (
          <RowComponent
            key={tabMember.id}
            tabObjectId={tabObjectId}
            tabMember={tabMember as any}
          />
        ))
      ) : (
        <WebUI.VStack
          className="items-center justify-center p-8"
          as={WebUI.Panel}
        >
          You don't have any responses
        </WebUI.VStack>
      )}
    </WebUI.VStack>
  )
}

// MARK: – QuestionList

interface QuestionListProps extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  tabObjectId: number
  tabObjectType: 'item' | 'form'
  tabObjectSubtype?: 'ticket'
}

export const QuestionList: React.FC<QuestionListProps> = ({
  collectionId,
  tabObjectId,
  tabObjectType,
  tabObjectSubtype,
  className,
  ...restProps
}) => {
  const responsesQuery = api.tabPayments.responseList.useQuery({
    pathParams: {
      tabId: collectionId,
      tabObjectId,
    },
  })
  const variantStatsQuery = api.tabPayments.variantStats.useQuery(
    {
      pathParams: {
        tabId: collectionId,
        tabObjectId,
      },
      queryParams: {
        include_refunds: true,
      },
    },
    {
      enabled: tabObjectType === 'item' && tabObjectSubtype !== 'ticket',
    },
  )

  const questions: Question[] = useMemo(() => {
    const fields =
      tabObjectSubtype === 'ticket'
        ? excludeTicketHiddenFields(responsesQuery.data ?? [])
        : (responsesQuery.data ?? [])

    return fields
      .map((field) => ({
        field,
        respondents: Util.uniqueBy(field.payments, (p) => p.id).flatMap((p) =>
          p.payment_items
            .filter((pi) => pi.amount_after_refund > 0 || pi.total === 0)
            .map((pi) => ({
              paymentId: p.id,
              paymentItemId: pi.id,
              createdAt: p.created_at,
              itemTotal: pi.amount_after_refund,
              total: p.total,
              payerName: p.tab_member.name,
              fieldView: pi.item_field_views.find(
                (ifv) => ifv.item_field_id === field.id,
              ),
            }))
            .filter((r) => r.fieldView != null),
        ),
      }))
      .filter((question) => question.respondents.length > 0)
  }, [responsesQuery.data, tabObjectSubtype])

  const variationQuestion: Question = useMemo(
    () => ({
      respondents:
        variantStatsQuery.data && Array.isArray(variantStatsQuery.data)
          ? variantStatsQuery.data.flatMap((vs) =>
              vs.payments.flatMap((p) =>
                p.payment_items.map((pi) => ({
                  paymentId: p.id,
                  paymentItemId: pi.id,
                  createdAt: p.created_at,
                  itemTotal: pi.amount_after_refund ?? 0,
                  payerName: p.tab_member.name,
                  variant: vs,
                })),
              ),
            )
          : [],
    }),
    [variantStatsQuery.data],
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-5', className)} {...restProps}>
      {responsesQuery.isFetching || variantStatsQuery.isFetching ? (
        Array.from({length: 4})
          .fill(null)
          .map((_v, idx) => (
            <QuestionListRow
              key={idx}
              tabObjectId={tabObjectId}
              tabObjectType={tabObjectType}
            />
          ))
      ) : variationQuestion.respondents.length > 0 || questions.length > 0 ? (
        <>
          {variationQuestion.respondents.length > 0 && (
            <QuestionListRow
              tabObjectId={tabObjectId}
              tabObjectType="item"
              question={variationQuestion}
            />
          )}
          {questions.map((question) => (
            <QuestionListRow
              key={question.field?.id}
              tabObjectId={tabObjectId}
              tabObjectType={tabObjectType}
              question={question}
            />
          ))}
        </>
      ) : (
        <WebUI.VStack
          className="items-center justify-center p-8"
          as={WebUI.Panel}
        >
          You don't have any responses
        </WebUI.VStack>
      )}
    </WebUI.VStack>
  )
}

// MARK: – RespondentListRow

interface RespondentListRowProps extends React.ComponentPropsWithoutRef<'div'> {
  tabObjectId?: number | null
  tabMember?: Api.TabMember
}

const RespondentListRow: React.FC<RespondentListRowProps> = ({
  tabObjectId,
  tabMember,
  className,
  ...restProps
}) => {
  interface PaymentItemResponse {
    name: string
    field_type: Api.TabObjectFieldType
    value: string
  }

  const urlParams = useParams()

  const [latestPayment, ...restPayments] = tabMember?.payments ?? []
  const latestPaymentItemResponses: PaymentItemResponse[] = [
    ...(latestPayment?.payment_items.flatMap((pi) =>
      Util.sort(pi.item_field_views).asc(
        (ifv) => ifv.item_field_id ?? ifv.position,
      ),
    ) ?? []),
    ...(latestPayment?.payment_items.flatMap((pi) =>
      Util.arrayFromObject(
        'detail' in pi ? (pi.detail.variant?.optionValues ?? {}) : {},
        (key, value) => ({
          name: key,
          field_type: 'text' as const,
          value,
        }),
      ),
    ) ?? []),
  ]
  const visiblePaymentItemsCount = 4

  const renderFieldViewRow = (res: PaymentItemResponse, idx: number) => (
    <React.Fragment key={idx}>
      <WebUI.Separator variant="primary" />
      <RespondentListRowResponse
        name={res.name}
        fieldType={res.field_type}
        value={res.value}
      />
    </React.Fragment>
  )

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-4 px-8 py-6 text-gray800', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="gap-1">
        <WebUI.HStack className="justify-between gap-3">
          <WebUI.VStack>
            <WebUI.Heading as="h4">
              {tabMember ? (
                tabMember.name
              ) : (
                <WebUI.Skeleton width={160} height={12} />
              )}
            </WebUI.Heading>
            {tabMember ? (
              <WebUI.Anchor
                className="text-ds-xs"
                href={`mailto=${tabMember.email}`}
              >
                {tabMember.email}
              </WebUI.Anchor>
            ) : (
              <WebUI.Skeleton width={120} height={12} />
            )}
          </WebUI.VStack>

          {!!tabMember && (
            <WebUI.DeprecatedTooltip label="Download Responses (.pdf)">
              <WebUI.IconButton
                as={Link}
                variant="secondary"
                to={`/pdf/collection/${urlParams.collection}/tab-object/${tabObjectId}/tab-member/${tabMember.id}`}
                target="_blank"
              >
                <WebUI.PhosphorIcon icon="printer" width={20} />
              </WebUI.IconButton>
            </WebUI.DeprecatedTooltip>
          )}
        </WebUI.HStack>
        <WebUI.VStack className="max-h-[400px] overflow-y-auto sm:max-h-[800px]">
          <WebUI.Disclosure className="gap-5">
            {!!latestPayment && (
              <RespondentListRowPaymentHeader payment={latestPayment} />
            )}

            <WebUI.VStack className="gap-4">
              {latestPaymentItemResponses
                .slice(0, visiblePaymentItemsCount)
                .map(renderFieldViewRow)}

              <WebUI.DisclosureContent>
                <WebUI.VStack className="gap-4">
                  {latestPaymentItemResponses
                    .slice(visiblePaymentItemsCount)
                    .map(renderFieldViewRow)}

                  {restPayments.map((payment) => (
                    <WebUI.VStack key={payment.id} className="gap-5">
                      <RespondentListRowPaymentHeader payment={payment} />
                      <WebUI.VStack className="gap-4">
                        {payment.payment_items.flatMap((pi) =>
                          pi.item_field_views.map(renderFieldViewRow),
                        )}
                      </WebUI.VStack>
                    </WebUI.VStack>
                  ))}
                </WebUI.VStack>
              </WebUI.DisclosureContent>

              <WebUI.Separator variant="primary" />

              {latestPaymentItemResponses.length > visiblePaymentItemsCount && (
                <WebUI.HStack className="justify-center">
                  <WebUI.DisclosureButton className="text-ds-sm" variant="link">
                    {(disclosure) =>
                      disclosure.visible ? 'Collapse' : 'View all'
                    }
                  </WebUI.DisclosureButton>
                </WebUI.HStack>
              )}
            </WebUI.VStack>
          </WebUI.Disclosure>
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

// MARK: – RespondentListRowPaymentHeader

interface RespondentListRowPaymentHeaderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  payment: Api.TabMemberPayment
}

const RespondentListRowPaymentHeader: React.FC<
  RespondentListRowPaymentHeaderProps
> = ({payment, className, ...restProps}) => (
  <WebUI.VStack
    className={WebUI.cn('font-light text-ds-xs', className)}
    {...restProps}
  >
    <WebUI.Text>
      Completed:{' '}
      {Util.formatDate(payment.created_at, 'MMMM dd, yyyy hh:mm aaa')}
    </WebUI.Text>

    {payment.payment_items.some((pi) => 'tab_item' in pi) && (
      <WebUI.HStack className="gap-5">
        <WebUI.Text>
          Item Total:{' '}
          {Util.formatAmount(
            Util.sumBy(payment.payment_items, (pi) => pi.total),
          )}
        </WebUI.Text>
        <WebUI.Text>
          Qty: {Util.sumBy(payment.payment_items, (pi) => pi.quantity)}
        </WebUI.Text>
      </WebUI.HStack>
    )}
  </WebUI.VStack>
)

// MARK: – RespondentListRowResponse

export interface RespondentListRowResponseProps
  extends React.ComponentPropsWithoutRef<'div'> {
  name: string
  fieldType: Api.TabObjectFieldType
  value: string
}

export const RespondentListRowResponse: React.FC<
  RespondentListRowResponseProps
> = ({name, fieldType, value, className, ...restProps}) => (
  <WebUI.VStack
    className={WebUI.cn('gap-1 text-ds-sm', className)}
    {...restProps}
  >
    <WebUI.Text>{name}</WebUI.Text>
    <FieldViewFormatted
      className="font-light"
      as={WebUI.Text}
      fieldType={fieldType}
      value={value}
    />
  </WebUI.VStack>
)

// MARK: – QuestionListRow

interface QuestionListRowProps extends React.ComponentPropsWithoutRef<'div'> {
  tabObjectId: number
  tabObjectType: 'item' | 'form'
  tabObjectSubtype?: 'ticket'
  question?: Question
}

const QuestionListRow: React.FC<QuestionListRowProps> = ({
  tabObjectId,
  tabObjectType,
  tabObjectSubtype,
  question,
  className,
  ...restProps
}) => {
  const visibleRespondentsCount = 4

  const urlParams = useParams()

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Respondent>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((r) => new Date(r.createdAt), {
        id: 'createdAt',
        sortingFn: 'datetime',
        meta: {
          invisible: true,
        },
      }),
      columnHelper.accessor((r) => r.payerName, {
        id: 'name',
        sortingFn: (rowA, rowB, columnId) => {
          const [nameA, nameB] = [
            rowA.getValue(columnId),
            rowB.getValue(columnId),
          ].map((name) =>
            typeof name === 'string'
              ? name.split(/(\s+)/).reverse().join(' ').toUpperCase()
              : '',
          ) as [string, string]

          return nameA > nameB ? 1 : -1
        },
        meta: {
          subtle: false,
        },
        header:
          tabObjectType === 'item'
            ? tabObjectSubtype === 'ticket'
              ? 'Attendee'
              : 'Payer'
            : 'Respondent',
      }),
      tabObjectSubtype === 'ticket'
        ? columnHelper.accessor(
            (r) => getTicketNumberFromTicketId(r.paymentItemId),
            {
              id: 'ticket',
              header: 'Ticket',
            },
          )
        : columnHelper.accessor(
            (r) => (tabObjectType === 'item' ? r.itemTotal : r.total) ?? 0,
            {
              id: 'paidTotal',
              header: tabObjectType === 'item' ? 'Item Total' : 'Total Paid',
              cell: ({cell}) => Util.formatAmount(cell.getValue()),
            },
          ),
      columnHelper.accessor(
        (r) =>
          r.fieldView?.value ??
          Object.values(r.variant?.optionValues ?? {}).join('|||') ??
          '',
        {
          id: 'fieldViewValue',
          header: 'Response',
          cell: ({cell}) => (
            <FieldViewFormatted
              remoteFileOverviewSize="compact"
              fieldType={question?.field?.field_type ?? 'checkbox'}
              value={cell.getValue()}
            />
          ),
        },
      ),
      columnHelper.display({
        id: 'orderSummaryLink',
        meta: {
          align: 'right',
        },
        header: 'Order Summary',
        cell: ({row}) => (
          <Link
            variant="primary"
            preserveSearch
            to={`payment/${row.original.paymentId}/order-summary`}
          >
            View
          </Link>
        ),
      }),
    ],
    [
      columnHelper,
      question?.field?.field_type,
      tabObjectSubtype,
      tabObjectType,
    ],
  )

  const initialSortingState = useMemo(() => [{id: 'createdAt', desc: true}], [])

  const globalFilterFn: WebUI.FilterFn<Respondent> = useCallback(
    (row, _columnId, filterValue) =>
      filterValue && row.index < visibleRespondentsCount,
    [],
  )

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-8 px-8 py-6 text-gray800', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.HStack className="justify-between gap-3">
        <WebUI.VStack className="gap-1">
          <WebUI.Heading as="h4">
            {question ? (
              (question.field?.name ?? 'Variations')
            ) : (
              <WebUI.Skeleton width={140} height={12} />
            )}
          </WebUI.Heading>
          <WebUI.Text className="font-light text-ds-xs">
            Answered:{' '}
            {question ? (
              question.respondents.length
            ) : (
              <WebUI.Skeleton width={80} height={10} />
            )}
          </WebUI.Text>
        </WebUI.VStack>

        {!!question && (
          <WebUI.DeprecatedTooltip label="Download Responses (.pdf)">
            <WebUI.IconButton
              as={Link}
              variant="secondary"
              to={{
                pathname: question.field?.id
                  ? `/pdf/collection/${urlParams.collection}/tab-object/${tabObjectId}/field/${question.field.id}`
                  : `/pdf/collection/${urlParams.collection}/tab-object/${tabObjectId}/fields`,
                search: question.field?.id
                  ? undefined
                  : queryString.stringify({variationsOnly: true}),
              }}
              target="_blank"
            >
              <WebUI.PhosphorIcon icon="printer" width={20} />
            </WebUI.IconButton>
          </WebUI.DeprecatedTooltip>
        )}
      </WebUI.HStack>

      <WebUI.VStack className="gap-16">
        {!!question &&
          (!question.field ||
            question.field.field_type === 'multiple_choice' ||
            question.field.field_type === 'checkbox') && (
            <MultipleValuesQuestionOverviewTable
              className="max-w-screen-sm"
              type={question.field?.field_type ?? 'variations'}
              values={
                question.field?.values ??
                Util.uniqueBy(
                  // biome-ignore lint/style/noNonNullAssertion:
                  question.respondents.map((r) => r.variant!),
                  (v) => v.uuid,
                )
              }
              respondents={question.respondents}
            />
          )}

        <WebUI.VStack>
          <WebUI.TableView<Respondent>
            className={
              'max-h-[400px] overflow-y-auto text-ds-sm sm:max-h-[800px] [&_.TableView-headerText]:font-normal'
            }
            columns={columns}
            loading={!question}
            loadingRowsCount={3}
            data={question?.respondents ?? []}
            initialState={{sorting: initialSortingState, globalFilter: true}}
            globalFilterFn={globalFilterFn}
            sortable
            sortByTogglesVisible
          >
            {(table) => (
              <WebUI.VStack className="-order-1 mb-8">
                <WebUI.HStack>
                  <WebUI.DropdownSelect<string | null>
                    size="compact"
                    value={table.getState().sorting[0]?.id ?? null}
                    onValueChange={(newSortBy) => {
                      if (newSortBy) {
                        table.setSorting([{id: newSortBy, desc: false}])
                      }
                    }}
                  >
                    <WebUI.DropdownSelectOption value="createdAt">
                      Newest
                    </WebUI.DropdownSelectOption>
                    <WebUI.DropdownSelectOption value="name">
                      A-Z
                    </WebUI.DropdownSelectOption>
                  </WebUI.DropdownSelect>
                </WebUI.HStack>

                {question?.respondents &&
                  question?.respondents.length > visibleRespondentsCount && (
                    <WebUI.HStack className="justify-center py-4">
                      <WebUI.Button
                        className="text-ds-sm"
                        variant="link"
                        aria-expanded={table.getState().globalFilter}
                        iconBefore={
                          <WebUI.PhosphorIcon
                            className="transition-transform aria-expanded:[&_Button-iconBefore]:rotate-180"
                            icon={
                              table.getState().globalFilter
                                ? 'caret-down-fill'
                                : 'caret-up-fill'
                            }
                          />
                        }
                        onClick={() =>
                          table.setGlobalFilter(
                            (prevCollapsed: boolean) => !prevCollapsed,
                          )
                        }
                      >
                        {table.getState().globalFilter
                          ? 'View all'
                          : 'Collapse'}
                      </WebUI.Button>
                    </WebUI.HStack>
                  )}
              </WebUI.VStack>
            )}
          </WebUI.TableView>
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

// MARK: – MultipleValuesQuestionOverviewTable

interface MultipleValuesQuestionOverviewTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  type: Api.TabObjectFieldType | 'variations'
  values: string | InferResponse<typeof endpoints.tabPayments.variantStats>
  respondents: Respondent[]
}

const MultipleValuesQuestionOverviewTable: React.FC<
  MultipleValuesQuestionOverviewTableProps
> = ({type, values, respondents, className, ...restProps}) => {
  interface Datum {
    value: string | Record<string, string>
    itemTotal: number
    total?: number
    respondentsCount: number
  }

  const data = useMemo(
    () =>
      Util.sort<Datum>(
        Array.isArray(values)
          ? values.map((variant) => ({
              value: variant.optionValues,
              itemTotal: Util.sumBy(
                variant.payments.flatMap((p) => p.payment_items),
                (pi) => pi.amount_after_refund ?? 0,
              ),
              respondentsCount: variant.payments.flatMap((p) => p.payment_items)
                .length,
            }))
          : values.split('|||').map((value) => ({
              value,
              itemTotal: Util.sumBy(
                respondents.filter((r) => r.fieldView?.value === value),
                (r) => r.itemTotal ?? 0,
              ),
              total: Util.sumBy(
                respondents.filter((r) => r.fieldView?.value === value),
                (r) => r.total ?? 0,
              ),
              respondentsCount:
                type === 'checkbox'
                  ? respondents.filter((respondent) =>
                      respondent.fieldView?.value.split('|||').includes(value),
                    ).length
                  : respondents.filter(
                      (respondent) => respondent.fieldView?.value === value,
                    ).length,
            })),
      ).desc((d) => d.respondentsCount),
    [respondents, type, values],
  )

  const hasItemTotal = respondents.some((r) => r.itemTotal != null)
  const hasTotal = respondents.some((r) => !!r.total)
  const submissionsCount = Util.sumBy(data, (item) => item.respondentsCount)

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Datum>(), [])

  const columns = useMemo(
    () =>
      [
        type === 'multiple_choice'
          ? columnHelper.accessor(
              (di) =>
                di.respondentsCount === 0
                  ? 0
                  : Math.round((di.respondentsCount / submissionsCount) * 100),
              {
                id: 'percentage',
                minSize: 52,
                size: 52,
                maxSize: 52,
                meta: {
                  subtle: false,
                },
                cell: ({cell}) => `${cell.getValue()}%`,
              },
            )
          : undefined,
        columnHelper.display({
          id: 'bar',
          minSize: 420,
          size: 420,
          maxSize: 420,
          meta: {
            subtle: false,
          },
          header: type === 'checkbox' ? 'Checkbox Options' : 'Responses',
          cell: ({row}) => (
            <WebUI.Bar
              fill={
                submissionsCount === 0
                  ? 0
                  : row.original.respondentsCount / submissionsCount
              }
            >
              {type === 'variations' &&
              typeof row.original.value !== 'string' ? (
                <WebUI.Ellipsis>
                  {Util.arrayFromObject(
                    row.original.value,
                    (key, value, idx) => (
                      <span key={`${key}-${value}`}>
                        {idx > 0 && ', '}
                        {key}: <span className="font-bold">{value}</span>
                      </span>
                    ),
                  )}
                </WebUI.Ellipsis>
              ) : (
                (row.original.value as string)
              )}
            </WebUI.Bar>
          ),
        }),
        columnHelper.accessor((di) => di.respondentsCount, {
          id: 'respondentsCount',
          size: 100,
          header:
            // @ts-expect-error
            {checkbox: '# Selected', variations: 'Quantity'}[type] ??
            '# Respondents',
          cell: ({cell}) =>
            // @ts-expect-error
            ({
              checkbox: `${cell.getValue()} Selected`,
              variations: cell.getValue(),
            })[type] ?? Util.pluralize('Response', cell.getValue(), true),
        }),
        type !== 'checkbox' && hasItemTotal
          ? columnHelper.accessor((di) => di.itemTotal, {
              id: 'itemTotal',
              size: 100,
              header: type === 'variations' ? 'Variation Total' : 'Item Total',
              cell: ({cell}) => Util.formatAmount(cell.getValue() ?? 0),
            })
          : hasTotal
            ? columnHelper.accessor((di) => di.total ?? 0, {
                id: 'total',
                size: 100,
                header: 'Total Collected',
                cell: ({cell}) => Util.formatAmount(cell.getValue()),
              })
            : undefined,
      ].filter((col) => !!col),
    [columnHelper, hasItemTotal, hasTotal, submissionsCount, type],
  )

  return (
    <WebUI.TableView<Datum>
      className={WebUI.cn(
        'text-ds-sm [&_.TableView-headerGroup]:border-b-0 [&_.TableViewRow]:border-b-0 [&_.TableViewRow]:py-1',
        className,
      )}
      columns={columns}
      data={data}
      {...restProps}
    />
  )
}

// MARK: – FieldViewFormatted

interface FieldViewFormattedProps {
  fieldType: Api.TabObjectFieldType
  value: string
  remoteFileOverviewSize?: WebUI.RemoteFileOverviewSize
}

const FieldViewFormatted = React.forwardRef(
  (
    {as: Comp = 'span', fieldType, value, remoteFileOverviewSize, ...restProps},
    forwardedRef,
  ) => (
    <Comp ref={forwardedRef} {...restProps}>
      {['image', 'signature'].includes(fieldType) ? (
        <img className="max-h-18" alt="Signature" src={value} />
      ) : fieldType === 'file' ? (
        <WebUI.RemoteFileOverview size={remoteFileOverviewSize} url={value} />
      ) : (
        getCollectionFieldValueFormatted({value, field_type: fieldType})
      )}
    </Comp>
  ),
) as ForwardRefComponent<'span', FieldViewFormattedProps>

// MARK: – Helpers

interface Question {
  field?: InferResponse<typeof endpoints.tabPayments.responseList>[number]
  respondents: Respondent[]
}

interface Respondent {
  paymentItemId: number
  paymentId: number
  createdAt: string
  itemTotal: number | undefined
  total?: number
  payerName: string
  fieldView?: InferResponse<
    typeof endpoints.tabPayments.responseList
  >[number]['payments'][number]['payment_items'][number]['item_field_views'][number]
  variant?: InferResponse<typeof endpoints.tabPayments.variantStats>[number]
}
