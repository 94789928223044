import * as Yup from 'yup'
import {
  api,
  useSendEmailVerificationCodeMutation,
  useStartVerificationMutation,
  useUpdateUserMutation,
  useVerifyCodeMutation,
  useVerifyEmailVerificationCodeMutation,
} from '@cheddarup/api-client'
import {useForkRef, useFormik, useInterval} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect, useRef, useState} from 'react'
import {guessError} from 'src/helpers/error-utils'

export interface TwoFactorAuthVerificationModalProps extends WebUI.ModalProps {
  onDidComplete?: () => void
}

export const TwoFactorAuthVerificationModal = React.forwardRef<
  WebUI.DialogInstance,
  TwoFactorAuthVerificationModalProps
>(
  (
    {onDidComplete, initialVisible = false, className, ...restProps},
    forwardedRef,
  ) => {
    const [emailToken, setEmailToken] = useState('')
    const [backUpCode, setBackUpCode] = useState('')

    return (
      <WebUI.Modal
        aria-label="Two factor auth verification"
        ref={forwardedRef}
        className={WebUI.cn(
          '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:p-9 sm:[&_>_.ModalContentView]:rounded-large',
          className,
        )}
        initialVisible={initialVisible}
        {...restProps}
      >
        {(dialog) => (
          <>
            <WebUI.ModalCloseButton />

            <div className="flex flex-col gap-4">
              <WebUI.Heading as="h2">
                Set Two-Factor Authentication
              </WebUI.Heading>

              <div className="flex flex-col gap-10">
                <span>
                  Two-Factor Authentication is required prior to publishing a
                  collection and helps keep your account safe when key actions
                  are taken on the platform.
                </span>

                <WebUI.Card className="p-6">
                  <WebUI.Tabs variant="stepper">
                    {(tabs) => (
                      <div className="flex flex-col gap-5">
                        <WebUI.TabList>
                          <WebUI.Tab id="email" />
                          <WebUI.Tab id="phone" />
                          <WebUI.Tab id="backup-code" />
                        </WebUI.TabList>

                        <WebUI.TabPanel tabId="email">
                          <TwoFactorAuthVerificationVerifyEmail
                            onDidVerify={(newEmailToken) => {
                              setEmailToken(newEmailToken)
                              tabs.setSelectedId('phone')
                            }}
                          />
                        </WebUI.TabPanel>
                        <WebUI.TabPanel tabId="phone">
                          <TwoFactorAuthVerificationVerifyPhone
                            emailToken={emailToken}
                            onDidVerify={(newBackUpCode) => {
                              setBackUpCode(newBackUpCode)
                              tabs.setSelectedId('backup-code')
                            }}
                          />
                        </WebUI.TabPanel>
                        <WebUI.TabPanel tabId="backup-code">
                          <TwoFactorAuthVeificationBackUpCode
                            backUpCode={backUpCode}
                            onDidSave={() => {
                              dialog.hide()
                              onDidComplete?.()
                            }}
                          />
                        </WebUI.TabPanel>
                      </div>
                    )}
                  </WebUI.Tabs>
                </WebUI.Card>
              </div>
            </div>
          </>
        )}
      </WebUI.Modal>
    )
  },
)

// MARK: – TwoFactorAuthVerificationVerifyEmail

interface TwoFactorAuthVerificationVerifyEmailProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<TwoFactorAuthEmailVerificationAlertProps, 'onDidVerify'> {}

const TwoFactorAuthVerificationVerifyEmail = ({
  onDidVerify,
  className,
  ...restProps
}: TwoFactorAuthVerificationVerifyEmailProps) => (
  <div
    className={WebUI.cn('flex flex-col items-start gap-4', className)}
    {...restProps}
  >
    <div className="flex flex-col gap-3">
      <WebUI.Heading className="font-bold" as="h5">
        1: Verify your email address
      </WebUI.Heading>
      <span className="text-ds-sm">
        Verify the email address that you used to set up your account
      </span>
    </div>

    <TwoFactorAuthEmailVerificationAlert onDidVerify={onDidVerify} />
  </div>
)

// MARK: – TwoFactorAuthEmailVerificationAlert

export interface TwoFactorAuthEmailVerificationAlertProps
  extends WebUI.ButtonProps {
  onDidVerify?: (token: string) => void
}

export const TwoFactorAuthEmailVerificationAlert = React.forwardRef<
  WebUI.DialogInstance,
  TwoFactorAuthEmailVerificationAlertProps
>(({onDidVerify, ...restProps}, forwardedRef) => {
  const ownRef = useRef<WebUI.DialogInstance>(null)
  const ref = useForkRef(forwardedRef, ownRef)
  const emailQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.email,
  })
  const sendEmailVerificationCodeMutation =
    useSendEmailVerificationCodeMutation()
  const verifyEmailVerificationCodeMutation =
    useVerifyEmailVerificationCodeMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik({
    initialValues: {
      emailVerificationCode: '',
    },
    validationSchema: Yup.object({
      emailVerificationCode: Yup.string()
        .min(6, 'Verification code must be at least 6 characters')
        .required(),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await verifyEmailVerificationCodeMutation.mutateAsync({
          body: {
            code: values.emailVerificationCode,
          },
        })
        onDidVerify?.(values.emailVerificationCode)
        ownRef.current?.hide()
      } catch (err) {
        formikHelpers.resetForm()
        growlActions.show('error', {
          title: guessError(err).message,
        })
      }
    },
  })

  async function sendVerificationCode() {
    try {
      await sendEmailVerificationCodeMutation.mutateAsync()
      growlActions.clear()
      growlActions.show('success', {
        title: 'Code Sent',
        body: 'Verification code sent to your email address.',
      })
    } catch (err: any) {
      growlActions.clear()
      growlActions.show('error', {
        title: 'Error!',
        body: guessError(err).message,
      })
    }
  }

  return (
    <WebUI.Alert
      aria-label="Two factor auth email verification"
      ref={ref}
      disclosure={
        <WebUI.DialogDisclosure
          iconBefore={
            <WebUI.PhosphorIcon
              className="text-accent300"
              icon="envelope"
              width={20}
            />
          }
          variant="secondary"
          loading={sendEmailVerificationCodeMutation.isPending}
          onClick={() => sendVerificationCode()}
          {...restProps}
        >
          Send email verification code
        </WebUI.DialogDisclosure>
      }
    >
      <WebUI.AlertHeader>Enter Email Verification Code</WebUI.AlertHeader>

      <div className="flex flex-col gap-4 p-7">
        <span>Enter the six-digit code sent to {emailQuery.data ?? ''}.</span>

        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
          <WebUI.FormField
            label="Enter Verification Code"
            error={formik.errors.emailVerificationCode}
          >
            <WebUI.Input
              className="w-[280px]"
              name="emailVerificationCode"
              autoComplete="one-time-code"
              value={formik.values.emailVerificationCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>

          <span className="text-ds-sm">
            Don’t see the email?{' '}
            <WebUI.Button
              variant="link"
              disabled={sendEmailVerificationCodeMutation.isPending}
              onClick={sendVerificationCode}
            >
              Send a new code
            </WebUI.Button>
          </span>

          <WebUI.Button
            className="self-start"
            type="submit"
            variant="primary"
            loading={verifyEmailVerificationCodeMutation.isPending}
          >
            Verify
          </WebUI.Button>
        </form>
      </div>
    </WebUI.Alert>
  )
})

// MARK: – TwoFactorAuthVerificationVerifyPhone

interface TwoFactorAuthVerificationVerifyPhoneProps
  extends React.ComponentPropsWithoutRef<'form'>,
    Pick<
      TwoFactorAuthPhoneVerificationAlertProps,
      'emailToken' | 'onDidVerify'
    > {}

const TwoFactorAuthVerificationVerifyPhone = ({
  onDidVerify,
  emailToken,
  className,
  ...restProps
}: TwoFactorAuthVerificationVerifyPhoneProps) => {
  const twoFactorAuthPhoneVerificationAlertRef =
    useRef<WebUI.DialogInstance>(null)
  const updateUserMutation = useUpdateUserMutation()
  const startVerificationMutation = useStartVerificationMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      via: '',
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required('Required'),
      via: Yup.string().required('Required'),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const parsedPhoneNumber = WebUI.parsePhoneNumber(values.phoneNumber)
        if (!parsedPhoneNumber) {
          throw new Error('Invalid phone number')
        }
        if (!emailToken) {
          throw new Error('Email token not found')
        }
        if (values.via !== 'sms' && values.via !== 'call' && !emailToken) {
          throw new Error('Something went wrong...')
        }

        const via = values.via as 'sms' | 'call'

        await updateUserMutation.mutateAsync({
          body: {
            profile: {
              phone: {
                country_code: parsedPhoneNumber.countryCallingCode as string,
                phone_number: parsedPhoneNumber.nationalNumber as string,
              },
            },
          },
        })
        await startVerificationMutation.mutateAsync({
          body: {via, security: {emailToken}},
        })

        twoFactorAuthPhoneVerificationAlertRef.current?.show()
      } catch (err) {
        formikHelpers.setFieldValue('via', '')
        growlActions.clear()
        growlActions.show('error', {
          title: 'Error!',
          body: guessError(err).message,
        })
      }
    },
  })

  async function handleViaButtonClick(via: 'sms' | 'call') {
    if (formik.values.phoneNumber) {
      // TODO: replace with Yup validation
      if (WebUI.isPossiblePhoneNumber(formik.values.phoneNumber)) {
        await formik.setFieldValue('via', via)
        formik.submitForm()
      } else {
        formik.setErrors({phoneNumber: 'Invalid'})
      }
    }
  }

  return (
    <>
      <form
        className={WebUI.cn('flex flex-col gap-4', className)}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
        {...restProps}
      >
        <div className="flex flex-col gap-3">
          <WebUI.Heading className="font-bold" as="h5">
            2: Verify your phone number
          </WebUI.Heading>
          <span className="text-ds-sm">
            Enter a phone number for receiving two-factor verifications.
          </span>
        </div>

        <WebUI.FormField error={formik.errors.phoneNumber}>
          <WebUI.PhoneInput
            autoFocus
            name="phoneNumber"
            placeholder="Enter your mobile phone number"
            maxLength={17}
            onChange={(newPhoneNumber) =>
              formik.setFieldValue('phoneNumber', newPhoneNumber)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        <div className="flex flex-row gap-6">
          <WebUI.Button
            type="button"
            variant="secondary"
            disabled={formik.isSubmitting}
            loading={formik.values.via === 'sms' && formik.isSubmitting}
            iconBefore={
              <WebUI.PhosphorIcon
                icon="chat-dots"
                className="text-tint"
                width={16}
              />
            }
            onClick={() => handleViaButtonClick('sms')}
          >
            Text me verification code
          </WebUI.Button>

          <WebUI.Button
            className="text-ds-sm"
            type="button"
            variant="link"
            disabled={formik.isSubmitting}
            onClick={() => handleViaButtonClick('call')}
          >
            Or, call me with code
          </WebUI.Button>
        </div>
      </form>

      <TwoFactorAuthPhoneVerificationAlert
        ref={twoFactorAuthPhoneVerificationAlertRef}
        phoneNumber={formik.values.phoneNumber}
        via={formik.values.via as any}
        emailToken={emailToken}
        onDidVerify={onDidVerify}
      />
    </>
  )
}

// MARK: – TwoFactorAuthPhoneVerificationAlert

export interface TwoFactorAuthPhoneVerificationAlertProps
  extends WebUI.AlertProps {
  phoneNumber: string
  via: 'sms' | 'call'
  emailToken: string
  onDidVerify?: (backupSecurityCode: string) => void
}

export const TwoFactorAuthPhoneVerificationAlert = React.forwardRef<
  WebUI.DialogInstance,
  TwoFactorAuthPhoneVerificationAlertProps
>(({phoneNumber, via, emailToken, onDidVerify, ...restProps}, forwardedRef) => {
  const verificationCodeLength = 6

  const ownRef = useRef<WebUI.DialogInstance>(null)
  const ref = useForkRef(forwardedRef, ownRef)
  const [secondsLeftToResend, setSecondsLeftToResend] = useState(45)
  const startVerificationMutation = useStartVerificationMutation()
  const verifyCodeMutation = useVerifyCodeMutation()
  const growlActions = WebUI.useGrowlActions()

  useInterval(() => {
    setSecondsLeftToResend((prev) => Math.max(prev - 1, 0))
  }, 1000)

  const formik = useFormik({
    initialValues: {
      verificationCode: '',
    },
    validationSchema: Yup.object().shape({
      verificationCode: Yup.string()
        .required('Required')
        .length(
          verificationCodeLength,
          `Verification code must be ${verificationCodeLength} digit.`,
        ),
    }),
    onSubmit: async (values) => {
      try {
        const {reset_code: backupSecurityCode, success} =
          await verifyCodeMutation.mutateAsync({
            body: {
              token: values.verificationCode,
              security: {
                emailToken,
              },
            },
          })
        if (success) {
          onDidVerify?.(backupSecurityCode)
          ownRef.current?.hide()
        }
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <WebUI.Alert
      aria-label="Two factor auth phone verification"
      ref={ref}
      {...restProps}
    >
      <WebUI.AlertHeader>
        Enter Phone Number Verification code
      </WebUI.AlertHeader>

      <div className="flex flex-col gap-4 p-7">
        <span>
          Enter the six-digit confirmation code to your mobile device:
          ***-***-**
          {phoneNumber.slice(-2)}.
        </span>

        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
          <WebUI.FormField
            label="Enter Code"
            error={formik.errors.verificationCode}
          >
            <WebUI.Input
              name="verificationCode"
              inputMode="numeric"
              autoComplete="one-time-code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={verificationCodeLength}
            />
          </WebUI.FormField>

          {secondsLeftToResend > 0 ? (
            <div className="cursor-not-allowed font-normal text-ds-sm text-tint">
              Seconds left to resend code: {secondsLeftToResend}
            </div>
          ) : (
            <WebUI.Button
              className="text-ds-sm"
              variant="link"
              onClick={async () => {
                setSecondsLeftToResend(45)
                await startVerificationMutation.mutateAsync({
                  body: {via, security: {emailToken}},
                })
                growlActions.show('success', {
                  title: 'Success',
                  body: 'Verification code sent to your phone',
                })
              }}
            >
              Resend a new code
            </WebUI.Button>
          )}

          <WebUI.Button
            className="self-start"
            type="submit"
            variant="primary"
            loading={formik.isSubmitting}
          >
            Verify
          </WebUI.Button>
        </form>
      </div>
    </WebUI.Alert>
  )
})

// MARK: – TwoFactorAuthVeificationBackUpCode

interface TwoFactorAuthVeificationBackUpCodeProps
  extends React.ComponentPropsWithoutRef<'form'> {
  backUpCode: string
  onDidSave?: () => void
}

const LazyBackUpCodePdfDocumentDownloadIconButton = React.lazy(() =>
  import('./BackUpCodePdfDocumentDownloadButton').then((module) => ({
    default: module.BackUpCodePdfDocumentDownloadIconButton,
  })),
)

const TwoFactorAuthVeificationBackUpCode = ({
  backUpCode,
  onDidSave,
  className,
  ...restProps
}: TwoFactorAuthVeificationBackUpCodeProps) => {
  const growlActions = WebUI.useGrowlActions()

  useEffect(() => {
    function handleBeforeunload(event: BeforeUnloadEvent) {
      const promptText = 'Are you sure you want to close this browser window?'
      event.returnValue = promptText
      return promptText
    }

    window.addEventListener('beforeunload', handleBeforeunload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [])

  const formik = useFormik({
    validateOnMount: true,
    validateOnChange: true,
    initialValues: {
      isCodeCopied: false,
    },
    validationSchema: Yup.object().shape({
      isCodeCopied: Yup.boolean().oneOf([true]),
    }),
    onSubmit: () => {
      onDidSave?.()
    },
  })

  return (
    <form
      className={WebUI.cn('flex flex-col items-start gap-4', className)}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <div className="flex flex-col gap-3">
        <WebUI.Heading className="font-bold" as="h5">
          3. Save your back-up security code
        </WebUI.Heading>
        <span className="text-ds-sm">
          <span className="font-normal text-flamingo">IMPORTANT:</span> This
          back-up security code is the ONLY way you’ll be able to recover your
          account for key actions on the platform if you do not have access to
          your phone or if the account owner may need to change at some point in
          the future. As such, it is imperative that you save this code in a
          safe place should you need it in the future.
        </span>
      </div>

      <div className="flex flex-row gap-4">
        <div className="rounded-default bg-natural-80 px-4 py-2 font-normal text-ds-sm">
          {backUpCode}
        </div>

        <div className="flex flex-row gap-2">
          <React.Suspense fallback={<div />}>
            <LazyBackUpCodePdfDocumentDownloadIconButton
              className="text-ds-xl"
              size="default_alt"
              variant="default"
              backUpCode={backUpCode}
            >
              <WebUI.PhosphorIcon icon="download-simple" />
            </LazyBackUpCodePdfDocumentDownloadIconButton>
          </React.Suspense>
          <WebUI.IconButton
            className="text-ds-xl"
            size="default_alt"
            variant="default"
            onClick={() => {
              WebUI.copyToClipboard(backUpCode)
              growlActions.show('success', {
                title: 'Success',
                body: 'Link copied',
              })
            }}
          >
            <WebUI.PhosphorIcon icon="copy" />
          </WebUI.IconButton>
        </div>
      </div>

      <WebUI.FormField>
        <WebUI.Checkbox
          className="items-center"
          name="isCodeCopied"
          size="compact"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <span>
            I have copied and saved this code
            <span className="text-orange-50">*</span>
          </span>
        </WebUI.Checkbox>
      </WebUI.FormField>

      <WebUI.Button type="submit" variant="primary" disabled={!formik.isValid}>
        Save
      </WebUI.Button>
    </form>
  )
}
