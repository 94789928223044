import lodash from 'lodash'

export {default as pluralize} from 'pluralize'
export {
  match as fuzzyMatch,
  filter as fuzzyFilterIterator,
  sort as fuzzySort,
} from 'fuzzyjs'
export {capitalize, stringToPath} from 'remeda'

export const toCamelCase = lodash.camelCase

const {kebabCase, startCase, truncate} = lodash

export {kebabCase, startCase, truncate}

// TODO: Cover with tests stage 1
export function replaceTokens(
  str: string,
  tokenMap: Record<string, string | number | boolean>,
  tokenRegex: RegExp,
  matchTransform?: (match: string) => string,
) {
  return str.replace(tokenRegex, (_match) => {
    const match = matchTransform?.(_match) ?? _match

    const value = tokenMap[match]

    if (!value) {
      console.warn(`Missing required parameter "${match}" for "${str}".`)
    }

    return String(value)
  })
}

// TODO: Cover with tests stage 1
export function getTokens(str: string, tokenRegex: RegExp) {
  return str.match(tokenRegex)?.map((p) => p.slice(1)) ?? []
}
