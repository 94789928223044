import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'
import {AccountSettingsContentLayout} from './components/AccountSettingsContentLayouts'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik} from '@cheddarup/react-util'
import {api, useUpdateBrandingMutation} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import React, {useRef, useState} from 'react'
import {
  BrandKitColors,
  brandKitPalettes,
  defaultBrandKitColorMap,
  getBrandKitColors,
  getPayerBrandKitColors,
} from '@cheddarup/core'
import {Link} from 'src/components/Link'
import BrandKitSettingsDisclosure from './components/BrandKitSettingsDisclosure'
import {getCUReadableColor} from 'src/helpers/color-utils'

const BrandKitPalettePage = () => {
  const navigate = useNavigate()
  const disableBrandColorsAlertRef = useRef<WebUI.DialogInstance>(null)
  const [toggleToDisable, setToggleToDisable] = useState<'group' | 'payer'>(
    'group',
  )
  const brandingQuery = api.userBrandings.detail.useQuery()
  const {data: notSubscribedToTeam} = api.auth.session.useQuery(undefined, {
    select: (session) => !session.capabilities.subscribed_to_team,
  })

  const updateBrandingMutation = useUpdateBrandingMutation()

  const brandKitColors = getBrandKitColors(
    brandingQuery.data?.color_palette.groupPage,
  )
  const payerBrandKitColors = getPayerBrandKitColors(
    brandingQuery.data?.color_palette.payerPage,
  )

  const validateTeamPlanAndConfirmationAlert = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    toggleType: 'group' | 'payer',
  ) => {
    if (notSubscribedToTeam) {
      event.preventDefault()
      navigate('i/plans?recommendedPlan=team')
    } else if (
      toggleType === 'group' &&
      brandingQuery.data?.color_palette.groupPage.enabled &&
      !Util.deepEqual(brandKitColors, defaultBrandKitColorMap)
    ) {
      event.preventDefault()
      setToggleToDisable(toggleType)
      disableBrandColorsAlertRef.current?.show()
    } else if (
      toggleType === 'payer' &&
      brandingQuery.data?.color_palette.payerPage.enabled &&
      !Util.deepEqual(payerBrandKitColors, {
        primaryButton: defaultBrandKitColorMap.primary,
        secondaryButton: defaultBrandKitColorMap.secondary,
      })
    ) {
      event.preventDefault()
      setToggleToDisable(toggleType)
      disableBrandColorsAlertRef.current?.show()
    }
  }

  const togglePayerPageBrandColors = (enabled: boolean) => {
    if (enabled !== brandingQuery.data?.color_palette.payerPage.enabled) {
      return updateBrandingMutation.mutateAsync({
        body: {
          color_palette: {
            payerPage: {
              enabled,
              ...(!enabled && {
                primaryButton: defaultBrandKitColorMap.primary,
                secondaryButton: defaultBrandKitColorMap.secondary,
              }),
            },
          },
        },
      })
    }
    return Promise.resolve(null)
  }

  const toggleGroupPageBrandColors = (enabled: boolean) => {
    if (enabled !== brandingQuery.data?.color_palette.groupPage.enabled) {
      return updateBrandingMutation.mutateAsync({
        body: {
          color_palette: {
            groupPage: {
              enabled,
              ...(!enabled && defaultBrandKitColorMap),
            },
          },
        },
      })
    }
    return Promise.resolve(null)
  }

  return (
    <AccountSettingsContentLayout
      className="[&_>_.Panel]:px-6 [&_>_.Panel]:py-5"
      heading={
        <div className="flex flex-row items-center gap-4">
          <WebUI.Heading as="h2">Palette</WebUI.Heading>
          {notSubscribedToTeam && (
            <PlanUpgradeButton upgradeTo="team" asPaidBadge />
          )}
        </div>
      }
      body={
        <span className="text-ds-base">
          Replace the Cheddar Up default colors with background and button
          colors that match your brand.{' '}
          <Link variant="primary" to="group-page-quick-tour">
            Learn more
          </Link>
        </span>
      }
    >
      <WebUI.Panel>
        <BrandKitSettingsDisclosure
          label="Group Page Brand Colors"
          visible={brandingQuery.data?.color_palette.groupPage.enabled}
          onVisibleChange={toggleGroupPageBrandColors}
          onClick={(event) =>
            validateTeamPlanAndConfirmationAlert(event, 'group')
          }
        >
          <WebUI.Separator className="-mx-6" />
          <WebUI.Text className="font-extrabold text-ds-sm">
            Group Page Colors
          </WebUI.Text>
          <WebUI.Text className="font-light">
            These colors will be used for select backgrounds on your Group Page.
          </WebUI.Text>
          <BradingColorPickers brandKitColors={brandKitColors} />
        </BrandKitSettingsDisclosure>
      </WebUI.Panel>
      <WebUI.Panel>
        <BrandKitSettingsDisclosure
          label="Payer Page Brand Colors"
          visible={brandingQuery.data?.color_palette.payerPage.enabled}
          onVisibleChange={togglePayerPageBrandColors}
          onClick={(event) =>
            validateTeamPlanAndConfirmationAlert(event, 'payer')
          }
        >
          <WebUI.Separator className="-mx-6" />
          <WebUI.Text className="font-extrabold text-ds-sm">
            Collection Page Button Colors
          </WebUI.Text>
          <WebUI.Text className="font-light">
            These colors will be applied to buttons, accents and icons across
            all of your collection pages.
          </WebUI.Text>
          <WebUI.Separator className="-mx-6" />
          <WebUI.Text className="text-ds-sm">Primary Button Color</WebUI.Text>
          <WebUI.Text className="font-light">
            Your primary button color will apply to item and form buttons as
            well as small icons.
          </WebUI.Text>
          <BrandingColorPickerPopoverForm
            className="h-9 w-32 self-start"
            brandingColorKey="primaryButton"
            heading="Primary Button Color"
            description="Choose a bold color with high contrast to capture the most attention."
            formTarget="payerPage"
            defaultValue={payerBrandKitColors.primaryButton}
          />
          <WebUI.Separator className="-mx-6" />
          <WebUI.Text className="text-ds-sm">Secondary Button Color</WebUI.Text>
          <WebUI.Text className="font-light">
            Your secondary button color will apply to page-advancing buttons and
            other accents.
          </WebUI.Text>
          <BrandingColorPickerPopoverForm
            className="h-9 w-32 self-start"
            brandingColorKey="secondaryButton"
            heading="Secondary Button Color"
            description="Choose a bold color that compliments your primary button color."
            formTarget="payerPage"
            defaultValue={payerBrandKitColors.secondaryButton}
          />
        </BrandKitSettingsDisclosure>
      </WebUI.Panel>
      <DisableColorPaletteAlert
        ref={disableBrandColorsAlertRef}
        onContinue={() =>
          toggleToDisable === 'group'
            ? toggleGroupPageBrandColors(false)
            : togglePayerPageBrandColors(false)
        }
        isLoading={updateBrandingMutation.isPending}
      />
    </AccountSettingsContentLayout>
  )
}

// MARK: – BradingColorPickers

interface BradingColorPickersProps
  extends React.ComponentPropsWithoutRef<'div'> {
  brandKitColors: BrandKitColors
}

const BradingColorPickers = ({
  className,
  brandKitColors,
  ...restProps
}: BradingColorPickersProps) => (
  <div className={WebUI.cn('flex flex-wrap gap-6', className)} {...restProps}>
    <div className="flex flex-col items-center gap-2">
      <BrandingColorPickerPopoverForm
        brandingColorKey="primary"
        heading="Primary"
        description="This color will be used for buttons. Choose a color that stands out:"
        defaultValue={brandKitColors.primary}
      />
      <WebUI.Text className="text-ds-sm">Primary</WebUI.Text>
    </div>
    <div className="flex flex-col items-center gap-2">
      <BrandingColorPickerPopoverForm
        brandingColorKey="secondary"
        heading="Secondary"
        description="Select a compliment to your primary color or brand."
        defaultValue={brandKitColors.secondary}
      />
      <WebUI.Text className="text-ds-sm">Secondary</WebUI.Text>
    </div>
    <div className="flex flex-col items-center gap-2">
      <BrandingColorPickerPopoverForm
        brandingColorKey="neutral"
        heading="Neutral"
        description="We recommend a lighter color for better legibility and visual balance."
        defaultValue={brandKitColors.neutral}
      />
      <WebUI.Text className="text-ds-sm">Neutral</WebUI.Text>
    </div>
  </div>
)

// MARK: – BrandingColorPickerPopoverForm

export interface BrandingColorPickerPopoverFormProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'defaultValue'> {
  brandingColorKey:
    | Exclude<Api.BrandKitColorKey, 'white'>
    | 'primaryButton'
    | 'secondaryButton'
  heading?: React.ReactNode
  placement?: WebUI.PopoverProps['placement']
  description: string
  formTarget?: 'groupPage' | 'payerPage' | 'email'
  defaultValue: string
}

export const BrandingColorPickerPopoverForm = ({
  brandingColorKey,
  heading,
  defaultValue,
  value: valueProp,
  style,
  onClick,
  placement = 'auto-end',
  formTarget = 'groupPage',
  description,
  className,
  ...restProps
}: BrandingColorPickerPopoverFormProps) => {
  const navigate = useNavigate()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const updateBrandingMutation = useUpdateBrandingMutation()
  const popoverRef = useRef<WebUI.PopoverInstance>(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      color: defaultValue,
    },
    onSubmit: async (values) => {
      await updateBrandingMutation.mutateAsync({
        body: {
          color_palette: {
            [formTarget]: {
              [brandingColorKey]: values.color,
              ...(formTarget === 'email' && {
                primaryButtonText: getCUReadableColor(values.color),
              }),
            },
          },
        },
      })

      popoverRef.current?.hide()
    },
  })

  return (
    <WebUI.ColorPickerPopover
      ref={popoverRef}
      disclosure={
        <WebUI.PopoverDisclosure
          style={{
            backgroundColor: formik.values.color,
            color: getCUReadableColor(formik.values.color),
            ...style,
          }}
          className={WebUI.cn('h-18 min-w-32', className)}
          onClick={(event) => {
            onClick?.(event)
            if (event.defaultPrevented) {
              return
            }

            if (!isSubscribedToTeamQuery.data) {
              event.preventDefault()
              navigate('i/plans?recommendedPlan=team')
            }
          }}
          {...restProps}
        >
          {formik.values.color}
        </WebUI.PopoverDisclosure>
      }
      value={formik.values.color}
      onValueChange={(newColor) => formik.setFieldValue('color', newColor)}
      placement={placement}
      onDidHide={() => formik.resetForm()}
    >
      <form
        className="flex flex-col items-start gap-6 p-3 sm:max-w-72"
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        {typeof heading === 'string' ? (
          <WebUI.Heading className="mx-1 font-bold" as="h5">
            {heading}
          </WebUI.Heading>
        ) : (
          heading
        )}
        <WebUI.Text className="font-light text-ds-sm">{description}</WebUI.Text>

        <div className="flex flex-col gap-4">
          <WebUI.ColorSwatchPicker>
            {(brandingColorKey === 'neutral'
              ? brandKitPalettes.neutral
              : brandKitPalettes.tint
            ).map((color) => (
              <WebUI.ColorSwatchPickerItem
                key={color}
                className="h-6 w-6"
                color={color}
              />
            ))}
          </WebUI.ColorSwatchPicker>

          <WebUI.ColorSaturationArea className="!h-28 !w-28" />
          <WebUI.ColorHueSlider />

          <WebUI.ColorInput size="compact" />
        </div>

        <WebUI.Button type="submit" loading={formik.isSubmitting}>
          Save
        </WebUI.Button>
      </form>
    </WebUI.ColorPickerPopover>
  )
}

// MARK: – DisableColorPaletteAlert

interface DisableColorPaletteAlertProps extends WebUI.AlertProps {
  onContinue: () => void
  isLoading: boolean
}

const DisableColorPaletteAlert = React.forwardRef<
  WebUI.DialogInstance,
  DisableColorPaletteAlertProps
>(({onContinue, isLoading, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    aria-label="Disable Custom Footer alert"
    ref={forwardedRef}
    {...restProps}
  >
    <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text="Your brand colors will not be saved and will revert to Cheddar Up’s default colors."
      actions={
        <>
          <WebUI.AlertActionButton loading={isLoading} execute={onContinue}>
            I’m sure
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton />
        </>
      }
    />
  </WebUI.Alert>
))

export default BrandKitPalettePage
