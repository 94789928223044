// @ts-ignore

import {z} from '@cheddarup/util'

export const fieldSetTypeSchema = z.enum(['full_name', 'address', 'general'])

export const fieldSetSchema = z.object({
  uuid: z.string(),
  label: z.string(),
  type: fieldSetTypeSchema,
  position: z.number().nullish(),
})

export const tabObjectFieldTypeSchema = z.enum([
  'text',
  'text_multiline',
  'multiple_choice',
  'checkbox',
  'date',
  'phone',
  'time',
  'initials',
  'image',
  'signature',
  'file',
  'email',
  'layout_divider',
  'layout_description',
])

export const timeSlotFieldTypeSchema = z.enum([
  'first_name',
  'last_name',
  'comment',
])

export const tabObjectFieldIdentifierSchema = z.enum([
  'first_name',
  'last_name',
  'line1',
  'line2',
  'city',
  'state',
  'zip',
])

export const tabObjectFieldTypeMetadataSchema = z.object({
  timeZone: z.string(),
  fieldIdentifier: tabObjectFieldIdentifierSchema.nullish(),
  acceptableFiles: z.string().nullish(),
})

export const tabObjectFieldMetadataSchema = z.object({
  fieldSetId: z.string().nullish(),
  isWaiverField: z.boolean().nullish(),
  isTimeSlotField: z.boolean().nullish(),
  waiverFieldType: z
    .enum(['signed_date', 'first_name', 'last_name', 'signature'])
    .nullish(),
  timeSlotFieldType: timeSlotFieldTypeSchema.nullish(),
  description: z
    .object({
      enabled: z.boolean(),
      value: z.string(),
    })
    .optional(),
  fieldTypeMetadata: tabObjectFieldTypeMetadataSchema.optional(),
})

export const tabObjectFieldSchema = z.object({
  id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  deleted_at: z.string().nullish(),
  tab_object_id: z.number(),
  name: z.string(),
  required: z.boolean(),
  position: z.number(),
  field_type: tabObjectFieldTypeSchema,
  values: z.string().nullable(),
  metadata: tabObjectFieldMetadataSchema,
})

export const signUpFieldSchema = tabObjectFieldSchema.extend({
  metadata: z.object({
    isTimeSlotField: z.boolean(),
    timeSlotFieldType: timeSlotFieldTypeSchema,
  }),
})

declare global {
  namespace Api {
    // @ts-ignore
    type SignUpField = z.infer<typeof signUpFieldSchema>
    // @ts-ignore
    type TabObjectField = z.infer<typeof tabObjectFieldSchema>
    // @ts-ignore
    type TabObjectFieldMetadata = z.infer<typeof tabObjectFieldMetadataSchema>
    // @ts-ignore
    type TabObjectFieldTypeMetadata = z.infer<
      typeof tabObjectFieldTypeMetadataSchema
    >
    // @ts-ignore
    type TabObjectFieldIdentifier = z.infer<
      typeof tabObjectFieldIdentifierSchema
    >
    // @ts-ignore
    type TimeSlotFieldType = z.infer<typeof timeSlotFieldTypeSchema>
    // @ts-ignore
    type TabObjectFieldType = z.infer<typeof tabObjectFieldTypeSchema>
    // @ts-ignore
    type FieldSet = z.infer<typeof fieldSetSchema>
    // @ts-ignore
    type FieldSetType = z.infer<typeof fieldSetTypeSchema>
  }
}
