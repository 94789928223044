import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {Formik} from 'formik'
import {useParams} from 'react-router-dom'
import {useFormik, useLiveRef} from '@cheddarup/react-util'
import React, {useEffect, useRef, useState} from 'react'
import {
  api,
  useAlphabetizeItemsMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {useUndoableMutation} from 'src/hooks/useUndoableMutation'
import {useApiHeaders} from 'src/helpers/client'

export interface ItemListToolbarProps extends WebUI.DataListToolbarProps {
  inventoryGroupsVisible: boolean
  onUpdateInventoryGroups: () => void
  onAddItem: () => void
  onSearch: (keyword: string) => boolean
}

export const ItemListToolbar: React.FC<ItemListToolbarProps> = ({
  inventoryGroupsVisible,
  onUpdateInventoryGroups,
  onAddItem,
  onSearch,
  className,
  ...restProps
}) => {
  const headers = useApiHeaders()
  const media = WebUI.useMedia()
  const urlParams = useParams()
  const [searchBarErrorMessage, setSearchBarErrorMessage] = useState('')
  const alphabetizeItemsUndoableMutation = useUndoableMutation(
    useAlphabetizeItemsMutation({timeout: 8000}),
  )
  const growlActions = WebUI.useGrowlActions()

  const collectionId = Number(urlParams.collection)
  return (
    <WebUI.DataListToolbar
      className={WebUI.cn('[&_.DataListToolbar-content]:gap-3', className)}
      sectionList
      {...restProps}
    >
      {!media.sm && (
        <WebUI.Button size="compact" variant="primary" onClick={onAddItem}>
          Add Item
        </WebUI.Button>
      )}
      <CategoryFormPopover
        disclosure={
          <WebUI.PopoverDisclosure size="compact">
            Add Category
          </WebUI.PopoverDisclosure>
        }
        collectionId={collectionId}
      />
      <NavigationMenuPopover collectionId={collectionId} />

      <WebUI.Separator orientation="vertical" variant="primary" />

      <WebUI.HStack className="gap-3">
        {inventoryGroupsVisible && (
          <WebUI.Button
            size="compact"
            variant="outlined"
            iconBefore={<WebUI.PhosphorIcon icon="gear" width={20} />}
            onClick={() => onUpdateInventoryGroups()}
          >
            Item Quantity Groups
          </WebUI.Button>
        )}
        <DisplayOptionsPopover collectionId={collectionId} />
        <WebUI.Button
          size="compact"
          variant="outlined"
          iconBefore={<WebUI.PhosphorIcon icon="download-simple" width={20} />}
          onClick={async () => {
            const res = await api.tabs.itemsExport.fetch({
              pathParams: {
                tabId: collectionId,
              },
              headers,
            })

            if (res.file_url) {
              WebUI.downloadFile(res.file_url, `${collectionId}_items.xlsx`)
            }
            growlActions.show('success', {
              title: 'Your report is in progress',
              body:
                res.message ??
                'You will receive an email with a link to download your report.',
            })
          }}
        >
          Items Report
        </WebUI.Button>
        <WebUI.Alert
          aria-label="Sort items confirmation"
          disclosure={
            <WebUI.DialogDisclosure
              size="compact"
              variant="outlined"
              iconBefore={
                <WebUI.PhosphorIcon icon="sort-ascending" width={20} />
              }
            >
              Sort
            </WebUI.DialogDisclosure>
          }
        >
          <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text="This action will sort items alphabetically within each category, are you sure you want to proceed?"
            actions={
              <>
                <WebUI.AlertActionButton
                  execute={() =>
                    alphabetizeItemsUndoableMutation.mutateWithUndo(
                      {
                        body: 'Items sorted successfully',
                      },
                      {
                        pathParams: {
                          tabId: collectionId,
                        },
                        body: {type: 'items'},
                      },
                    )
                  }
                >
                  Continue
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </WebUI.Alert>
        <ItemsToolbarSearchBar
          className="w-full gap-2"
          errorMessage={searchBarErrorMessage}
          onResetErrorMessage={() => {
            setSearchBarErrorMessage('')
          }}
          onSubmit={(keyword) => {
            const isAnyFound = onSearch(keyword)
            if (!isAnyFound) {
              setSearchBarErrorMessage('No results found')
            }
          }}
        />
      </WebUI.HStack>
    </WebUI.DataListToolbar>
  )
}

// MARK: – CategoryFormPopover

export interface CategoryFormPopoverProps extends WebUI.PopoverProps {
  collectionId: number
  category?: Api.Category
  disclosure?: React.ReactNode
}

export const CategoryFormPopover = React.forwardRef<
  WebUI.PopoverInstance,
  CategoryFormPopoverProps
>(({collectionId, category, disclosure, ...restProps}, forwardedRef) => {
  const createCategoryMutation = useCreateCategoryMutation()
  const updateCategoryMutation = useUpdateCategoryMutation()
  return (
    <WebUI.Popover ref={forwardedRef} {...restProps}>
      {(popover) => (
        <>
          {disclosure}
          <WebUI.PopoverContent
            aria-label="Category form"
            data-no-dnd="true"
            className={
              '[&_>_.PopoverContent-inner_>_.PopoverContent-body]:!max-w-[420px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:w-[420px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:px-8 [&_>_.PopoverContent-inner_>_.PopoverContent-body]:py-8'
            }
            arrow
            unstable_autoFocusOnShow={false}
          >
            <WebUI.VStack className="gap-6">
              <WebUI.Heading as="h2">Category</WebUI.Heading>
              <WebUI.Text className="font-light text-ds-sm">
                Create a category to group items within a collection.
              </WebUI.Text>

              <Formik
                enableReinitialize
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Required'),
                })}
                initialValues={{
                  name: category?.name ?? '',
                  description: category?.description ?? '',
                }}
                onSubmit={async (values, formikHelpers) => {
                  if (category) {
                    await updateCategoryMutation.mutateAsync({
                      pathParams: {
                        tabId: collectionId,
                        categoryId: category.id,
                      },
                      body: {...values, anchor: false},
                    })
                  } else {
                    await createCategoryMutation.mutateAsync({
                      pathParams: {
                        tabId: collectionId,
                      },
                      body: {...values, anchor: false},
                    })
                  }
                  formikHelpers.resetForm()
                }}
              >
                {(formik) => (
                  <WebUI.Form
                    className="[&_>_.Form-inner]:gap-6"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                  >
                    <WebUI.FormField
                      size="compact"
                      required
                      label="Category Name"
                      error={formik.errors.name}
                    >
                      <WebUI.Input
                        name="name"
                        placeholder="Category Name"
                        size="compact"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </WebUI.FormField>
                    <WebUI.FormField error={formik.errors.description}>
                      <WebUI.RichTextEditor
                        className="h-[180px] min-w-[280px] resize-none overflow-auto text-ds-sm"
                        name="description"
                        placeholder="Description (optional)"
                        initialMarkdownValue={formik.values.description}
                        onMarkdownValueChange={(newDescription) =>
                          formik.setFieldValue('description', newDescription)
                        }
                      >
                        <WebUI.RichTextEditorToolbar
                          className={
                            '!justify-end [&_>_.RichTextEditorToolbar-contentMain]:flex-0 [&_>_.RichTextEditorToolbar-extra]:flex-[0_0_0px]'
                          }
                          rootClassName="-order-1"
                          pick={['bold', 'italic', 'a']}
                        />
                      </WebUI.RichTextEditor>
                    </WebUI.FormField>

                    <WebUI.HStack className="gap-3">
                      <WebUI.Button
                        type="button"
                        loading={formik.isSubmitting}
                        onClick={async () => {
                          const isFormValid = formik.isValid
                          await formik.submitForm()
                          if (isFormValid) {
                            popover.hide()
                          }
                        }}
                      >
                        Save
                      </WebUI.Button>
                      {!category && (
                        <WebUI.Button
                          type="button"
                          disabled={formik.isSubmitting}
                          onClick={() => formik.submitForm()}
                        >
                          Save and Add Another
                        </WebUI.Button>
                      )}
                    </WebUI.HStack>
                  </WebUI.Form>
                )}
              </Formik>
            </WebUI.VStack>
          </WebUI.PopoverContent>
        </>
      )}
    </WebUI.Popover>
  )
})

// MARK: – DisplayOptionsPopover

interface DisplayOptionsPopoverProps extends WebUI.PopoverProps {
  collectionId: number
}

const DisplayOptionsPopover = ({
  collectionId,
  ...restProps
}: DisplayOptionsPopoverProps) => {
  const popoverRef = useRef<WebUI.PopoverInstance>(null)
  const collectionQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const updateCollectionMutation = useUpdateTabMutation()

  return (
    <WebUI.Popover ref={popoverRef} {...restProps}>
      <WebUI.PopoverDisclosure
        size="compact"
        variant="outlined"
        iconBefore={<WebUI.PhosphorIcon icon="squares-four" width={20} />}
      >
        Display Options
      </WebUI.PopoverDisclosure>
      <WebUI.PopoverContent
        aria-label="Item list display options"
        className={
          '[&_>_.PopoverContent-inner_>_.PopoverContent-body]:!max-w-[420px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:px-8 [&_>_.PopoverContent-inner_>_.PopoverContent-body]:py-8'
        }
        unstable_autoFocusOnShow={false}
        arrow
      >
        <WebUI.VStack className="gap-6">
          <WebUI.Heading as="h2">Display Options</WebUI.Heading>

          <WebUI.VStack className="gap-3">
            <WebUI.HStack className="items-start justify-between gap-8">
              <WebUI.Text>Choose list or gallery view</WebUI.Text>
              <WebUI.ToggleGroup
                className="[&_.ToggleGroupItem]:text-ds-lg"
                defaultValue={
                  collectionQuery.data?.options?.defaultToGalleryView
                    ? 'gallery'
                    : 'list'
                }
                onValueChange={(newValue) =>
                  updateCollectionMutation.mutate({
                    pathParams: {
                      tabId: collectionId,
                    },
                    body: {
                      options: {
                        defaultToGalleryView: newValue === 'gallery',
                      },
                    },
                  })
                }
              >
                <WebUI.DeprecatedTooltip label="List">
                  <WebUI.ToggleGroupItem
                    value="list"
                    as={WebUI.IconButton}
                    size="default_alt"
                  >
                    <WebUI.PhosphorIcon icon="list" />
                  </WebUI.ToggleGroupItem>
                </WebUI.DeprecatedTooltip>
                <WebUI.DeprecatedTooltip label="Gallery">
                  <WebUI.ToggleGroupItem
                    value="gallery"
                    as={WebUI.IconButton}
                    size="default_alt"
                  >
                    <WebUI.PhosphorIcon icon="squares-four" />
                  </WebUI.ToggleGroupItem>
                </WebUI.DeprecatedTooltip>
              </WebUI.ToggleGroup>
            </WebUI.HStack>

            <span className="max-w-[240px] text-ds-sm">
              {collectionQuery.data?.options?.defaultToGalleryView ? (
                <>
                  Your items will be displayed as a{' '}
                  <span className="font-bold">gallery</span>
                  —great for online stores and showcasing items with images.
                </>
              ) : (
                <>
                  Your items will be displayed as a{' '}
                  <span className="font-bold">list</span>— great for long item
                  names and items that do not have images.
                </>
              )}
            </span>
          </WebUI.VStack>
          <WebUI.Separator variant="primary" />

          <WebUI.Disclosure
            visible={collectionQuery.data?.options?.hideSoldOutItems ?? false}
          >
            <WebUI.Switch
              className={
                '[&_>_.Switch-input]:order-1 [&_>_.Switch-input]:ml-3 [&_>_.Switch-label]:m-0 [&_>_.Switch-label]:grow [&_>_.Switch-label]:font-normal'
              }
              checked={collectionQuery.data?.options?.hideSoldOutItems ?? false}
              onChange={(event) =>
                updateCollectionMutation.mutate({
                  pathParams: {
                    tabId: collectionId,
                  },
                  body: {options: {hideSoldOutItems: event.target.checked}},
                })
              }
            >
              Hide “sold out” items
            </WebUI.Switch>
            <WebUI.DisclosureContent>
              <WebUI.VStack>
                <span className="max-w-[240px] text-ds-sm">
                  Items with a quantity available of “0” will be displayed on
                  your page with “sold out”.
                </span>
              </WebUI.VStack>
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        </WebUI.VStack>
      </WebUI.PopoverContent>
    </WebUI.Popover>
  )
}

// MARK: – NavigationMenuPopover

interface NavigationMenuPopoverProps extends WebUI.PopoverProps {
  collectionId: number
}

const NavigationMenuPopover = ({
  collectionId,
  ...restProps
}: NavigationMenuPopoverProps) => {
  const popoverRef = useRef<WebUI.PopoverInstance>(null)
  const categoriesQuery = api.tabCategories.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })

  const formik = useFormik<{selectedCategoryIds: Record<number, boolean>}>({
    enableReinitialize: true,
    initialValues: {
      selectedCategoryIds: Util.mapToObj(
        categoriesQuery.data ?? [],
        (category) => [String(category.id), category.anchor],
      ),
    },
    onSubmit: async (values) => {
      await api.misc.batch.fetch({
        body: {
          sequential: true,
          ops:
            categoriesQuery.data?.map((category) => ({
              method: 'patch',
              url: `/api/users/tabs/${collectionId}/categories/${category.id}`,
              params: {anchor: values.selectedCategoryIds[category.id]},
            })) ?? [],
        },
      })
      categoriesQuery.refetch()
      popoverRef.current?.hide()
    },
  })

  if (!categoriesQuery.data || categoriesQuery.data.length === 0) {
    return null
  }

  return (
    <WebUI.Popover ref={popoverRef} onDidHide={formik.resetForm} {...restProps}>
      <WebUI.PopoverDisclosure size="compact" variant="secondary">
        Navigation Menu
      </WebUI.PopoverDisclosure>
      <WebUI.PopoverContent
        aria-label="Item list navigation menu"
        className={
          '[&_>_.PopoverContent-inner_>_.PopoverContent-body]:!max-w-[420px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:px-8 [&_>_.PopoverContent-inner_>_.PopoverContent-body]:py-8'
        }
        arrow
      >
        <WebUI.VStack className="gap-6">
          <WebUI.Heading as="h2">Navigation Menu</WebUI.Heading>
          <WebUI.Text className="font-light text-ds-sm">
            Select category names to include in a drop-down menu at the top of
            your collection page. Navigational menus give your payers an easy
            way to filter items.
          </WebUI.Text>

          <WebUI.Form
            className="[&_>_.Form-inner]:gap-8"
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <WebUI.VStack className="max-h-[240px] gap-4 overflow-y-auto">
              {categoriesQuery.data?.map((category) => (
                <WebUI.Checkbox
                  key={category.id}
                  name={`selectedCategoryIds[${category.id}]`}
                  checked={!!formik.values.selectedCategoryIds[category.id]}
                  onChange={(event) =>
                    formik.setFieldValue(
                      `selectedCategoryIds[${category.id}]`,
                      event.target.checked,
                    )
                  }
                  onBlur={formik.handleBlur}
                >
                  {category.name}
                </WebUI.Checkbox>
              ))}
            </WebUI.VStack>
            <WebUI.HStack className="gap-3">
              <WebUI.Button type="submit" loading={formik.isSubmitting}>
                Save
              </WebUI.Button>
              <WebUI.Button
                variant="secondary"
                disabled={formik.isSubmitting}
                onClick={() =>
                  formik.setFieldValue(
                    'selectedCategoryIds',
                    Util.mapToObj(categoriesQuery.data ?? [], (category) => [
                      String(category.id),
                      false,
                    ]),
                  )
                }
              >
                Clear All
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.Form>
        </WebUI.VStack>
      </WebUI.PopoverContent>
    </WebUI.Popover>
  )
}

// MARK: – ItemsToolbarSearchBar

interface ItemsToolbarSearchBarProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  errorMessage: string
  onResetErrorMessage: () => void
  onSubmit: (keyword: string) => void
}

const ItemsToolbarSearchBar = ({
  errorMessage,
  onResetErrorMessage,
  onSubmit,
  ...restProps
}: ItemsToolbarSearchBarProps) => {
  const [keyword, setKeyword] = useState('')
  const onResetErrorMessageRef = useLiveRef(onResetErrorMessage)
  const inputRef = useRef<HTMLInputElement>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onResetErrorMessageRef.current?.()
  }, [keyword])

  return (
    <WebUI.HStack
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        if (keyword.length > 0) {
          onSubmit(keyword)
        }
      }}
      {...restProps}
    >
      <div className="relative">
        <WebUI.Input
          className="min-h-[auto] w-full min-w-[200px]"
          size="compact"
          placeholder="Search by item name"
          value={keyword}
          onChange={(event) => {
            setKeyword(event.target.value)
          }}
          ref={inputRef}
        />
        <WebUI.IconButton
          className="-translate-y-1/2 absolute top-1/2 right-2 text-gray400"
          size="small"
          type="button"
          onClick={() => {
            setKeyword('')
            inputRef?.current?.focus()
          }}
        >
          {keyword.length > 0 ? (
            <WebUI.PhosphorIcon icon="x" />
          ) : (
            <WebUI.PhosphorIcon icon="magnifying-glass" />
          )}
        </WebUI.IconButton>
      </div>
      {errorMessage.length > 0 && (
        <WebUI.Ellipsis className="flex items-center text-ds-sm">
          {errorMessage}
        </WebUI.Ellipsis>
      )}
    </WebUI.HStack>
  )
}
