import {
  Cell,
  ColumnDef,
  DisplayColumnDef,
  FilterFn,
  PaginationState,
  Row,
  RowData,
  SortingFn,
  SortingState,
  Table,
  TableOptions,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from '@tanstack/react-table'
import {
  ForwardRefComponent,
  genericForwardRef,
  genericMemo,
} from '@cheddarup/react-util'
import {fuzzyMatch, pickBy} from '@cheddarup/util'
import React, {useContext, useMemo} from 'react'

import {PhosphorIcon} from '../../icons'
import {Checkbox} from '../Checkbox'
import {Ellipsis} from '../Ellipsis'
import {HStack, VStack} from '../Stack'
import {IconButton} from '../IconButton'
import {Skeleton} from '../Skeleton'
import Paginator from '../Paginator'
import {cn} from '../../utils'

export {createColumnHelper}
export type {ColumnDef, FilterFn, PaginationState, SortingFn, SortingState}

interface TableViewInnerContextValue<D extends object = {}> extends Table<D> {}

const TableViewInnerContext = React.createContext(
  {} as TableViewInnerContextValue,
)

// MARK: – TableView

export type TableViewInstance<D extends object> = Table<D>

export interface TableViewProps<D extends object>
  extends Pick<
      TableOptions<D>,
      | 'autoResetAll'
      | 'autoResetExpanded'
      | 'autoResetPageIndex'
      | 'getRowId'
      | 'onRowSelectionChange'
      | 'onPaginationChange'
      | 'onSortingChange'
      | 'onStateChange'
      | 'columns'
      | 'data'
      | 'initialState'
      | 'state'
      | 'enableRowSelection'
      | 'manualPagination'
      | 'globalFilterFn'
    >,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  loading?: boolean
  loadingRowsCount?: number
  pageCount?: number
  sortable?: boolean
  manualSortBy?: boolean
  sortByTogglesVisible?: boolean
  selectAllVisible?: boolean
  disableSortRemove?: boolean
  getCellProps?: (
    cell: Cell<D, unknown>,
    table: Table<D>,
  ) => React.ComponentPropsWithoutRef<'div'>
  getRowProps?: (
    row: Row<D>,
    table: Table<D>,
  ) => Partial<React.ComponentPropsWithoutRef<typeof TableViewRow>>
  children?: React.ReactNode | ((table: Table<D>) => React.ReactNode)
}

export const TableView = genericForwardRef(
  <D extends object>(
    {
      columns: columnsProp,
      data: dataProp,
      loading,
      loadingRowsCount = 16,
      initialState,
      autoResetAll,
      autoResetExpanded,
      autoResetPageIndex,
      state,
      pageCount,
      manualPagination,
      sortable,
      manualSortBy,
      sortByTogglesVisible = false,
      enableRowSelection,
      selectAllVisible,
      disableSortRemove,
      onStateChange,
      onRowSelectionChange,
      onPaginationChange,
      onSortingChange,
      globalFilterFn = fuzzyFilter,
      getRowProps,
      getCellProps,
      getRowId,
      className,
      children,
      ...restProps
    }: TableViewProps<D> & {ref?: React.Ref<HTMLDivElement>},
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const defaultColumn: Partial<ColumnDef<D>> = useMemo(
      () => ({
        cell: ({
          cell: {
            column: {columnDef},
            getValue,
          },
        }) => (
          <HStack
            className={cn(
              'min-w-0 max-w-full flex-[1] items-center py-2',
              (
                {
                  left: 'justify-start',
                  center: 'justify-center',
                  right: 'justify-end',
                } as const
              )[columnDef.meta?.align ?? 'left'],
            )}
          >
            <Ellipsis
              className="[font-weight:inherit]"
              style={{textAlign: columnDef.meta?.align}}
            >
              {getValue() as any}
            </Ellipsis>
          </HStack>
        ),
        size: 160,
        minSize: 60,
        maxSize: Number.MAX_SAFE_INTEGER,
        filterFn: 'fuzzy',
        enableGlobalFilter: true,
      }),
      [],
    )

    const columns = useMemo(
      () =>
        loading
          ? columnsProp.map(
              (column) =>
                pickBy(
                  {
                    id: column.id,
                    header: column.header ?? '',
                    footer: column.footer,
                    size: column.size ?? 160,
                    maxSize: column.maxSize ?? 200,
                    minSize: column.minSize ?? 60,
                    align: (column as any).align,
                    accessorFn: () => '' as any,
                    cell: () => (
                      <Skeleton
                        width={column.size ?? column.minSize ?? 160}
                        height={16}
                      />
                    ),
                  },
                  (v) => v !== undefined,
                ) as ColumnDef<D>,
            )
          : enableRowSelection && columnsProp.length > 0
            ? [
                {
                  id: '_checkbox_',
                  minSize: 40,
                  size: 40,
                  maxSize: 40,
                  header:
                    selectAllVisible === false
                      ? ''
                      : ({table}) => (
                          <HStack className="items-center justify-center px-2">
                            <Checkbox
                              state={
                                table.getIsSomeRowsSelected()
                                  ? 'indeterminate'
                                  : table.getIsAllRowsSelected()
                              }
                              onChange={table.getToggleAllRowsSelectedHandler()}
                            />
                          </HStack>
                        ),
                  cell: ({row}) => (
                    <Checkbox
                      state={
                        row.getIsSomeSelected()
                          ? 'indeterminate'
                          : row.getIsSelected()
                      }
                      disabled={!row.getCanSelect()}
                      onChange={row.getToggleSelectedHandler()}
                    />
                  ),
                } as DisplayColumnDef<D>,
                ...columnsProp,
              ]
            : columnsProp,
      [loading, columnsProp, enableRowSelection, selectAllVisible],
    )
    const data = useMemo(
      () =>
        loading
          ? Array.from<D>({length: loadingRowsCount}).map(
              () => ({id: Math.random()}) as any,
            )
          : dataProp,
      [loading, loadingRowsCount, dataProp],
    )

    const table = useReactTable<D>({
      defaultColumn,
      columns,
      data,
      initialState,
      state,
      filterFns: {
        fuzzy: fuzzyFilter,
      },
      sortingFns: {
        fuzzy: fuzzySort,
      },
      autoResetAll,
      autoResetExpanded,
      autoResetPageIndex,
      globalFilterFn,
      getColumnCanGlobalFilter: truthy,
      pageCount,
      manualPagination,
      manualSorting: manualSortBy,
      onStateChange,
      onPaginationChange,
      enableRowSelection,
      enableSortingRemoval: !disableSortRemove,
      getRowId,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel:
        pageCount == null ||
        manualPagination ||
        onPaginationChange ||
        initialState?.pagination ||
        state?.pagination
          ? undefined
          : getPaginationRowModel(),
      getSortedRowModel: sortable ? getSortedRowModel() : undefined,
      ...(onRowSelectionChange ? {onRowSelectionChange} : undefined),
      ...(onSortingChange ? {onSortingChange} : undefined),
    })

    const headerVisible = columns.some((col) => !!col.header)

    return (
      <TableViewInnerContext.Provider value={table as any}>
        <VStack
          ref={forwardedRef}
          role="grid"
          className={cn(
            'TableView',
            'no-scrollbar relative max-h-full min-h-0 flex-[1] overflow-x-auto bg-natural-100 text-gray800',
            className,
          )}
          {...restProps}
        >
          {headerVisible && (
            <VStack className="TableView-headerGroupList">
              {table.getHeaderGroups().map((hg, idx) => (
                <HStack
                  key={hg.id || String(idx)}
                  role="row"
                  className={
                    'TableView-headerGroup flex w-fit min-w-full border-b'
                  }
                >
                  {hg.headers.map((h) => {
                    if (h.column.columnDef.meta?.invisible) {
                      return null
                    }

                    const hElement = flexRender(
                      h.column.columnDef.header,
                      h.getContext(),
                    )

                    return (
                      <HStack
                        key={h.id}
                        role="columnheader"
                        aria-colspan={h.colSpan}
                        className={cn(
                          'TableView-header',
                          'items-center',
                          (
                            {
                              left: 'justify-start',
                              center: 'justify-center',
                              right: 'justify-end',
                            } as const
                          )[h.column.columnDef.meta?.align ?? 'left'],
                        )}
                        style={{
                          flex: `${h.column.columnDef.size ?? 0} 0 ${
                            h.column.columnDef.size ?? 0
                          }px`,
                          width: `${h.column.columnDef.size ?? 0}px`,
                          minWidth: h.column.columnDef.minSize,
                          maxWidth: h.column.columnDef.maxSize,
                        }}
                      >
                        {typeof hElement === 'string' ? (
                          <Ellipsis
                            className="TableView-headerText self-center p-2 text-ds-xs"
                            style={{textAlign: h.column.columnDef.meta?.align}}
                          >
                            {hElement}
                          </Ellipsis>
                        ) : (
                          hElement
                        )}
                        {sortByTogglesVisible &&
                          (h.column.getCanSort() ||
                            (!!h.column.accessorFn &&
                              h.column.columnDef.enableSorting !== false &&
                              manualSortBy)) && (
                            <IconButton
                              size="default_alt"
                              onClick={h.column.getToggleSortingHandler()}
                            >
                              {h.column.getIsSorted() ? (
                                h.column.getIsSorted() === 'desc' ? (
                                  <PhosphorIcon icon="sort-descending" />
                                ) : (
                                  <PhosphorIcon icon="sort-ascending" />
                                )
                              ) : (
                                <PhosphorIcon icon="funnel-simple" />
                              )}
                            </IconButton>
                          )}
                      </HStack>
                    )
                  })}
                </HStack>
              ))}
            </VStack>
          )}

          <VStack className="TableView-body">
            {table.getRowModel().rows.map((row) => {
              const modifiedRowProps = getRowProps?.(row, table)

              return (
                <TableViewRow
                  key={row.id}
                  {...modifiedRowProps}
                  className={cn(
                    'flex w-fit min-w-full border-b py-4',
                    modifiedRowProps?.className,
                  )}
                  row={row}
                >
                  {row.getVisibleCells().map((cell) => {
                    if (cell.column.columnDef.meta?.invisible) {
                      return null
                    }

                    const modifiedCellProps = getCellProps?.(cell, table)

                    return (
                      <TableViewCell
                        key={cell.id}
                        role="cell"
                        {...modifiedCellProps}
                        className={cn(
                          'flex overflow-hidden',
                          modifiedCellProps?.className,
                        )}
                        style={{
                          flex: `${cell.column.columnDef.size ?? 0} 0 ${
                            cell.column.columnDef.size ?? 0
                          }px`,
                          width: `${cell.column.columnDef.size ?? 0}px`,
                          minWidth: cell.column.columnDef.minSize,
                          maxWidth: cell.column.columnDef.maxSize,
                          justifyContent: {
                            left: 'flex-start',
                            center: 'center',
                            right: 'flex-end',
                          }[cell.column.columnDef.meta?.align ?? 'left'],
                          ...modifiedCellProps?.style,
                        }}
                        cell={cell}
                      />
                    )
                  })}
                </TableViewRow>
              )
            })}
          </VStack>
        </VStack>

        {typeof children === 'function' ? children(table) : children}
      </TableViewInnerContext.Provider>
    )
  },
)

// MARK: – TableViewRow

interface TableViewRowProps<D extends object> {
  row: Row<D>
}

const TableViewRow = React.forwardRef(
  (
    {as: Comp = 'div', row, className, children, ...restProps},
    forwardedRef,
  ) => (
    <Comp
      ref={forwardedRef}
      role="row"
      data-rowid={row.id}
      className={cn(
        'TableViewRow',
        'relative items-center',
        'focus:outline-none',
        'data-[rowhovered=true]:bg-tableRowHoverBackground',
        className,
      )}
      tabIndex={0}
      {...restProps}
    >
      {children}
    </Comp>
  ),
) as ForwardRefComponent<'div', TableViewRowProps<any>>

// MARK: - TableViewCell

interface TableViewCellProps<D extends object>
  extends React.ComponentPropsWithoutRef<'div'> {
  cell: Cell<D, unknown>
}

const TableViewCell = genericMemo(
  <D extends object>({
    className,
    cell,
    ...restProps
  }: TableViewCellProps<D>) => (
    <div
      // biome-ignore lint/a11y/useSemanticElements:
      role="gridcell"
      data-columnid={cell.column.id}
      className={cn(
        'TableViewCell',
        cell.column.columnDef.meta?.subtle !== false && 'TableViewCell--subtle',
        'px-2 font-body',
        'focus:outline-none',
        cell.column.columnDef.meta?.subtle === false
          ? 'font-normal'
          : 'font-light',
        className,
      )}
      tabIndex={0}
      {...restProps}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  ),
)

// MARK: – Helpers

function fuzzyMatchWithScore(filterValue: string, value: string) {
  return fuzzyMatch(filterValue, value, {
    withScore: true,
    caseSensitive: false,
  })
}

const fuzzyFilter: FilterFn<any> = (row, columnId, filterValue, addMeta) => {
  const columnValue = row.getValue<any>(columnId) ?? ''
  const matchRes = fuzzyMatchWithScore(
    filterValue.toString(),
    columnValue.toString(),
  )

  addMeta({itemRank: matchRes})

  return matchRes.match
}

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    const scoreA = rowA.columnFiltersMeta[columnId]?.itemRank.score ?? 0
    const scoreB = rowB.columnFiltersMeta[columnId]?.itemRank.score ?? 0

    dir = scoreA === scoreB ? 0 : scoreA > scoreB ? -1 : 1
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

export function getSpannedRowHelpers<D extends object>(
  row: Row<D>,
  table: Table<D>,
  columnId?: string,
): React.ComponentPropsWithoutRef<'div'> {
  const currValue = columnId
    ? row.getValue(columnId)
    : row.getAllCells()[0]?.getValue()

  const nextRows = table.getRowModel().rows.slice(row.index + 1)

  let isSpanned = false

  for (const r of nextRows) {
    const topCell = r.getAllCells()[0]
    if (topCell && topCell.getValue() === currValue) {
      isSpanned = true
      ;(topCell as any).isRowSpanned = true
    } else {
      break
    }
  }

  return {
    className: isSpanned ? 'border-b-0' : undefined,
  }
}

const truthy = () => true

// MARK: – TablePaginator

export interface TablePaginatorProps {}

export const TablePaginator = React.forwardRef<
  HTMLDivElement,
  TablePaginatorProps
>((props, forwardedRef) => {
  const table = useContext(TableViewInnerContext)

  const paginationState = table.getState().pagination

  return (
    <Paginator
      ref={forwardedRef}
      page={paginationState.pageIndex}
      perPage={paginationState.pageSize}
      pageCount={table.getPageCount()}
      onPageChange={({selected: newPageIndex}) =>
        table.setPageIndex(newPageIndex)
      }
      {...props}
    />
  )
})

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    subtle?: boolean
    invisible?: boolean
    align?: 'left' | 'right' | 'center'
  }

  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }

  interface SortingFns {
    fuzzy: SortingFn<unknown>
  }

  interface FilterMeta {
    itemRank: ReturnType<typeof fuzzyMatchWithScore>
  }
}
