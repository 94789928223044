import {deepPartialify, z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  paginationSchema,
  tabItemReportSchema,
  tabItemSchema,
  tabItemWithPaymentInfoSchema,
  waitlistSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/items',
    responseSchema: tabItemSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId',
    responseSchema: tabItemSchema,
  }),
  search: makeEndpoint({
    path: 'users/tabs/:tabId/items/search',
    queryParamsSchema: z
      .object({
        perPage: z.number(),
        page: z.number(),
        sort: z.string(),
        direction: z.enum(['asc', 'desc']),
        search: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: tabItemWithPaymentInfoSchema.array(),
      pagination: paginationSchema,
      search: z.string().nullish(),
      sort: z.string(),
    }),
  }),
  report: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/report',
    method: 'POST',
    isMutation: false,
    responseSchema: tabItemReportSchema,
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/items',
    method: 'POST',
    bodySchema: deepPartialify(
      z.object({
        ...tabItemSchema.shape,
        amount: tabItemSchema.shape.amount.nullish(),
        available_quantity: tabItemSchema.shape.available_quantity.nullish(),
        options: z.object({
          ...tabItemSchema.shape.options.shape,
          variants: z.object({
            ...tabItemSchema.shape.options.shape.variants.unwrap().unwrap()
              .shape,
            listings: z
              .object({
                ...tabItemSchema.shape.options.shape.variants.unwrap().unwrap()
                  .shape.listings.element.shape,
                amount: tabItemSchema.shape.options.shape.variants
                  .unwrap()
                  .unwrap()
                  .shape.listings.element.shape.amount.nullish(),
                available_quantity: tabItemSchema.shape.options.shape.variants
                  .unwrap()
                  .unwrap()
                  .shape.listings.element.shape.available_quantity.nullish(),
              })
              .array(),
          }),
        }),
      }),
    ),
    responseSchema: tabItemSchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId',
    method: 'PATCH',
    bodySchema: deepPartialify(
      z.object({
        ...tabItemSchema.shape,
        amount: tabItemSchema.shape.amount.nullish(),
        available_quantity: tabItemSchema.shape.available_quantity.nullish(),
        options: z.object({
          ...tabItemSchema.shape.options.shape,
          variants: z.object({
            ...tabItemSchema.shape.options.shape.variants.unwrap().unwrap()
              .shape,
            listings: z
              .object({
                ...tabItemSchema.shape.options.shape.variants.unwrap().unwrap()
                  .shape.listings.element.shape,
                amount: tabItemSchema.shape.options.shape.variants
                  .unwrap()
                  .unwrap()
                  .shape.listings.element.shape.amount.nullish(),
                available_quantity: tabItemSchema.shape.options.shape.variants
                  .unwrap()
                  .unwrap()
                  .shape.listings.element.shape.available_quantity.nullish(),
              })
              .array(),
          }),
        }),
      }),
    ),
    responseSchema: tabItemSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  updateBatch: makeEndpoint({
    path: 'users/tabs/:tabId/items/modify',
    method: 'POST',
    bodySchema: z.object({
      tab_item_ids: z.number().array(),
      hidden: z.boolean().optional(),
      parent_id: z.boolean().optional(),
    }),
    responseSchema: tabItemSchema.array(),
  }),
  deleteBulk: makeEndpoint({
    path: 'users/tabs/:tabId/items/delete',
    method: 'POST',
    bodySchema: z.object({
      tab_item_ids: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  clone: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/clone',
    method: 'POST',
    responseSchema: tabItemSchema,
  }),
  upload: makeEndpoint({
    path: 'users/tabs/:tabId/uploads/items',
    method: 'POST',
    bodySchema: z.any(),
    responseSchema: z.object({
      created: z.number(),
      updated: z.number(),
    }),
  }),
  alphabetize: makeEndpoint({
    path: 'users/tabs/:tabId/alphabetize',
    method: 'PATCH',
    bodySchema: z.object({
      type: z.literal('items'),
    }),
    responseSchema: z.object({
      updated: z.number(),
    }),
  }),
  move: makeEndpoint({
    path: 'users/tabs/:tabId/items/move',
    method: 'POST',
    bodySchema: z.object({
      collection_id: z.number(),
      tab_item_ids: z.number().array(),
      include_categories: z.boolean(),
    }),
    responseSchema: z.any(),
  }),
  adjustPrices: makeEndpoint({
    path: 'users/tabs/:tabId/items/adjust_prices',
    method: 'PATCH',
    bodySchema: z.object({
      tab_item_ids: z.number().array(),
      basis: z.enum(['sale', 'retail']),
      fixed: z.number().nullish(),
      percentage: z.number().nullish(),
    }),
    responseSchema: tabItemSchema.array(),
  }),
  listWaitlists: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/waitlist_entries',
    responseSchema: waitlistSchema.array(),
  }),
  addToWaitlist: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/waitlist_entries',
    method: 'POST',
    bodySchema: z.object({
      first_name: z.string(),
      last_name: z.string(),
      email: z.string(),
      quantity_desired: z.number(),
      variant_uuid: z.string().optional(),
    }),
    responseSchema: waitlistSchema,
  }),
  deleteFromWaitlist: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/waitlist_entries/:waitlistId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  deleteBatchFromWaitlist: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/waitlist_entries/delete',
    method: 'POST',
    bodySchema: z.object({
      waitlist_ids: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
}
