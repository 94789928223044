import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {groupPageSchema, publicTabSchema, s3ImageSchema} from '../schemas'

export default {
  detail: makeEndpoint({
    path: 'collections/:tabId',
    queryParamsSchema: z
      .object({
        name: z.string(),
        email: z.string(),
        access_code: z.string(),
        posCode: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: publicTabSchema,
  }),
  home: makeEndpoint({
    path: 'collections/:tabId/home',
    responseSchema: groupPageSchema.extend({
      user: z.object({
        id: z.number(),
        email: z.string(),
        first_name: z.string(),
        last_name: z.string(),
        display_name: z.string(),
        slug: z.string(),
        profile_pic: s3ImageSchema.nullish(),
      }),
    }),
  }),
  contact: makeEndpoint({
    path: 'collections/:tabId/contact',
    method: 'POST',
    bodySchema: z.object({
      name: z.string(),
      email: z.string(),
      message: z.string(),
      captchaToken: z.string().optional(),
    }),
    responseSchema: z.any(),
  }),
}
