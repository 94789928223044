import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useDeleteCartItemMutation} from '@cheddarup/api-client'
import ImagesUtils from 'src/helpers/ImagesUtils'
import CartHelpers from 'src/helpers/CartHelpers'
import EmptyCartIcon from 'src/images/EmptyCartIcon.svg'
import {LinkButton} from 'src/components/LinkButton'

import useCart from '../../hooks/useCart'
import usePublicCollection from '../../hooks/usePublicCollection'
import {
  CartObjectDetailsDisclosure,
  CartOverviewFormRow,
  CartOverviewSignUpRow,
} from '../../components/CartOverview'
import {usePayerUIState} from '../../PayerUIStateProvider'
import {isFormsBeforeItems} from '../../utils/public-collection-utils'
import {PayerContinueButton} from '../../components/PayerContinueButton'
import {RecurringPaymentIndicator} from '../../components/RecurringPaymentIndicator'
import {useCurrentPayerSegment} from '../../utils/payer-navigation-utils'
import {
  getPayerBrandKitColors,
  hasMissingRequiredFormFields,
  hasMissingRequiredItems,
  hasMissingRequiredSignups,
} from '@cheddarup/core'

const CartInfoContainer: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const payerUIState = usePayerUIState()
  const currentPayerSegment = useCurrentPayerSegment()

  const isItemlessCollection = publicCollection.items.length === 0
  const isCartEmpty = CartHelpers.getRecordsCount(cart) === 0
  const isCartFormsOnly = cart?.items.length === 0 && cart.forms.length > 0
  const brandKitColors = getPayerBrandKitColors(
    publicCollection.organizer.branding?.color_palette.payerPage,
  )

  return (
    <WebUI.VStack
      className={WebUI.cn('h-full min-w-[240px] gap-4 p-8', className)}
      as={WebUI.Panel}
      variant="capsule"
      {...restProps}
    >
      <WebUI.VStack className="gap-1">
        <WebUI.Heading className="font-semibold" as="h2">
          {isCartFormsOnly ? 'Summary' : 'Order Summary'}
        </WebUI.Heading>
        {cart?.items.some((i) => i.tab_item.available_quantity != null) && (
          <WebUI.Text className="text-ds-sm text-orange-50">
            Items with a limited quantity are only secured once checkout is
            complete.
          </WebUI.Text>
        )}
      </WebUI.VStack>

      <WebUI.Separator />

      <WebUI.VStack className="grow gap-4 overflow-hidden">
        {isCartEmpty ? (
          <WebUI.VStack className="h-[160px] w-full justify-center gap-3 py-8">
            <img className="h-[3.4em]" src={EmptyCartIcon} alt="Empty cart" />
            <WebUI.Text className="text-center text-ds-sm">
              Your cart is empty
            </WebUI.Text>
          </WebUI.VStack>
        ) : (
          <>
            <WebUI.VStack className="grow gap-6 overflow-y-auto sm:max-h-[calc(100vh-470px)] sm:min-h-[88px] [&_>_.Stack]:flex-0">
              {cart?.forms.map((cartForm) => (
                <CartOverviewFormRow
                  key={cartForm.id}
                  collectionSlug={publicCollection.slug}
                  cartUuid={cart.uuid}
                  cartForm={cartForm}
                />
              ))}
              {CartHelpers.getSignUps(cart).map((cartSignUp) => (
                <CartOverviewSignUpRow
                  key={cartSignUp.signUp.id}
                  collectionSlug={publicCollection.slug}
                  cartUuid={cart?.uuid ?? ''}
                  cartSignUp={cartSignUp}
                />
              ))}
              {Util.sort(cart?.items ?? [])
                .asc((ci) => ci.id)
                .map((cartItem, idx) => {
                  const itemView = publicCollection.items.find(
                    ({id}) => id === cartItem.tab_item.id,
                  )
                  const hasEditableVariants =
                    !!itemView?.options.variants?.enabled &&
                    itemView.options.variants.options.some(
                      ({values}) => values.length > 1,
                    )

                  return (
                    <React.Fragment key={cartItem.id}>
                      {idx > 0 && <WebUI.Separator variant="primary" />}
                      <CartInfoItemListItemContainer
                        cartUuid={cart?.uuid ?? ''}
                        collectionSlug={publicCollection.slug}
                        allowQuantity={itemView?.allow_quantity ?? false}
                        hasItemViewFieldViews={
                          !!itemView && itemView.fields?.length > 0
                        }
                        hasEditableVariants={hasEditableVariants}
                        cartItem={cartItem}
                      />
                    </React.Fragment>
                  )
                })}
            </WebUI.VStack>
            {!isItemlessCollection && (
              <>
                <WebUI.Separator />
                <WebUI.Text className="text-right text-ds-sm text-gray750">
                  <span>Subtotal:</span>{' '}
                  <span className="font-semibold">
                    {Util.formatAmount(cart?.subtotal ?? 0)}
                  </span>
                </WebUI.Text>
              </>
            )}
          </>
        )}
        <WebUI.VStack className="flex-0 gap-4">
          {currentPayerSegment === 'items' &&
          hasMissingRequiredItems({publicTab: publicCollection, cart}) ? (
            <>
              <div className="text-center font-normal text-ds-sm text-orange-50">
                Additional items are required to proceed to checkout
              </div>
              <WebUI.Button
                className="w-full"
                size="large"
                variant="primary"
                roundness="capsule"
                onClick={() => {
                  payerUIState.setCartVisible(false)
                  payerUIState.itemPickerRef.current?.viewRequiredItems()
                }}
              >
                View Required Items
              </WebUI.Button>
            </>
          ) : currentPayerSegment === 'forms' &&
            (hasMissingRequiredFormFields({
              publicTab: publicCollection,
              cart,
            }) ||
              hasMissingRequiredSignups({
                publicTab: publicCollection,
                cart,
              })) ? (
            <>
              <div className="text-center font-normal text-ds-sm text-orange-50">
                Additional forms are required to proceed to checkout
              </div>
              <WebUI.Button
                className="w-full"
                size="large"
                variant="primary"
                roundness="capsule"
                onClick={() => {
                  payerUIState.setCartVisible(false)
                  payerUIState.formPickerRef.current?.viewRequiredForms()
                }}
              >
                View Required Forms
              </WebUI.Button>
            </>
          ) : publicCollection?.requirePayment &&
            !CartHelpers.hasNonZeroAmountItems(cart) ? (
            <>
              <WebUI.Text className="text-center text-ds-sm text-orange-50">
                An item is required to proceed to checkout.
              </WebUI.Text>
              <LinkButton
                variant="primary"
                roundness="capsule"
                preserveSearch
                to="../items"
                relative="nonContextualPath"
                onClick={() => payerUIState.setCartVisible(false)}
              >
                Add an item
              </LinkButton>
            </>
          ) : currentPayerSegment === 'checkout' ? null : (
            <>
              {!isItemlessCollection &&
                !isFormsBeforeItems(publicCollection) && (
                  <WebUI.Button
                    size="large"
                    variant="secondary"
                    roundness="capsule"
                    onClick={() => payerUIState.setCartVisible(false)}
                  >
                    Keep Shopping
                  </WebUI.Button>
                )}
              <PayerContinueButton
                size="large"
                disabled={isCartEmpty}
                onClick={() => payerUIState.setCartVisible(false)}
                style={{backgroundColor: brandKitColors.secondaryButton}}
              />
            </>
          )}
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – CartInfoItemListItemContainer

interface CartInfoItemListItemContainerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionSlug: string
  cartUuid: string
  allowQuantity: boolean
  hasItemViewFieldViews: boolean
  hasEditableVariants: boolean
  cartItem: Api.CheddarUpCartItem
}

const CartInfoItemListItemContainer = ({
  collectionSlug,
  allowQuantity,
  cartUuid,
  hasItemViewFieldViews,
  hasEditableVariants,
  cartItem,
  className,
  ...restProps
}: CartInfoItemListItemContainerProps) => {
  const deleteCartItemMutation = useDeleteCartItemMutation()
  return (
    <WebUI.VStack
      className={WebUI.cn('gap-3 font-normal', className)}
      {...restProps}
    >
      <WebUI.VStack className="gap-1">
        <WebUI.Heading as="h4">{cartItem.tab_item.name}</WebUI.Heading>
        {cartItem.tab_item.options.recurring?.enabled && (
          <RecurringPaymentIndicator />
        )}
        <WebUI.HStack className="items-center gap-1">
          <WebUI.Text className="min-h-0 min-w-0 flex-auto text-ds-sm">
            Qty: {cartItem.quantity}
          </WebUI.Text>
          <WebUI.Text className="text-right text-ds-sm">
            {cartItem.tab_item.amount_type === 'fixed' &&
              !!cartItem.tab_item.retailPrice &&
              cartItem.amount !== cartItem.tab_item.retailPrice && (
                <WebUI.Text className="mr-2 font-light text-gray400 line-through">
                  {Util.formatAmount(
                    cartItem.tab_item.retailPrice * cartItem.quantity,
                  )}
                </WebUI.Text>
              )}
            <WebUI.Text>{Util.formatAmount(cartItem.total)}</WebUI.Text>
          </WebUI.Text>
        </WebUI.HStack>
      </WebUI.VStack>
      <CartObjectDetailsDisclosure
        collectionSlug={collectionSlug}
        cartObject={cartItem}
        maxVisibleFieldsCount={3}
      />
      {cartItem.tab_item.images.length > 0 && (
        <img
          className="w-16"
          alt={cartItem.tab_item.name}
          src={
            ImagesUtils.getItemMainThumbnailUrl(
              cartItem.tab_item.images,
              {
                width: 240,
                height: 240,
              },
              cartItem.detail?.variant?.imageId,
            ) as any
          }
        />
      )}
      <WebUI.HStack className="gap-3">
        <WebUI.Button
          size="compact"
          variant="outlined"
          loading={deleteCartItemMutation.isPending}
          onClick={() =>
            deleteCartItemMutation.mutate({
              pathParams: {
                tabId: collectionSlug,
                cartUuid,
                itemId: cartItem.id,
              },
            })
          }
        >
          Remove
        </WebUI.Button>
        {(allowQuantity ||
          cartItem.tab_item.amount_type === 'open' ||
          hasItemViewFieldViews ||
          hasEditableVariants) && (
          <LinkButton
            size="compact"
            variant="secondary"
            preserveSearch
            to={{
              pathname: `../items/item/${cartItem.tab_item.id}`,
              search: `ciId=${cartItem.id}`,
            }}
            relative="nonContextualPath"
          >
            Edit
          </LinkButton>
        )}
      </WebUI.HStack>
    </WebUI.VStack>
  )
}

export default CartInfoContainer
