import React from 'react'
import {useLocation} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import config from 'src/config'
import {ConvertTemplateToTabButton} from 'src/components/ConvertTemplateToTabButton'
import {Logo} from 'src/components'
import {useIsAuthed} from 'src/hooks/useAuthToken'
import {UserAvatar} from 'src/components/UserMenu'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'

import {usePayerUIState} from '../PayerUIStateProvider'
import {CollectionShareButton} from './CollectionShareButton'
import usePublicCollection from '../hooks/usePublicCollection'
import {useCurrentPayerSegment} from '../utils/payer-navigation-utils'

export interface PayerHeaderProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {}

export const PayerHeader: React.FC<PayerHeaderProps> = ({
  className,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()
  const payerUIState = usePayerUIState()
  const currentSegment = useCurrentPayerSegment()
  const branding = publicCollection.organizer.branding

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'relative h-[76px] justify-center overflow-hidden bg-natural-100',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack
        className={WebUI.cn(
          'mx-4 h-full max-w-screen-xl flex-[1_0_auto] items-center gap-3 *:min-w-0 *:flex-[1_1_0px] sm:mx-8',
        )}
        {...restProps}
      >
        <WebUI.HStack className="max-w-[480px] items-center">
          <WebUI.Anchor
            href={
              branding?.logo
                ? (branding.footer.mainWebsiteLink?.link ?? '#')
                : config.links.marketingPage
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {branding?.enable_brand_logo && branding.logo ? (
              <img src={branding.logo.url} alt="logo" className="max-h-13" />
            ) : (
              <Logo />
            )}
          </WebUI.Anchor>
        </WebUI.HStack>
        <WebUI.HStack className="hidden items-center justify-center sm:flex">
          {(publicCollection.type === 'Template' ||
            !publicCollection.published_at) && (
            <WebUI.Text className="font-semibold text-ds-lg text-orange-50">
              Preview Only
            </WebUI.Text>
          )}
        </WebUI.HStack>
        <WebUI.HStack className="items-center justify-end gap-2">
          {publicCollection.type === 'Template' && (
            <ConvertTemplateToTabButton
              className="hidden sm:block [&_>_.Button-content]:font-semibold"
              templateId={publicCollection.id}
              size="default"
            />
          )}
          {publicCollection.type !== 'Template' &&
            !!publicCollection.published_at &&
            currentSegment !== 'checkout' && <PayerMenu />}
          {payerUIState.shouldRenderModalCloseButton && (
            <WebUI.ModalCloseButton className="static z-0 text-ds-xl" />
          )}
        </WebUI.HStack>
      </WebUI.HStack>
    </WebUI.HStack>
  )
}

// MARK: – PayerMenu

interface PayerMenuProps extends React.ComponentPropsWithoutRef<'button'> {}

const PayerMenu = React.forwardRef<WebUI.MenuInstance, PayerMenuProps>(
  ({className, ...restProps}, forwardedRef) => {
    const media = WebUI.useMedia()
    const location = useLocation()
    const isLoggedIn = useIsAuthed()
    const {publicCollection} = usePublicCollection()
    const branding = publicCollection.organizer.branding

    return (
      <WebUI.Menu ref={forwardedRef}>
        {(menu) => (
          <>
            <WebUI.MenuButton
              className={WebUI.cn('text-ds-xl', className)}
              size="default_alt"
              as={WebUI.IconButton}
              {...restProps}
            >
              <WebUI.PhosphorIcon icon="list" />
            </WebUI.MenuButton>

            <WebUI.MenuList
              className="[&_.MenuList-body]:!p-0 [&_.MenuList-body]:!pt-10 sm:[&_.MenuList-body]:!pt-0 min-w-[200px] [&_.MenuItem]:text-ds-base sm:[&_.MenuItem]:text-ds-sm [&_.MenuList-body]:rounded-none sm:[&_.MenuList-body]:rounded-large sm:[&_.MenuList-body]:p-1 [&_.MenuList-body_>_.MenuItem]:h-[3.2rem] [&_.MenuList-body_>_.MenuItem]:border-b [&_.MenuList-body_>_.MenuItem]:px-5 sm:[&_.MenuList-body_>_.MenuItem]:border-b-0"
              fullSize={!media.sm}
            >
              {!media.sm && (
                <WebUI.IconButton
                  className="absolute top-2 right-2"
                  onClick={() => menu.hide()}
                >
                  <WebUI.PhosphorIcon icon="x" width={25} />
                </WebUI.IconButton>
              )}
              {!branding?.enable_brand_logo &&
                (isLoggedIn ? (
                  <WebUI.MenuItem
                    as={LinkButton}
                    to="/logout"
                    iconBefore={<UserAvatar size={22} />}
                  >
                    Log Out
                  </WebUI.MenuItem>
                ) : (
                  <WebUI.MenuItem
                    as={LinkButton}
                    preserveSearch
                    to={`/login?redirect=${location.pathname}`}
                  >
                    Log In
                  </WebUI.MenuItem>
                ))}
              <WebUI.MenuItem
                hideOnClick={false}
                as={CollectionShareButton}
                onDidSucceed={() => menu.hide()}
                onDidFail={() => menu.hide()}
              >
                Share
              </WebUI.MenuItem>
              <WebUI.MenuItem
                as={LinkButton}
                relative="nonContextualPath"
                preserveSearch
                to="help"
              >
                Contact Organizer
              </WebUI.MenuItem>
              {publicCollection.organizer.branding?.footer.mainWebsiteLink
                ?.text && (
                <WebUI.MenuItem
                  as={WebUI.AnchorButton}
                  href={
                    publicCollection.organizer.branding.footer.mainWebsiteLink
                      .link
                  }
                  target="_blank"
                >
                  {
                    publicCollection.organizer.branding.footer.mainWebsiteLink
                      .text
                  }
                </WebUI.MenuItem>
              )}
              {publicCollection.is_team && publicCollection.groupPage && (
                <WebUI.MenuItem
                  as={LinkButton}
                  className="bg-gray100 font-semibold"
                  to={`/me/${publicCollection.organizer.id}`}
                >
                  View all collections
                </WebUI.MenuItem>
              )}
              {!publicCollection.is_team && (
                <WebUI.MenuItem
                  className="bg-gray100 font-semibold"
                  iconBefore={
                    <WebUI.LogoGraphicsIcon className="h-auto w-[22px]" />
                  }
                  as={LinkButton}
                  target="_blank"
                  to="/signup"
                >
                  Create your own page
                </WebUI.MenuItem>
              )}

              {!media.sm &&
                (branding?.enable_brand_logo && branding.logo ? (
                  <div className="mt-auto flex px-4 py-6">
                    <img
                      src={branding.logo.url}
                      alt="logo"
                      className="max-h-13"
                    />
                  </div>
                ) : (
                  <CheddarUpBrand className="mt-auto" />
                ))}
            </WebUI.MenuList>
          </>
        )}
      </WebUI.Menu>
    )
  },
)

// MARK: – CheddarUpBrand

const CheddarUpBrand: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const location = useLocation()
  const {publicCollection} = usePublicCollection()

  return (
    <WebUI.VStack
      className={WebUI.cn('justify-center bg-gray100 px-7 py-9', className)}
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <Logo className="self-start text-ds-md" forceDisplayText />
        {!publicCollection.is_team && (
          <WebUI.VStack className="gap-0_5">
            <WebUI.Text className="font-semibold text-ds-sm">
              Online payment pages and forms for groups
            </WebUI.Text>
            <Link
              className="text-ds-sm"
              variant="primary"
              preserveSearch
              to={`/login?redirect=${location.pathname}`}
            >
              Create your own page in minutes
            </Link>
          </WebUI.VStack>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}
