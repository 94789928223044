import lodash from 'lodash'
export {
  hasSubObject,
  invert,
  isEmpty,
  mapKeys,
  mapValues,
  merge,
  mergeDeep,
  omit,
  omitBy,
  pick,
  setPath as setAt,
} from 'remeda'

// @ts-expect-error
import _cartesianProduct from 'cartesian'
import {mapToObj} from 'remeda'

export {deepEqual} from 'fast-equals'

export {mapToObj}

const {isMatch, mergeWith} = lodash

export {isMatch, mergeWith}

export function pickBy<T, U extends T>(
  obj: Record<string, T> | null | undefined,
  predicate: (val: T, key: string) => val is U,
): Record<string, U>
export function pickBy<T>(
  obj: Record<string, T> | null | undefined,
  predicate: (val: T, key: string) => boolean,
): Record<string, T>
export function pickBy<T extends object>(
  obj: T | null | undefined,
  predicate: (val: T[keyof T], key: keyof T) => boolean,
): Partial<T>
export function pickBy(
  obj: Record<string, unknown> | null | undefined,
  predicate: (val: unknown, key: string) => boolean,
) {
  const ret = {} as Record<string, unknown>
  if (obj) {
    for (const prop in obj) {
      if (predicate(obj[prop], prop)) {
        ret[prop] = obj[prop]
      }
    }
  }
  return ret
}

// TODO: Replace this with something from remeda
export function objectFromObject<T, U = T>(
  obj: Record<string, T>,
  valueSelector: (key: string, value: T, index: number) => U,
  /** return undefined to filter out */
  keySelector: (key: string, value: T, index: number) => string | undefined = (
    key,
  ) => key,
) {
  let index = 0
  const ret: Record<string, U> = {}
  for (const [key, value] of Object.entries(obj)) {
    const newKey = keySelector(key, value, index)
    if (newKey != null) {
      if (newKey in ret) {
        console.warn(
          `Duplicate key "${newKey.toString()}" during objectFromObject`,
        )
      }
      ret[newKey] = valueSelector(key, value, index)
    }
    index++
  }
  return ret
}

// TODO: Replace this with something from remeda
export function arrayFromObject<T, U = T>(
  obj: Record<string, T>,
  selector: (key: string, value: T, index: number) => U,
) {
  const items: U[] = []
  const keys = Object.keys(obj)
  for (const key of keys) {
    // biome-ignore lint/style/noNonNullAssertion:
    items.push(selector(key, obj[key]!, keys.indexOf(key)))
  }
  return items
}

interface Cartesian {
  <T extends any[][]>(arr: T): T
  <T>(obj: Record<string, T[]>): Array<Record<string, T>>
}

const cartesianProduct: Cartesian = _cartesianProduct

export {cartesianProduct}
