import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import React, {useEffect, useMemo, useRef, useState} from 'react'

import type {FieldGroupToFieldLabelKeysMap} from './FieldGroupFieldsEdit/FieldGroupFieldsEdit'

// MARK: – Types

export type FieldGroupType =
  | Exclude<Api.FieldSetType, 'general'>
  | Exclude<Api.TabObjectFieldType, 'image'>

export type FieldGroupTypeLabelless = Extract<
  FieldGroupType,
  'layout_description' | 'layout_divider'
>
export type FieldGroupTypeLabelled = Exclude<
  FieldGroupType,
  FieldGroupTypeLabelless
>

export interface FieldGroup<TType extends FieldGroupType = FieldGroupType> {
  /** if an `uuid` is null, that's a new field existing locally only */
  uuid: string | null
  id: string
  type: TType
  required: TType extends FieldGroupTypeLabelled ? boolean : undefined
  fieldLabels: TType extends FieldGroupTypeLabelled
    ? Record<keyof FieldGroupToFieldLabelKeysMap[TType], string>
    : undefined
  placeholders: TType extends FieldGroupTypeLabelled
    ? Record<keyof FieldGroupToFieldLabelKeysMap[TType], string>
    : undefined
  value: FieldGroupValue<TType>
}

export type FieldGroupValue<TType extends FieldGroupType = FieldGroupType> =
  TType extends 'layout_description'
    ? string
    : TType extends 'multiple_choice' | 'checkbox'
      ? string[]
      : null

export interface UnsavedField {
  name: string
  field_type: Api.TabObjectFieldType
  required: boolean
  position: number
  values?: string
  metadata: {
    fieldSetId: string
    description?: {
      enabled: boolean
      value: string
    }
    fieldTypeMetadata: Api.TabObjectFieldTypeMetadata
  }
}

export interface FieldsEditValue {
  fieldSet: Api.FieldSet
  fields: Array<Api.TabObjectField | UnsavedField>
}

// MARK: – FieldsEditContext

export interface FieldsEditInnerContextValue {
  fieldGroups: FieldGroup[]
  setFieldGroups: React.Dispatch<React.SetStateAction<FieldGroup[]>>
}

export const FieldsEditInnerContext = React.createContext(
  {} as FieldsEditInnerContextValue,
)

// MARK: – FieldsEdit

export interface FieldsEditProps {
  initialFieldSets?: Api.FieldSet[]
  initialFields?: Api.TabObjectField[]
  onChange: (value: FieldsEditValue[]) => void
  onInit?: (value: FieldsEditValue[]) => void
  children: React.ReactNode
}

export const FieldsEdit = ({
  initialFieldSets = [],
  initialFields = [],
  onChange,
  onInit,
  children,
}: FieldsEditProps) => {
  const initialFieldsRef = useRef(initialFields)
  const {fields: transientFields, fieldSets: transientFieldSets} = useMemo(
    () => normalizeFieldSetLessFields({fields: initialFieldsRef.current}),
    [],
  )
  const [fieldGroups, setFieldGroups] = useState<FieldGroup[]>(
    parseFieldGroups({
      fieldSets: [...initialFieldSets, ...transientFieldSets],
      fields: [...initialFields, ...transientFields],
    }),
  )
  const onChangeRef = useLiveRef(onChange)
  const onInitRef = useLiveRef(onInit)

  const newFieldSetsAndFields = useMemo(
    () =>
      unparseFieldGroups({
        fieldGroups,
        fields: [...initialFieldsRef.current, ...transientFields],
      }),
    [fieldGroups, transientFields],
  )

  useUpdateEffect(() => {
    onChangeRef.current(newFieldSetsAndFields)
  }, [newFieldSetsAndFields])

  useEffect(() => {
    onInitRef.current?.(newFieldSetsAndFields)
  }, [newFieldSetsAndFields])

  const contextValue: FieldsEditInnerContextValue = useMemo(
    () => ({
      fieldGroups,
      setFieldGroups,
    }),
    [fieldGroups],
  )

  return (
    <WebUI.DragAndDrop
      touchEnabled
      collisionDetection={WebUI.closestCenter}
      modifiers={[WebUI.followMouseModifier]}
      onDragStart={(event) => {
        if (event.active.data.current) {
          if ('id' in event.active.data.current) {
            setFieldGroups((prevFieldGroups) => [
              ...prevFieldGroups,
              event.active.data.current as FieldGroup,
            ])
          }
        }
      }}
      onDragEnd={({active, over}) => {
        if (!over) {
          return
        }

        const oldOrder = (over.data.current?.sortable.items ??
          []) as WebUI.UniqueIdentifier[]
        const newOrder = WebUI.arrayMoveByValue(oldOrder, active.id, over.id)

        if (active.data.current) {
          setFieldGroups((prevFieldGroups) =>
            newOrder
              .map((oId) =>
                [
                  ...prevFieldGroups,
                  // biome-ignore lint/style/noNonNullAssertion:
                  active.data.current!,
                ].find((fg) => fg.id === oId),
              )
              .filter((fg): fg is FieldGroup => !!fg),
          )
        } else {
          setFieldGroups((prevFieldGroups) =>
            newOrder
              .map((oId) => prevFieldGroups.find((fg) => fg.id === oId))
              .filter((fg) => !!fg),
          )
        }
      }}
    >
      <FieldsEditInnerContext.Provider value={contextValue}>
        {children}
      </FieldsEditInnerContext.Provider>
    </WebUI.DragAndDrop>
  )
}

// MARK: – FieldSet and Field to FieldGroup transformations

export const normalizeFieldSetLessFields = ({
  fields,
}: {
  fields: Api.TabObjectField[]
}) => {
  const fieldsWithoutFieldSet = fields
    .filter(
      (f) =>
        f.metadata.fieldSetId == null ||
        (f.metadata.description?.enabled && f.metadata.description.value),
    )
    .flatMap<Api.TabObjectField | UnsavedField>((f) => {
      if (f.metadata.description?.enabled && f.metadata.description.value) {
        return [
          {
            ...f,
            metadata: {
              ...f.metadata,
              description: {
                enabled: false,
                value: '',
              },
            },
          },
          {
            created_at: f.created_at,
            updated_at: f.updated_at,
            deleted_at: f.deleted_at,
            tab_object_id: f.tab_object_id,
            name: 'layout_description',
            required: false,
            position: f.position,
            field_type: 'layout_description' as const,
            values: f.metadata.description.value,
            metadata: {
              ...f.metadata,
              description: {
                enabled: false,
                value: '',
              },
            },
          } as UnsavedField,
        ]
      }

      return [f]
    })

  const transientFieldSets: Api.FieldSet[] = fieldsWithoutFieldSet.map(() => ({
    uuid: Util.makeShortId(),
    label: '',
    type: 'general',
  }))
  const transientFields = fieldsWithoutFieldSet.map(
    (f, idx) =>
      ({
        ...f,
        metadata: {
          ...f.metadata,

          fieldSetId: transientFieldSets[idx]?.uuid,
        },
      }) as Api.TabObjectField | UnsavedField,
  )

  return {
    fields: transientFields,
    fieldSets: transientFieldSets,
  }
}

export const parseFieldGroups = ({
  fields,
  fieldSets,
}: {
  fields: Array<Api.TabObjectField | UnsavedField>
  fieldSets: Api.FieldSet[]
}): FieldGroup[] =>
  fieldSets
    .map((fs) => parseFieldGroup({fieldSet: fs, fields}))
    .filter((fg) => !!fg)

export const parseFieldGroup = ({
  fieldSet,
  fields,
}: {
  fieldSet: Api.FieldSet
  fields: Array<Api.TabObjectField | UnsavedField>
}): FieldGroup | null => {
  const fieldSetFields = fields.filter(
    (f) => f.metadata.fieldSetId === fieldSet.uuid,
  )

  const common = {
    uuid: fieldSet.uuid,
    id: fieldSet.uuid,
    required: fieldSetFields.some((f) => f.required),
    value: null,
  }

  const fieldLabels =
    fieldSet.type === 'general'
      ? {}
      : Util.objectFromObject(
          defaultFieldGroupFieldLabels[fieldSet.type],
          (fieldId, defaultLabel) => {
            const fieldLabel = fieldSetFields.find(
              (f) => f.metadata.fieldTypeMetadata?.fieldIdentifier === fieldId,
            )?.name
            return fieldLabel || defaultLabel
          },
        )

  switch (fieldSet.type) {
    case 'address':
    case 'full_name':
      return {
        ...common,
        type: fieldSet.type,
        fieldLabels,
        placeholders: defaultFieldGroupFieldLabels[fieldSet.type],
      }
    case 'general': {
      const type = parseFieldGroupType({fieldSet, fields})

      if (!type) {
        return null
      }

      return {
        ...common,
        type,
        fieldLabels:
          type === 'layout_description' || type === 'layout_divider'
            ? {}
            : Util.objectFromObject(
                defaultFieldGroupFieldLabels[type],
                (_fieldId, defaultLabel) =>
                  fieldSetFields[0]?.name ?? defaultLabel,
              ),
        value:
          // @ts-expect-error
          {
            layout_description: fieldSetFields[0]?.values ?? '',
            multiple_choice: fieldSetFields[0]?.values?.split('|||') ?? [],
            checkbox: fieldSetFields[0]?.values?.split('|||') ?? [],
          }[type] ?? null,
        placeholders:
          type === 'layout_description' || type === 'layout_divider'
            ? undefined
            : defaultFieldGroupFieldLabels[type],
      }
    }
  }
}

export const parseFieldGroupType = ({
  fieldSet,
  fields,
}: {
  fieldSet: Api.FieldSet
  fields: Array<Api.TabObjectField | UnsavedField>
}): FieldGroupType | null => {
  if (fieldSet.type !== 'general') {
    return fieldSet.type
  }

  const [anyField, secondField] = fields.filter(
    (f) => f.metadata.fieldSetId === fieldSet.uuid,
  )
  if (!anyField || !!secondField || anyField.field_type === 'image') {
    return null
  }

  return anyField.field_type
}

// MARK: – FieldGroup to FieldSet and Field transformations

export const unparseFieldGroups = ({
  fieldGroups,
  fields,
}: {
  fieldGroups: FieldGroup[]
  fields: Array<Api.TabObjectField | UnsavedField>
}): FieldsEditValue[] =>
  fieldGroups.map((fg) => unparseFieldGroup({fieldGroup: fg, fields}))

export const unparseFieldGroup = ({
  fieldGroup,
  fields,
}: {
  fieldGroup: FieldGroup
  fields: Array<Api.TabObjectField | UnsavedField>
}): FieldsEditValue => {
  switch (fieldGroup.type) {
    case 'address':
    case 'full_name':
      return {
        fieldSet: {
          uuid: fieldGroup.id,
          label: '',
          type: fieldGroup.type,
        },
        fields: unparseFields({fieldGroup, fields}),
      }
    default:
      return {
        fieldSet: {
          uuid: fieldGroup.id,
          label: '',
          type: 'general',
        },
        fields: unparseFields({fieldGroup, fields}),
      }
  }
}

export const unparseFields = ({
  fieldGroup,
  fields,
}: {
  fieldGroup: FieldGroup
  fields: Array<Api.TabObjectField | UnsavedField>
}): Array<Api.TabObjectField | UnsavedField> => {
  const fieldSetFields = fields.filter(
    (f) => f.metadata.fieldSetId === fieldGroup.id,
  )

  switch (fieldGroup.type) {
    case 'address':
    case 'full_name':
      if (fieldSetFields.length > 0) {
        return fieldSetFields
          .map((f) => {
            const name = (fieldGroup.fieldLabels as any)[
              f.metadata.fieldTypeMetadata?.fieldIdentifier ?? ''
            ]
            return name
              ? {
                  ...f,
                  name,
                  required:
                    fieldGroup.required &&
                    f.metadata.fieldTypeMetadata?.fieldIdentifier !== 'line2',
                }
              : null
          })
          .filter((f): f is Api.TabObjectField => !!f)
      }

      // biome-ignore lint/style/noNonNullAssertion:
      return Object.keys(fieldGroup.fieldLabels!).map((fieldIdentifier) => ({
        name: (fieldGroup.fieldLabels as any)[fieldIdentifier] as string,
        field_type: 'text',
        // biome-ignore lint/style/noNonNullAssertion:
        required: fieldIdentifier === 'line2' ? false : fieldGroup.required!,
        position: 0,
        metadata: {
          fieldSetId: fieldGroup.id,
          description: {enabled: false, value: ''},
          fieldTypeMetadata: {
            fieldIdentifier: fieldIdentifier as Api.TabObjectFieldIdentifier,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      }))

    default: {
      const [existingField] = fieldSetFields
      if (existingField) {
        return [
          {
            ...existingField,
            name:
              fieldGroup.fieldLabels && 'value' in fieldGroup.fieldLabels
                ? fieldGroup.fieldLabels.value
                : existingField.name,
            required: fieldGroup.required ?? false,
            values: Array.isArray(fieldGroup.value)
              ? (fieldGroup.value.join('|||') ?? '')
              : (fieldGroup.value ?? ''),
          },
        ]
      }
      return [
        {
          name:
            fieldGroup.fieldLabels && 'value' in fieldGroup.fieldLabels
              ? fieldGroup.fieldLabels.value
              : fieldGroup.type,
          field_type: fieldGroup.type as any,
          required: fieldGroup.required ?? false,
          values: Array.isArray(fieldGroup.value)
            ? (fieldGroup.value.join('|||') ?? '')
            : (fieldGroup.value ?? ''),
          position: 0,
          metadata: {
            fieldSetId: fieldGroup.id,
            description: {enabled: false, value: ''},
            fieldTypeMetadata: {
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        },
      ]
    }
  }
}

// MARK: – Helpers

// typescript doesn't support control flow analysis
export function makeFieldGroup<TType extends FieldGroupType>(
  fieldGroupType: TType,
  overrides?: Partial<FieldGroup<TType>>,
): FieldGroup<TType> {
  switch (fieldGroupType) {
    case 'layout_description':
    case 'layout_divider': {
      const typedFieldGroupType = fieldGroupType as FieldGroupTypeLabelless
      return {
        uuid: null,
        id: Util.makeShortId(),
        type: typedFieldGroupType,
        value: fieldGroupType === 'layout_description' ? '' : null,
        ...overrides,
      } as any
    }
    default:
      return {
        uuid: null,
        id: Util.makeShortId(),
        type: fieldGroupType,
        required: false,
        fieldLabels: (defaultFieldGroupFieldLabels as any)[fieldGroupType],
        placeholders: (defaultFieldGroupFieldLabels as any)[fieldGroupType],
        value: ['multiple_choice', 'checkbox'].includes(fieldGroupType)
          ? []
          : null,
        ...overrides,
      } as any
  }
}

export const defaultFieldGroupFieldLabels: {
  [TType in FieldGroupTypeLabelled]: Record<
    keyof FieldGroupToFieldLabelKeysMap[TType],
    string
  >
} = {
  full_name: {
    first_name: 'First Name',
    last_name: 'Last Name',
  },
  email: {
    value: 'Email Address',
  },
  phone: {
    value: 'Phone',
  },
  text: {
    value: 'Ask a question',
  },
  text_multiline: {
    value: 'Ask a question',
  },
  multiple_choice: {
    value: 'Ask a question',
  },
  checkbox: {
    value: 'Ask a question',
  },
  date: {
    value: 'Date',
  },
  time: {
    value: 'Time',
  },
  signature: {
    value: 'Signature',
  },
  initials: {
    value: 'Initials',
  },
  file: {
    value: 'Upload File',
  },
  address: {
    line1: 'Street Address',
    line2: 'Street Address, Line 2',
    state: 'State/Province',
    city: 'City',
    zip: 'Zip/Postal Code',
  },
}
