import React, {useEffect, useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {SearchForm} from 'src/components'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {TabTemplatesGrid} from 'src/components/TabTemplatesGrid'
import {templateCategoriesPickerOrder} from 'src/views/templates/TemplatesLayout'

export const TemplatesSuggestionsModal = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.ModalProps
>(({className, ...restProps}, forwardedRef) => {
  const media = WebUI.useMedia()
  const [orderBy, setOrderBy] = useState<
    Api.TabTemplatesOrderBy | 'recommended'
  >('recommended')
  const [templateFilter, setTemplateFilter] = useState({
    type: 'category',
    value: 'Staff Picks',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const {data: user} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user,
  })
  const templatesQuery = api.templates.list.useQuery(
    {
      queryParams: {
        orderBy: {
          key: orderBy === 'recommended' ? 'recentlyAdded' : orderBy,
        },
      },
    },
    {
      select: (templates) =>
        orderBy === 'recommended'
          ? Util.sort(templates).asc((template) => {
              if (
                !templateFilter.value ||
                (templateFilter.type !== 'category' &&
                  templateFilter.type !== 'groupType')
              ) {
                return 0
              }

              const order = (
                template.options.template[
                  templateFilter.type === 'category' ? 'categories' : 'groups'
                ] as Array<Api.TabTemplateCategory | Api.TabTemplateGroup>
              ).find((catOrGr) =>
                (templateFilter.value === 'For You' && user?.customer_category
                  ? customerCategoryToTemplateGroupTypeMap[
                      user.customer_category
                    ]
                  : [templateFilter.value]
                ).includes(catOrGr.type),
              )?.order

              return order ?? Number.MAX_SAFE_INTEGER
            })
          : templates,
    },
  )

  const templates = templatesQuery.data ?? []

  const recommendedTemplates = templates.filter((template) =>
    template.options.template.groups.some((g) =>
      user?.customer_category
        ? Boolean(
            customerCategoryToTemplateGroupTypeMap[
              user.customer_category
            ]?.includes(g.type),
          )
        : false,
    ),
  )

  const hotCategories = ['Staff Picks', 'Seasonal Collections', 'All']

  // biome-ignore lint/correctness/useExhaustiveDependencies: prevents order changes
  const categories = useMemo(
    () =>
      Util.sort(
        Util.unique(
          templates
            .flatMap((template) =>
              template.options.template.categories.map((c) => c.type),
            )
            .filter((c) => !hotCategories.includes(c)),
        ),
      ).asc((cat) => {
        const index = templateCategoriesPickerOrder.indexOf(cat)
        return index > -1 ? index : Number.MAX_SAFE_INTEGER
      }),
    [templatesQuery.isSuccess],
  )

  const hasRecommendedTemplates = recommendedTemplates.length > 0
  useEffect(() => {
    if (hasRecommendedTemplates) {
      setTemplateFilter({type: 'groupType', value: 'For You'})
    }
  }, [hasRecommendedTemplates])

  const filteredTemplates = (
    templateFilter.value === 'For You' ? recommendedTemplates : templates
  )
    .filter(
      Util.fuzzyFilterIterator(searchTerm, {
        iterator: (template) => template.name,
      }),
    )
    .filter(
      (template) =>
        templateFilter.value === 'All' ||
        templateFilter.value === 'For You' ||
        (templateFilter.type === 'groupType'
          ? template.options.template.groups.some(
              (g) => g.type === templateFilter.value,
            )
          : template.options.template.categories.some(
              (c) => c.type === templateFilter.value,
            )),
    )

  const sideNav = (
    <div className="scrollbar-hide flex max-w-[320px] shrink-0 grow basis-auto flex-col gap-6 overflow-y-auto bg-natural-80 px-10 py-16">
      <WebUI.Heading className="font-bold uppercase" as="h4">
        TEMPLATES
      </WebUI.Heading>
      <SearchForm
        containerClassName="w-full"
        className={
          'w-full [&_.Input]:bg-natural-100 [&_.Input]:px-3 [&_.Search-cancelButton]:right-6'
        }
        placeholder="Search by keyword"
        values={{term: searchTerm}}
        onTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
      />
      <div className="flex flex-col items-start gap-4">
        {hasRecommendedTemplates && (
          <SideNavButton
            selected={templateFilter.value === 'For You'}
            onClick={() =>
              setTemplateFilter({type: 'groupType', value: 'For You'})
            }
          >
            For You
          </SideNavButton>
        )}
        {hotCategories.map((category, idx) => (
          <SideNavButton
            key={idx}
            selected={
              templateFilter.type === 'category' &&
              templateFilter.value === category
            }
            onClick={() =>
              setTemplateFilter({type: 'category', value: category})
            }
          >
            {category}
          </SideNavButton>
        ))}
      </div>
      <WebUI.Heading className="font-bold uppercase" as="h4">
        Use Cases
      </WebUI.Heading>
      <div className="flex flex-col items-start gap-4">
        {categories.map((category, idx) => (
          <SideNavButton
            key={idx}
            onClick={() =>
              setTemplateFilter({type: 'category', value: category})
            }
            selected={
              templateFilter.type === 'category' &&
              category === templateFilter.value
            }
          >
            {category}
          </SideNavButton>
        ))}
      </div>
    </div>
  )

  return (
    <WebUI.Modal
      aria-label="Template suggestions"
      ref={forwardedRef}
      className={WebUI.cn(
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:w-full [&_>_.ModalContentView]:max-w-screen-xl sm:[&_>_.ModalContentView]:max-h-[740px] sm:[&_>_.ModalContentView]:rounded-extended',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="grow overflow-hidden">
        {media.lg ? sideNav : <WebUI.Drawer>{sideNav}</WebUI.Drawer>}
        <WebUI.VStack className="grow gap-5 overflow-y-auto px-10 py-16">
          <WebUI.VStack>
            <WebUI.Heading className="font-accentAlt">
              {templateFilter.value}
            </WebUI.Heading>
            <WebUI.Text className="font-light">
              {templateFilter.value === 'For You'
                ? 'We’ve hand-selected templates for you based on your profile.'
                : 'We’ve seen them all and these are some of our favorites.'}
            </WebUI.Text>
          </WebUI.VStack>
          <WebUI.HStack className="justify-between">
            {templateFilter.type === 'category' &&
            templateFilter.value === 'All' ? (
              <div />
            ) : (
              <WebUI.Button
                variant="link"
                className="text-ds-sm"
                onClick={() =>
                  setTemplateFilter({type: 'category', value: 'All'})
                }
              >
                View All Templates
              </WebUI.Button>
            )}
            <WebUI.DropdownSelect<Api.TabTemplatesOrderBy | 'recommended'>
              className="[&_>_.Select-select]:px-4 [&_>_.Select-select_>_.DropdownSelectButton-content]:text-ds-sm"
              value={orderBy}
              onValueChange={(newOrderBy) => {
                if (newOrderBy) {
                  setOrderBy(newOrderBy)
                }
              }}
            >
              <WebUI.DropdownSelectOption value="recommended">
                Recommended
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="recentlyAdded">
                Recently added
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="mostUsed">
                Most used
              </WebUI.DropdownSelectOption>
            </WebUI.DropdownSelect>
          </WebUI.HStack>
          <TabTemplatesGrid
            className="mt-5 sm:grid-cols-[repeat(auto-fill,minmax(246px,1fr))] [&_>_.TemplateCard_>_.TemplateCard-bottom]:h-[120px]"
            loading={templatesQuery.isLoading}
            templates={filteredTemplates}
            templateCardWidth={246}
            templateCardImageHeight={150}
            templateCardButtonSize="default"
          />
        </WebUI.VStack>
      </WebUI.HStack>

      <WebUI.ModalCloseButton />
    </WebUI.Modal>
  )
})

// MARK: - SideNavButton

interface SideNavButtonProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  selected?: boolean
}

const SideNavButton: React.FC<SideNavButtonProps> = ({
  className,
  variant = 'text',
  selected,
  ...restProps
}) => (
  <WebUI.Button
    aria-selected={selected}
    variant={variant}
    className={WebUI.cn(
      'aria-selected:text-orange-50 [&_>_.Button-iconBefore]:invisible [&_>_.Button-iconBefore]:aria-selected:visible',
      className,
    )}
    iconBefore={
      <WebUI.PhosphorIcon height={20} color="#f36d36" icon="arrow-right-bold" />
    }
    {...restProps}
  />
)

// MARK: – Helpers

const customerCategoryToTemplateGroupTypeMap: Record<
  Api.UserCustomerCategory,
  Api.TabTemplateGroupType[]
> = {
  BUSINESS: ['Small Business', 'Workplace', 'HOAs'],
  CLUB: ['Clubs', 'Greek Life'],
  'FINE ARTS': ['Fine Arts', 'Bands & Choir'],
  FRIENDS: ['Friends & Family', 'Reunions', 'Workplace'],
  'NON-PROFIT': ['Nonprofits'],
  RELIGIOUS: ['Religious'],
  SCHOOL: ['Schools & PTAs'],
  SCOUT: ['Scouts'],
  SPORT: ['Sports', 'Cheer'],
  NONE_OF_THE_ABOVE: [],
  PERSONAL: [],
  DISABLED: [],
  TEST: [],
  MANAGER: [],
  UNKNOWN: [],
}
