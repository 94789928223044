import * as Yup from 'yup'
import {NumberParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useState} from 'react'
import {
  api,
  useAddContactsMutation,
  useAddContactsToContactListMutation,
  useDeleteContactMutation,
  useRemoveContactsFromContactListMutation,
  useUpdateContactMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import ContactsIcon from 'src/images/ContactsIcon.svg'

import {ContactTableViewToolbar} from './ContactTableViewToolbar'
import {RecipientsModal} from '../../components/RecipientsModal/RecipientsModal'

export interface ContactTableViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onCreateList: () => Promise<Api.ContactList>
}

export const ContactTableView = ({
  onCreateList,
  ...restProps
}: ContactTableViewProps) => {
  const [contactListId] = useQueryParam('contactListId', NumberParam)
  const [nameOrEmail, setNameOrEmail] = useState('')
  const [query, setQuery] = useState({
    page: 0,
    search: '',
    sort: 'created_at' as any,
  })
  const contactListNameQuery = api.contacts.contactListDetail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        contactListId: contactListId!,
      },
    },
    {
      enabled: contactListId != null,
      select: (contactList) => contactList.name,
    },
  )
  const contactsQuery = api.contacts.list.useQuery(
    {
      queryParams: {
        ...query,
        name_or_email: nameOrEmail,
        contact_list_id: contactListId,
        perPage: 99999,
        page: query.page + 1,
      },
    },
    {
      placeholderData: (prevData) => prevData,
    },
  )
  const updateContactMutation = useUpdateContactMutation()
  const deleteContactMutation = useDeleteContactMutation()
  const removeContactsFromContactListMutation =
    useRemoveContactsFromContactListMutation()

  const updateContactMutate = updateContactMutation.mutate
  const deleteContactMutate = deleteContactMutation.mutate
  const removeContactsFromContactListMutate =
    removeContactsFromContactListMutation.mutate

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.Contact>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((c) => c.name || '?', {
        id: 'name',
        cell: ({cell, row: {original: contact}}) => (
          <WebUI.InlineEditInput<typeof WebUI.Input>
            className="[&_.Input[class]]:max-w-[120px]"
            inputOffset={[-8, -8]}
            defaultValue={cell.getValue()}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                ;(event.target as any).blur()
              }
            }}
            onBlur={(event) =>
              updateContactMutate({
                pathParams: {
                  contactId: contact.id,
                },
                body: {name: event.target.value},
              })
            }
          />
        ),
      }),
      columnHelper.accessor((c) => c.email, {
        id: 'email',
        cell: ({cell, row: {original: contact}}) => (
          <WebUI.InlineEditInput<typeof WebUI.Input>
            className="[&_.Input[class]]:max-w-[120px] [&_.Text]:font-light"
            inputOffset={[-8, -8]}
            defaultValue={cell.getValue()}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                ;(event.target as any).blur()
              }
            }}
            onBlur={(event) => {
              if (Yup.string().email().isValidSync(event.target.value)) {
                updateContactMutate({
                  pathParams: {
                    contactId: contact.id,
                  },
                  body: {email: event.target.value},
                })
              }
            }}
          />
        ),
      }),
      columnHelper.display({
        id: 'delete',
        maxSize: 36,
        minSize: 36,
        cell: ({row: {original: contact}}) => (
          <WebUI.IconButton
            data-columnid="delete"
            className="invisible opacity-0 transition-opacity duration-100 ease-in-out"
            size="default_alt"
            onClick={(event) => {
              event.stopPropagation()

              if (contactListId == null) {
                deleteContactMutate({
                  pathParams: {
                    contactId: contact.id,
                  },
                })
              } else {
                removeContactsFromContactListMutate({
                  pathParams: {
                    contactListId,
                  },
                  body: {contacts: [contact.id]},
                })
              }
            }}
          >
            <WebUI.PhosphorIcon icon="trash" />
          </WebUI.IconButton>
        ),
      }),
    ],
    [
      columnHelper,
      contactListId,
      deleteContactMutate,
      removeContactsFromContactListMutate,
      updateContactMutate,
    ],
  )

  const paginationState = useMemo(
    () => ({
      pageSize: 20,
      pageIndex: query.page,
    }),
    [query.page],
  )
  const pageCount = contactsQuery.data
    ? Math.ceil(contactsQuery.data.pagination.total / 20)
    : 0

  return (
    <WebUI.VStack {...restProps}>
      <WebUI.VStack className="p4 shrink-0 grow-0 basis-[84px] sm:p-8">
        <WebUI.VStack
          className={
            'items-stretch justify-center gap-3 py-4 sm:flex-row sm:items-center sm:justify-between sm:py-0'
          }
        >
          <WebUI.Heading className="leading-compact" as="h2">
            {contactListNameQuery.isFetching ? (
              <WebUI.Skeleton width={200} />
            ) : (
              (contactListNameQuery.data ?? 'All Contacts')
            )}
          </WebUI.Heading>
          {contactsQuery.data && contactsQuery.data.pagination.total > 0 && (
            <AddContactsModal
              disclosure={
                <WebUI.DialogDisclosure className="w-[180px]" variant="primary">
                  Add Contacts
                </WebUI.DialogDisclosure>
              }
            />
          )}
        </WebUI.VStack>
      </WebUI.VStack>
      {nameOrEmail !== '' && (
        <WebUI.HStack className="gap-4 px-8 pb-4">
          <div className="text-ds-sm">Filters:</div>
          <WebUI.HStack className="items-center gap-1 rounded-[12px] bg-teal-80 px-1 py-0_5 text-ds-sm">
            <WebUI.IconButton
              className="rounded-[12px] bg-natural-100"
              size="default_alt"
              onClick={() => setNameOrEmail('')}
            >
              <WebUI.PhosphorIcon icon="x" />
            </WebUI.IconButton>
            <div>{nameOrEmail}</div>
          </WebUI.HStack>
        </WebUI.HStack>
      )}
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="flex-auto">
        {contactsQuery.data?.pagination.total === 0 ? (
          <WebUI.VStack className="mt-[calc(theme(spacing.10)*2)] grow items-center">
            <ContactTableViewEmptyStateView
              filtersApplied={nameOrEmail !== ''}
            />
          </WebUI.VStack>
        ) : (
          <WebUI.TableView
            className={`[&_.TableViewCell]:h-9 [&_.TableViewCell]:py-2 [&_.TableViewRow:hover]:bg-gray-150 [&_.TableViewRow:hover_*[data-columnid="delete"]]:visible [&_.TableViewRow:hover_*[data-columnid="delete"]]:opacity-100 [&_.TableViewRow]:h-[50px] [&_.TableViewRow]:px-6 [&_.TableViewRow]:transition-colors [&_.TableViewRow]:duration-100 [&_.TableViewRow]:ease-in-out`}
            state={{pagination: paginationState}}
            loading={contactsQuery.isFetching}
            enableRowSelection
            selectAllVisible={false}
            pageCount={pageCount}
            manualPagination
            onPaginationChange={(updater) => {
              const newPagination =
                typeof updater === 'function'
                  ? updater(paginationState)
                  : updater

              setQuery((prevQuery) => ({
                ...prevQuery,
                page: newPagination.pageIndex,
              }))
            }}
            data={contactsQuery.data?.data ?? []}
            columns={columns}
          >
            {(table) => {
              const totalSize = contactsQuery.data?.pagination.total ?? 0
              return (
                <>
                  <WebUI.VStack className="-order-1">
                    <ContactTableViewToolbar
                      className="h-[50px] xs:h-auto overflow-x-auto px-8"
                      table={table}
                      onCreateList={onCreateList}
                      sortBy={query.sort}
                      onNameOrEmailChange={(newNameOrEmail) =>
                        setNameOrEmail(newNameOrEmail)
                      }
                      onSortByChange={(newSortBy) =>
                        setQuery((prevQuery) => ({
                          ...prevQuery,
                          sort: newSortBy,
                        }))
                      }
                    />
                    <WebUI.Separator variant="primary" />
                  </WebUI.VStack>
                  {pageCount > 1 && (
                    <WebUI.HStack className="items-center justify-between py-4">
                      {totalSize > 0 && (
                        <WebUI.Text className="px-2 font-light text-ds-sm">
                          {Util.pluralize('result', totalSize, true)}
                        </WebUI.Text>
                      )}
                      <WebUI.HStack className="px-8">
                        <WebUI.TablePaginator />
                      </WebUI.HStack>
                    </WebUI.HStack>
                  )}
                </>
              )
            }}
          </WebUI.TableView>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – ContactTableViewEmptyStateView

interface ContactTableViewEmptyStateViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filtersApplied?: boolean
}

const ContactTableViewEmptyStateView = ({
  filtersApplied,
  className,
  ...restProps
}: ContactTableViewEmptyStateViewProps) => {
  const [contactListId] = useQueryParam('contactListId', NumberParam)

  if (contactListId) {
    return (
      <WebUI.VStack className={WebUI.cn('gap-5', className)} {...restProps}>
        <WebUI.Text className="text-center font-bold text-ds-md">
          {filtersApplied
            ? 'There are no matching records based on your filter criteria'
            : 'You haven’t added any contacts to this list'}
        </WebUI.Text>
        <AddContactsModal
          disclosure={
            <WebUI.DialogDisclosure variant="primary">
              Add Contacts
            </WebUI.DialogDisclosure>
          }
        />
      </WebUI.VStack>
    )
  }

  if (filtersApplied) {
    return (
      <WebUI.VStack className={WebUI.cn('gap-5', className)} {...restProps}>
        <WebUI.Text className="text-center font-bold text-ds-md">
          There are no matching records based on your filter criteria
        </WebUI.Text>
        <AddContactsModal
          disclosure={
            <WebUI.DialogDisclosure variant="primary">
              Add Contacts
            </WebUI.DialogDisclosure>
          }
        />
      </WebUI.VStack>
    )
  }

  return (
    <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
      <img src={ContactsIcon} alt="" className="w-[232px]" />
      <WebUI.Text className="text-center font-bold text-ds-md">
        Your address book is empty
        <br />
        <span className="font-normal text-gray400">
          Contacts will appear here
        </span>
      </WebUI.Text>
      <AddContactsModal
        disclosure={
          <WebUI.DialogDisclosure variant="primary">
            Add Contacts
          </WebUI.DialogDisclosure>
        }
      />
    </WebUI.VStack>
  )
}

// MARK: – AddContactsModal

const AddContactsModal = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.ModalProps
>((props, forwardedRef) => {
  const [contactListId] = useQueryParam('contactListId', NumberParam)
  const addContactsMutation = useAddContactsMutation()
  const addContactsToContactListMutation = useAddContactsToContactListMutation()

  return (
    <RecipientsModal
      ref={forwardedRef}
      aria-label="Add contacts"
      isContactsModal
      initialRecipients={[]}
      onRecipientsSave={async (newRecipients) => {
        const res = await addContactsMutation.mutateAsync({
          body: {
            contacts: newRecipients.map((r) => ({
              name: r.name,
              email: r.email,
            })),
          },
        })
        if (contactListId) {
          await addContactsToContactListMutation.mutateAsync({
            pathParams: {
              contactListId,
            },
            body: {
              contacts: [
                ...res.created.map((c) => c.id),
                ...res.updated.map((c) => c.id),
              ],
            },
          })
        }
      }}
      {...props}
    />
  )
})
