// @ts-ignore

import {z} from '@cheddarup/util'

import {itemTypeSchema, tabItemSchema} from './tab-items'
import {tabObjectFieldSchema} from './fields'
import {tabFormSchema} from './tab-forms'
import {timeSlotSchema} from './time-slots'
import {signUpSpotSchema} from './tab-signup-spots'
import {signUpSchema} from './tab-signups'
import {basePaymentItemSchema} from './base-payment-items'

export const paymentItemRefundDataSchema = z.object({
  total_refunded_cents: z.number(),
  quantity_refunded: z.number(),
  net_amount_cents: z.number(),
})

export const ticketStatusSchema = z.enum(['unused', 'redeemed', 'revoked'])
export const redeemedMethodSchema = z.enum(['manual', 'qr'])

export const ticketPaymentRedeembedBy = z.object({
  name: z.string(),
  email: z.string(),
})

export const tabItemPaymentItemDetailSchema = z.object({
  itemType: itemTypeSchema.optional(),
  ticketStatus: ticketStatusSchema.optional(),
  variant: z
    .object({
      amount: z.number(),
      description: z.string(),
      imageId: z.string().nullish(),
      optionValues: z.record(z.string()),
      sku: z.string(),
      uuid: z.string(),
    })
    .nullish(),
  organizerNote: z.string().optional(),
  redeemedBy: ticketPaymentRedeembedBy.optional(),
  updatedBy: z.object({
    date: z.number(), // seconds timestamp
    email: z.string(),
    name: z.string(),
  }),
  refund_data: paymentItemRefundDataSchema,
  redeemedMethod: redeemedMethodSchema.optional(),
})

export const tabItemPaymentSchema = basePaymentItemSchema.extend({
  ticket_number: z.string().nullish(),
  tab_item: tabItemSchema.extend({fields: tabObjectFieldSchema.array()}),
  detail: tabItemPaymentItemDetailSchema,
  refund_data: paymentItemRefundDataSchema,
  wallet_passes: z
    .object({
      android: z.string(),
      ios: z.string(),
    })
    .optional(),
})

export const tabFormPaymentSchema = basePaymentItemSchema.extend({
  tab_form: tabFormSchema.extend({fields: tabObjectFieldSchema.array()}),
})

export const tabSignupPaymentSchema = basePaymentItemSchema.extend({
  'cancellable?': z.boolean(),
  time_slot: timeSlotSchema
    .omit({
      payment_items: true,
    })
    .extend({
      spot: signUpSpotSchema.extend({
        signup: signUpSchema.extend({
          fields: tabObjectFieldSchema.array(),
        }),
      }),
    }),
})

export const paymentItemSchema = tabItemPaymentSchema
  .merge(tabFormPaymentSchema)
  .merge(tabSignupPaymentSchema)

declare global {
  namespace Api {
    // @ts-ignore
    type PaymentItem = z.infer<typeof paymentItemSchema>
    // @ts-ignore
    type PaymentItemRefundData = z.infer<typeof paymentItemRefundDataSchema>
    // @ts-ignore
    type TabItemPayment = z.infer<typeof tabItemPaymentSchema>
    // @ts-ignore
    type TabFormPayment = z.infer<typeof tabFormPaymentSchema>
    // @ts-ignore
    type TabSignupPayment = z.infer<typeof tabSignupPaymentSchema>
    // @ts-ignore
    type TicketStatus = z.infer<typeof ticketStatusSchema>
    // @ts-ignore
    type RedeemedMethod = z.infer<typeof redeemedMethodSchema>
    // @ts-ignore
    type TicketPaymentRedeembedBy = z.infer<typeof ticketPaymentRedeembedBy>
    // @ts-ignore
    type ItemType = z.infer<typeof itemTypeSchema>
    // @ts-ignore
    type TabItemPaymentItemDetail = z.infer<
      typeof tabItemPaymentItemDetailSchema
    >
  }
}
