import * as Util from '@cheddarup/util'

export interface CartSignUp {
  signUp: Omit<Api.PublicTabSignup, 'visible_spots'>
  time_slots: Api.CheddarUpCartTimeSlot[]
}

const CartHelpers = {
  getRecordsCount: (cart: Api.Cart | undefined) =>
    cart ? cart.items.length + cart.forms.length + cart.time_slots.length : 0,
  getSignUps: (cart?: Api.Cart) => {
    const records = cart?.time_slots.reduce<Record<number, CartSignUp>>(
      (acc, timeSlot) => {
        const signUpId = timeSlot.time_slot.spot.signup.id
        const existingTimeSlots = acc[signUpId]?.time_slots ?? []

        return {
          ...acc,
          [signUpId]: {
            signUp: timeSlot.time_slot.spot.signup,
            time_slots: [...existingTimeSlots, timeSlot],
          },
        }
      },
      {},
    )

    return Object.values(records ?? {})
  },
  getFees: ({
    cart,
    paymentMethod,
  }: {
    cart?: Api.Cart
    paymentMethod: 'card' | 'echeck' | 'cash' | null
  }) => {
    if (!cart || !cart.fees) {
      return 0
    }

    switch (paymentMethod) {
      case 'card':
        return cart.fees.card
      case 'echeck':
        return cart.fees.echeck
      case 'cash':
        return cart.fees.cash
      default:
        return 0
    }
  },
  getId: (cart: Api.Cart) =>
    cart.uuid && cart.uuid.split('!!').length === 2 && cart.uuid.split('!!')[1],
  getShipping: ({cart}: {cart: Api.Cart}) =>
    cart?.shippingInfo.shippingMethod
      ? (cart.shipping[cart.shippingInfo.shippingMethod] ?? 0)
      : 0,
  getPayErrorBody: (err: any) => {
    let body: any
    if (err.param === 'exp_month' || err.param === 'exp_year') {
      body = 'Please double check the expiration date on your credit card'
    } else if (err.type === 'card_error' || err.param === 'bank_account') {
      body = err.message
    } else if (err.response?.data) {
      const responseBody = err.response.data
      const apiError = responseBody.errors
        ? responseBody.errors[0].error
        : responseBody.error
      const emailError = responseBody.errors?.email
      if (apiError === 'exceeded_available_quantity') {
        const details = responseBody.details
        const {available} = details
        let quantityError = 'it is sold out'
        if (available > 0) {
          quantityError = `there ${
            available === 1 ? 'is' : 'are'
          } only ${available} available for sale`
        }
        body = `Sorry, you requested ${details.requested} of ${details.tab_item_name}, but ${quantityError}.`
      } else if (apiError === 'items_sold_out') {
        body = 'Some of your items have been sold out'
      } else if (apiError === 'account_already_exists') {
        body =
          "It looks like you entered a bank that's already saved to your account. Try refreshing the page and selecting one of your saved payment methods."
      } else if (apiError === 'intent_total_changed') {
        body =
          'There was an error trying to process your payment, please try again.'
      } else if (apiError === 'payment_intent_authentication_failure') {
        body =
          'The provided payment method has failed authentication. Provide a new payment method to attempt to checkout again.'
      } else if (apiError === 'invalid_email') {
        body = 'You must enter a valid email address'
      } else if (apiError === 'payment_required') {
        body =
          'This collection requires that your total is at least $1.00. Please add an item to your cart then attempt checkout again.'
      } else if (emailError) {
        body = emailError
      } else if (apiError === 'name_and_email_missing_at_validation') {
        body = 'Did you fill out your name and email address properly?'
      } else if (apiError === 'required_field_missing') {
        body = `Please make sure you've filled out all required form fields on this collection, then try again.`
      } else if (
        apiError &&
        (apiError.includes('payment_method_options') ||
          apiError.includes('has a status of requires_capture'))
      ) {
        body =
          'A processing error occurred, please reload the page, double check your payment information, and try again.'
      } else {
        body = Util.startCase(responseBody.error)
      }
    } else if (err.code === 'ERR_NETWORK') {
      body =
        'A network error occurred, please reload the page and attempt your payment again.'
    } else if (err.message?.includes('processing error')) {
      body =
        'A processing error occurred, please reload the page, double check your payment information, and try again.'
    } else if (err.response || err.name === 'FetchError') {
      body =
        'An unexpected error occurred, please reload the page and attempt your payment again.'
    }

    body =
      body ||
      err.message ||
      'Please make sure your payment information is correct.'

    if (
      body.includes('param is missing or the value is empty: source') ||
      body.includes('param is missing or the value is empty: method')
    ) {
      body = 'Please make sure you select a valid payment method.'
    }

    return body
  },
  isRequiredFormFieldViewMissing: ({
    formViews,
    cart,
  }: {
    cart: Api.Cart
    formViews: Api.PublicTabForm[]
  }) => {
    const requiredFieldViewIds = formViews
      .flatMap((formView) => formView.fields)
      .filter(({required}) => required)
      .map(({id}) => id)
    const requiredCartFieldViewIds =
      cart?.forms.flatMap(({cart_field_views}) =>
        cart_field_views
          .filter(({required}) => required)
          .map(({item_field_id}) => item_field_id),
      ) ?? []

    return requiredFieldViewIds.some(
      (requiredFieldViewId) =>
        !requiredCartFieldViewIds.includes(requiredFieldViewId),
    )
  },
  trackEvent: (event: string, properties?: any) => {
    try {
      const valueJoined = Object.values(properties || {}).join(',')
      if (window.__insp) {
        const props = properties || {}
        props.eventName = event
        window.__insp.push(['tagSession', props])
      }

      if (window._mfq) {
        window._mfq.push(['setVariable', event, valueJoined])
      }

      if (window.clarity) {
        window.clarity('set', event, valueJoined)
        window.clarity('set', 'BRANCH', window.netlify_ab_branch)
      }
    } catch {
      // noop
    }
  },
  hasNonZeroAmountItems: (cart?: Api.Cart) =>
    !!cart && cart.items.some((i) => i.amount > 0),
  getTotal: ({
    cart,
    paymentMethod,
  }: {
    cart?: Api.Cart
    paymentMethod?: 'cash' | 'echeck' | 'card'
  }) =>
    cart
      ? cart.subtotal +
        (paymentMethod ? CartHelpers.getFees({cart, paymentMethod}) : 0) +
        CartHelpers.getShipping({cart}) +
        cart.totalTaxes
      : 0,
  isZeroCart: ({
    cart,
    paymentMethod,
  }: {
    cart?: Api.Cart
    paymentMethod?: 'cash' | 'echeck' | 'card'
  }) => CartHelpers.getTotal({cart, paymentMethod}) === 0,
  isPaymentRequired: ({
    cart,
    paymentMethod,
  }: {
    cart?: Api.Cart
    paymentMethod?: 'cash' | 'echeck' | 'card'
  }) =>
    !CartHelpers.isZeroCart({cart, paymentMethod}) ||
    Object.values(cart?.discounts ?? {}).some((d) => d.amount > 0),
}

export function extractPaymentUuidFromCartUuid(cartUuid: string) {
  return cartUuid.split('!!')[0]
}

export default CartHelpers
