import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {useNavigate, useParams} from 'react-router-dom'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {
  api,
  useDeleteCategoryMutation,
  useDeleteItemMutation,
  useSortCollectionObjectsMutation,
} from '@cheddarup/api-client'
import {QueryDataSectionList} from 'src/components/QueryDataList'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import {useUndoableMutation} from 'src/hooks/useUndoableMutation'
import TicketLogo from 'src/images/ticket-logo.svg'
import AddItemIcon from 'src/images/ItemIcon.svg'
import DonationLogo from 'src/images/Donation.svg'
import RecurringCalendarLogo from 'src/images/Recurring-Calendar.svg'
import AddItemLogo from 'src/images/DollarTag.svg'

import {CategoryFormPopover, ItemListToolbar} from './ItemListToolbar'
import {ItemListBulkActionsToolbar} from './ItemListBulkActionsToolbar'
import ItemRow from './ItemRow'
import {MoveTabObjectsToAnotherCollectionOrCategoryModal} from 'src/components'
import {ItemQuantityGroupsModal} from '../../components'

type ItemCategoryListSectionData = WebUI.SectionListData<
  Api.Category | {id: -1},
  Api.TabItem
>

export interface ItemCategoryListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onAddItem: (type?: Api.ItemType) => void
  onAddTicket: () => void
}

export const ItemCategoryList: React.FC<ItemCategoryListProps> = ({
  onAddItem,
  onAddTicket,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)
  const listRef = useRef<WebUI.SectionListInstance>(null)
  const [itemToMove, setItemToMove] = useState<Api.TabItem | null>(null)
  const collectionQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const {data: categories} = api.tabCategories.list.useSuspenseQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const itemsQuery = api.tabItems.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (items) =>
        categories.length === 0 && items.length === 0
          ? []
          : [
              ...categories.map(
                (category): ItemCategoryListSectionData => ({
                  sectionData: category,
                  rowsData: Util.sort(
                    items.filter((i) => i.parent_id === category.id),
                  ).asc((i) => i.position),
                }),
              ),
              {
                sectionData: {id: -1},
                rowsData: Util.sort(
                  items.filter((i) => i.parent_id == null),
                ).asc((i) => i.position),
              } as ItemCategoryListSectionData,
            ],
    },
  )
  const inventoryGroupsQuery = api.inventoryGroups.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const sortCollectionObjectsMutation = useSortCollectionObjectsMutation()
  const deleteCategoryUndoableMutation = useUndoableMutation(
    useDeleteCategoryMutation({timeout: 8000}),
  )
  const itemQuantityGroupsModalRef = useRef<WebUI.DialogInstance>(null)
  const upgradeRequiredAlertRef = useRef<WebUI.DialogInstance>(null)
  const onAddItemRef = useLiveRef(onAddItem)
  const onAddTicketRef = useLiveRef(onAddTicket)
  const deleteItemUndoableMutation = useUndoableMutation(
    useDeleteItemMutation({timeout: 8000}),
  )

  const sortDisabledSectionIds = useMemo(() => [-1], [])

  const deleteCategoryWithUndo = deleteCategoryUndoableMutation.mutateWithUndo
  const ItemCategoryListSectionWithHandlers: WebUI.DataSectionComponentType<
    ItemCategoryListSectionData['sectionData']
  > = useMemo(
    () =>
      React.memo((sectionProps) => (
        <ItemCategoryListSection
          // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
          onDelete={useCallback(() => {
            if (
              sectionProps.section &&
              'name' in sectionProps.section.original
            ) {
              deleteCategoryWithUndo(
                {
                  body: `Category "${sectionProps.section.original.name}" Deleted`,
                },
                {
                  pathParams: {
                    // biome-ignore lint/style/noNonNullAssertion:
                    tabId: urlParams.collection!,
                    categoryId: sectionProps.section.original.id,
                  },
                },
              )
            }
          }, [sectionProps.section?.original.id])}
          {...sectionProps}
        />
      )),
    [deleteCategoryWithUndo, urlParams.collection],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const ItemRowWithHandlers: WebUI.DataRowComponentType<Api.TabItem> = useMemo(
    () =>
      React.forwardRef((itemRowProps, forwardedRef) => (
        <ItemRow
          ref={forwardedRef}
          // biome-ignore lint/style/noNonNullAssertion:
          collection={collectionQuery.data!}
          inventoryGroups={
            inventoryGroupsQuery.data?.filter((ig) =>
              ig.inventory_items.some(
                (ii) => ii.tab_object_id === itemRowProps.row?.original.id,
              ),
            ) ?? []
          }
          onUpdateInventoryGroups={() =>
            itemQuantityGroupsModalRef.current?.show()
          }
          onUpgrade={() => upgradeRequiredAlertRef.current?.show()}
          onMove={() => setItemToMove(itemRowProps.row?.original || null)}
          onDelete={() => {
            const item = itemRowProps.row?.original

            if (item) {
              deleteItemUndoableMutation.mutateWithUndo(
                {
                  body: `Item "${item.name}" Deleted${
                    item.options.recurring?.enabled
                      ? '. All future scheduled recurring payments on this item will stop.'
                      : ''
                  }`,
                },
                {
                  pathParams: {
                    tabId: item.tab_id,
                    itemId: item.id,
                  },
                },
              )
            }
          }}
          {...itemRowProps}
        />
      )),
    [collectionQuery.data, inventoryGroupsQuery.data],
  )

  const AddItemCTAPanelWithHandlers = useMemo(
    () => (emptyStateViewProps: React.ComponentPropsWithoutRef<'div'>) => (
      <AddItemCTAPanel
        onAddItem={onAddItemRef.current}
        onAddTicket={onAddTicketRef.current}
        {...emptyStateViewProps}
      />
    ),
    [],
  )

  if (!collectionQuery.data) {
    return null
  }

  return (
    <>
      <QueryDataSectionList
        ref={listRef}
        className={WebUI.cn(
          'gap-4 py-8 *:mx-auto *:w-full *:max-w-[min(1200px,100%)] [&_.SectionContainer]:gap-2',
          className,
        )}
        query={itemsQuery}
        estimateRowSize={84}
        selectable
        sortDisabledSectionIds={sortDisabledSectionIds}
        SectionListComponent={WebUI.SortableSectionList}
        EmptyStateViewComponent={AddItemCTAPanelWithHandlers}
        EmptyStateSectionViewComponent={ItemListEmptyStateView}
        DataSectionComponent={ItemCategoryListSectionWithHandlers}
        DataRowComponent={ItemRowWithHandlers}
        onOrderChange={(newOrder) => {
          if (newOrder.sectionId == null) {
            sortCollectionObjectsMutation.mutate({
              pathParams: {
                tabId: collectionId,
              },
              body: {
                type: 'categories',
                order: newOrder.orderedRowIds
                  .map(Number)
                  .filter((id) => id > 0),
              },
            })
          } else {
            sortCollectionObjectsMutation.mutate({
              pathParams: {
                tabId: collectionId,
              },
              body: {
                type: 'items',
                parent_id:
                  newOrder.sectionId === '-1'
                    ? null
                    : Number(newOrder.sectionId),
                order: newOrder.orderedRowIds.map(Number),
              },
            })
          }
        }}
        onMoveToSection={(newSourceOrder, newDestOrder) => {
          sortCollectionObjectsMutation.mutate({
            pathParams: {
              tabId: collectionId,
            },
            body: {
              type: 'items',
              parent_id:
                newSourceOrder.sectionId === '-1' ||
                newSourceOrder.sectionId === -1
                  ? null
                  : Number(newSourceOrder.sectionId),
              order: newSourceOrder.orderedRowIds.map(Number),
            },
          })
          sortCollectionObjectsMutation.mutate({
            pathParams: {
              tabId: collectionId,
            },
            body: {
              type: 'items',
              parent_id:
                newDestOrder.sectionId === '-1' || newDestOrder.sectionId === -1
                  ? null
                  : Number(newDestOrder.sectionId),
              order: newDestOrder.orderedRowIds.map(Number),
            },
          })
        }}
      >
        {({listElement, state: listState, dispatch: listDispatch}) => {
          const items = itemsQuery.data?.flatMap((s) => s.rowsData) ?? []
          const groupableItemsCount = items.filter(
            (item) =>
              item.amount_type !== 'open' && !item.options.variants?.enabled,
          ).length

          return (
            <div
              className={WebUI.cn(
                'flex min-h-0 max-w-[100vw] flex-col [&_>_.DataListToolbar_>_.DataListToolbar-inner]:mx-auto [&_>_.DataListToolbar_>_.DataListToolbar-inner]:h-16 [&_>_.DataListToolbar_>_.DataListToolbar-inner]:min-w-max [&_>_.DataListToolbar_>_.DataListToolbar-inner]:flex-[0_0_min(1200px,96%)] [&_>_.DataListToolbar_>_.DataListToolbar-inner]:px-2 sm:[&_>_.DataListToolbar_>_.DataListToolbar-inner]:px-8',
                className,
              )}
              {...restProps}
            >
              {items.length > 0 &&
                (Object.values(listState.selectedItemIdsMap).includes(true) ? (
                  <ItemListBulkActionsToolbar
                    categories={
                      itemsQuery.data?.map((s) => s.sectionData) ?? []
                    }
                    items={items}
                    listState={listState}
                    listDispatch={listDispatch}
                  />
                ) : (
                  <ItemListToolbar
                    onUpdateInventoryGroups={() =>
                      itemQuantityGroupsModalRef.current?.show()
                    }
                    onAddItem={onAddItem}
                    onSearch={(keyword) => {
                      const bestMatchingItem = items.find(
                        Util.fuzzyFilterIterator(keyword, {
                          caseSensitive: false,
                          iterator: (item) => item.name,
                        }),
                      )

                      if (bestMatchingItem) {
                        listRef.current?.scrollToRow(bestMatchingItem.id)
                      }
                      return !!bestMatchingItem
                    }}
                    inventoryGroupsVisible={groupableItemsCount > 1}
                  />
                ))}
              {listElement}
            </div>
          )
        }}
      </QueryDataSectionList>
      {itemToMove && (
        <MoveTabObjectsToAnotherCollectionOrCategoryModal
          objectType="item"
          visible={!!itemToMove}
          selectedItems={[itemToMove]}
          tabObjectIds={[itemToMove.id]}
          collectionId={collectionId}
          onDidMove={() => setItemToMove(null)}
          onDidHide={() => setItemToMove(null)}
        />
      )}
      <ItemQuantityGroupsModal
        ref={itemQuantityGroupsModalRef}
        collectionId={collectionId}
      />
      <UpgradeRequiredAlert ref={upgradeRequiredAlertRef} />
    </>
  )
}

// MARK: – ItemCategoryListSection

interface ItemCategoryListSectionProps
  extends WebUI.DataSectionComponentProps<Api.Category | {id: -1}> {
  onDelete: () => void
}

const ItemCategoryListSection = React.memo(
  ({
    onDelete,
    setSelected,
    section,
    children,
    ...restProps
  }: ItemCategoryListSectionProps) => {
    const content = <div className="px-2 py-4 sm:pr-3 sm:pl-8">{children}</div>

    return !section || !('name' in section.original) ? (
      <WebUI.Card {...restProps}>{content}</WebUI.Card>
    ) : (
      <WebUI.Disclosure className="min-w-0 grow" initialVisible>
        {(disclosure) =>
          'name' in section.original && (
            <WebUI.Card {...restProps}>
              <WebUI.SectionListHeader
                className="items-center"
                dragHandleVisible
                accessoryView={
                  <WebUI.ActionGroup>
                    <CategoryFormPopover
                      disclosure={
                        <WebUI.Action
                          as={WebUI.PopoverDisclosure}
                          icon={<WebUI.PhosphorIcon icon="pencil" />}
                          tooltipVisible={false}
                        >
                          Edit category
                        </WebUI.Action>
                      }
                      collectionId={section.original.tab_id}
                      category={section ? section.original : undefined}
                    />

                    <WebUI.Action
                      icon={<WebUI.PhosphorIcon icon="trash" />}
                      onClick={() => onDelete()}
                    >
                      Delete category
                    </WebUI.Action>
                  </WebUI.ActionGroup>
                }
              >
                <div className="flex flex-col overflow-hidden py-3">
                  <div className="flex h-[calc(72px-theme(spacing.3)*2)] flex-row items-center gap-3">
                    <WebUI.DisclosureButton
                      className={`text-ds-md [&[aria-expanded="true"]_svg]:rotate-90 [&_svg]:transition-transform [&_svg]:duration-100 [&_svg]:ease-linear`}
                      size="default_alt"
                      arrow={false}
                      as={WebUI.IconButton}
                    >
                      <WebUI.PhosphorIcon icon="caret-right-fill" />
                    </WebUI.DisclosureButton>
                    <WebUI.Ellipsis>
                      {section?.original.name ?? ''}
                    </WebUI.Ellipsis>
                  </div>
                  {disclosure.visible &&
                    !!section?.original.description &&
                    Util.stripMarkdown(section.original.description).replaceAll(
                      '\n',
                      '',
                    ).length > 0 && (
                      <WebUI.MarkdownParagraph
                        className="ml-10"
                        id={`item-category-description-${section.id}`}
                        markdown={section.original.description}
                      />
                    )}
                </div>
              </WebUI.SectionListHeader>
              <WebUI.DisclosureContent>
                <WebUI.Separator variant="primary" />
                {
                  /** HACK: accessing children.props.items to know if items is not empty */
                  children &&
                    typeof children === 'object' &&
                    'props' in children &&
                    children.props.items?.length > 0 && (
                      <>
                        <div className="flex flex-row px-2 py-4 sm:pr-3 sm:pl-8">
                          <WebUI.Checkbox
                            state={!!section?.state.selected}
                            onChange={(event) =>
                              setSelected?.(event.target.checked)
                            }
                          />
                        </div>
                        <WebUI.Separator variant="primary" />
                      </>
                    )
                }
                {content}
              </WebUI.DisclosureContent>
            </WebUI.Card>
          )
        }
      </WebUI.Disclosure>
    )
  },
)

// MARK: – ItemListEmptyStateView

const ItemListEmptyStateView = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div
    className={WebUI.cn('ItemListEmptyStateView border py-4', className)}
    {...restProps}
  >
    <h4 className="my-4 text-center text-natural-70">Drag Items Here</h4>
  </div>
)

// MARK: – AddItemCTAPanel

interface AddItemCTAPanelProps extends React.ComponentPropsWithoutRef<'div'> {
  onAddItem: (type: Api.ItemType) => void
  onAddTicket: () => void
}

const AddItemCTAPanel: React.FC<AddItemCTAPanelProps> = ({
  onAddItem,
  onAddTicket,
  className,
  ...restProps
}) => {
  const itemTypeModalRef = useRef<WebUI.DialogInstance>(null)
  return (
    <div
      className={WebUI.cn(
        'mx-auto mt-8 flex w-full flex-col items-center gap-5 overflow-y-auto p-8 text-center',
        className,
      )}
      {...restProps}
    >
      <WebUI.Heading as="h2">
        Want to collect payments?
        <br />
        Add one or more items to your collection page
      </WebUI.Heading>
      <WebUI.Button
        variant="link"
        onClick={() => itemTypeModalRef.current?.show()}
      >
        {' '}
        Learn more about payments
      </WebUI.Button>
      <div className="flex flex-row flex-wrap items-center justify-center gap-8 pt-3">
        <AddItemCta
          iconBefore={
            <img className="h-[4em]" alt="Dollar Tag" src={AddItemLogo} />
          }
          title="Fixed Price"
          description="Great for an online store or for registrations"
          onClick={() => onAddItem('fixed')}
        />
        <AddItemCta
          iconBefore={
            <img className="h-[4em]" alt="Donation Logo" src={DonationLogo} />
          }
          title="Donation"
          description="Donors can decide the amount to contribute"
          onClick={() => onAddItem('donation')}
        />
        <AddItemCta
          title="Ticket"
          description="Scan tickets at your event using our mobile app"
          iconBefore={
            <img className="h-[4em]" alt="Ticket Logo" src={TicketLogo} />
          }
          onClick={onAddTicket}
        />
        <AddItemCta
          iconBefore={
            <img
              className="h-[4em]"
              alt="Recurring Calendar Logo"
              src={RecurringCalendarLogo}
            />
          }
          title="Recurring Plan"
          description="Perfect for monthly giving or dues"
          onClick={() => onAddItem('recurring')}
        />
      </div>

      <ItemTypesModal ref={itemTypeModalRef} />
    </div>
  )
}

// MARK: - AddItemCta

interface AddItemCtaProps
  extends React.ComponentPropsWithoutRef<typeof WebUI.Button> {
  title: string
  description: string
}

const AddItemCta = React.forwardRef<HTMLButtonElement, AddItemCtaProps>(
  ({title, description, className, ...restProps}, forwardedRef) => (
    <WebUI.Button
      className={WebUI.cn(
        '!rounded-[10px] w-[214px] gap-3 text-wrap bg-natural-100 shadow-z4 aria-orientation-vertical:px-6 aria-orientation-vertical:pt-8 aria-orientation-vertical:pb-4',
        className,
      )}
      variant="secondary"
      orientation="vertical"
      ref={forwardedRef}
      {...restProps}
    >
      <WebUI.Text className="text-ds-base leading-compact">{title}</WebUI.Text>
      <WebUI.Text className="whitespace-break-spaces font-light text-ds-sm">
        {description}
      </WebUI.Text>
    </WebUI.Button>
  ),
)

// MARK: - ItemTypesModal

const ItemTypesModal = React.forwardRef<WebUI.DialogInstance, WebUI.ModalProps>(
  ({initialVisible = false, className, ...restProps}, forwardedRef) => (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Item Types View"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:max-w-screen-lg [&_>_.ModalContentView]:overflow-auto',
        className,
      )}
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.AlertHeader>Item Types</WebUI.AlertHeader>
          <div className="grid grid-cols-1 justify-center gap-8 px-8 py-9 md:grid-cols-2">
            <ItemTypeCard onNavigate={() => dialog.hide()} type="fixed" />
            <ItemTypeCard onNavigate={() => dialog.hide()} type="donation" />
            <ItemTypeCard onNavigate={() => dialog.hide()} type="ticket" />
            <ItemTypeCard onNavigate={() => dialog.hide()} type="recurring" />
          </div>
        </>
      )}
    </WebUI.Modal>
  ),
)

// MARK - ItemTypeCard

interface ItemTypeCardProps extends React.ComponentPropsWithoutRef<'div'> {
  type: Api.ItemType
  onNavigate: () => void
}

const ItemTypeCard: React.FC<ItemTypeCardProps> = ({
  type,
  onNavigate,
  className,
  ...restProps
}) => {
  const navigate = useNavigate()
  return (
    <div
      className={WebUI.cn(
        'xl:max-w[476px] flex flex-col items-start gap-4 rounded-[10px] px-6 py-8 text-gray800 shadow-z3',
        className,
      )}
      {...restProps}
    >
      <WebUI.PageHeader
        className="[&_.PageHeader-heading]:font-bold·[&_.PageHeader-heading]:text-ds-lg·[&_.PageHeader-subheading]:font-normal·[&_img]:h-16"
        graphics={ITEM_TYPE_CONTENT_MAP[type].icon}
        subheading={ITEM_TYPE_CONTENT_MAP[type].subheading}
      >
        {ITEM_TYPE_CONTENT_MAP[type].heading}
      </WebUI.PageHeader>
      <WebUI.Text className="font-light">
        {ITEM_TYPE_CONTENT_MAP[type].description}
      </WebUI.Text>
      <WebUI.Button
        className="mt-auto w-[200px]"
        onClick={() => {
          navigate(type === 'ticket' ? 'add-ticket' : `add-item?type=${type}`)
          onNavigate()
        }}
      >
        {ITEM_TYPE_CONTENT_MAP[type].ctaText}
      </WebUI.Button>
    </div>
  )
}

const ITEM_TYPE_CONTENT_MAP = {
  fixed: {
    heading: 'Fixed Price',
    subheading: 'Great for an online store or for registrations',
    description:
      'Our Fixed Price item offers a set price for almost anything: goods, services, events! It’s ideal for straightforward transactions such as online purchases or registrations and it comes with lots of settings including the ability to add item variations and limit and track quantities.',
    ctaText: 'Add Fixed Price Item',
    icon: <img src={AddItemIcon} alt="Fixed Price" />,
  },
  donation: {
    heading: 'Donation',
    subheading: 'Donors decide the amount to contribute',
    description:
      'Our Donation item allows donors to contribute any amount they choose (with the option to set a suggested amount). You also have the option to show how much you’ve collected for a specific item, set an item-specific fundraising goal, and display tax deductible information.',
    ctaText: 'Add Donation Item',
    icon: <img src={DonationLogo} alt="Donation" />,
  },
  ticket: {
    heading: 'Tickets',
    subheading: 'Seamless check in with scannable QR codes',
    description:
      'Our Ticket item enables easy event management and allows more event-specific customizations including date, time, and per-attendee ticket details. It also sends payers emailed QR Code tickets, resulting in seamless check in, allowing organizers to scan tickets using our mobile app.',
    ctaText: 'Add Ticket Item',
    icon: <img className="h-[4em]" alt="Ticket" src={TicketLogo} />,
  },
  recurring: {
    heading: 'Recurring Plan',
    subheading: 'Perfect for monthly giving and dues',
    description:
      'Our Recurring Plan item is perfect for regular contributions or dues. You can create customizable payment plans with fixed or donation amounts and can set both the frequency and duration of payments. Payers love the convenience—they pay once and have the rest happen automatically.',
    ctaText: 'Add Recurring Plan Item',
    icon: <img src={RecurringCalendarLogo} alt="Repeating Calendar" />,
  },
}
