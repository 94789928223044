import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'

import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'
import {CheckoutSteppedPanel} from './CheckoutSteppedPanel'
import {PayerBrandKitColors} from '@cheddarup/core'

interface ShippingMethodPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  brandKitColors: PayerBrandKitColors
}
export const ShippingMethodPanel: React.FC<ShippingMethodPanelProps> = ({
  brandKitColors,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const [updateCartMutation, updateCartAsync] = useEnhancedUpdateCartMutation()

  const shippingMethod = cart?.shippingInfo.shippingMethod ?? 'toMe'
  const isShippingFree =
    cart?.shipping.toMe != null && cart.shipping.toMe < 0.01

  if (
    !publicCollection.shippingOptions.shipToEnabled ||
    !publicCollection.shippingOptions.localPickupEnabled
  ) {
    return null
  }

  return (
    <CheckoutSteppedPanel
      step="address"
      heading="Delivery Method"
      {...restProps}
    >
      {({isActive, isCompleted}, flowActions) =>
        updateCartMutation.isPending ? (
          <WebUI.Loader size="2em" />
        ) : isActive ? (
          <>
            <WebUI.RadioGroup
              aria-label="Shipping method select"
              className="[&_.Radio]:has-[:disabled]:opacity-100"
              name="method"
              state={shippingMethod}
              onChange={(event) => {
                updateCartAsync({
                  body: {
                    shippingMethod:
                      event.target.value === 'toMe' ? 'toMe' : 'localPickup',
                    shipTo: cart?.shippingInfo.shipTo ?? undefined,
                  },
                })

                if (event.target.value === 'localPickup') {
                  flowActions.proceed()
                }
              }}
            >
              <WebUI.Radio
                checkedRadioIconStyles={{
                  backgroundColor: brandKitColors.secondaryButton,
                }}
                value="toMe"
              >
                <WebUI.Ellipsis className="whitespace-nowrap">
                  Ship to Me{' '}
                  {isShippingFree ? (
                    <span>
                      <span className="line-through">
                        (
                        {Util.formatAmount(
                          publicCollection.shippingOptions.cost ?? 0,
                        )}
                        )
                      </span>{' '}
                      <span className="text-orange-50">Free!</span>
                    </span>
                  ) : (
                    <span>{`(${Util.formatAmount(
                      publicCollection.shippingOptions.cost ?? 0,
                    )})`}</span>
                  )}
                </WebUI.Ellipsis>
              </WebUI.Radio>
              <WebUI.Radio
                checkedRadioIconStyles={{
                  backgroundColor: brandKitColors.secondaryButton,
                }}
                value="localPickup"
              >
                Local Pickup (free)
              </WebUI.Radio>
            </WebUI.RadioGroup>

            {shippingMethod === 'localPickup' && (
              <LocalPickupInstructionsWell />
            )}
          </>
        ) : isCompleted ? (
          <WebUI.VStack className="gap-2">
            <DeliveryMethodLabel />
            {shippingMethod === 'localPickup' && (
              <LocalPickupInstructionsWell />
            )}
            <WebUI.Button
              className="text-ds-sm"
              variant="link"
              onClick={() => flowActions.setActiveStep('address')}
            >
              Change
            </WebUI.Button>
          </WebUI.VStack>
        ) : null
      }
    </CheckoutSteppedPanel>
  )
}

// MARK: – DeliveryMethodLabel

const DeliveryMethodLabel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'span'>) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()

  const isShippingFree =
    cart?.shipping.toMe != null && cart.shipping.toMe < 0.01

  return (
    <WebUI.Ellipsis
      className={WebUI.cn('whitespace-nowrap', className)}
      {...restProps}
    >
      {cart?.shippingInfo.shippingMethod === 'localPickup' ? (
        'Local Pickup (free)'
      ) : (
        <>
          Ship to Me{' '}
          {isShippingFree ? (
            <span>
              <span className="line-through">
                ({Util.formatAmount(publicCollection.shippingOptions.cost ?? 0)}
                )
              </span>{' '}
              <span className="text-orange-50">Free!</span>
            </span>
          ) : (
            <span>{`(${Util.formatAmount(
              publicCollection.shippingOptions.cost ?? 0,
            )})`}</span>
          )}
        </>
      )}
    </WebUI.Ellipsis>
  )
}

// MARK: – LocalPickupInstructionsWell

const LocalPickupInstructionsWell = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const {publicCollection} = usePublicCollection()

  if (!publicCollection.shippingOptions.localPickupInstructions) {
    return null
  }

  return (
    <WebUI.VStack
      className={WebUI.cn('rounded bg-backgroundSecondary p-3', className)}
      {...restProps}
    >
      <WebUI.Text className="text-ds-sm">
        {publicCollection.shippingOptions.localPickupInstructions}
      </WebUI.Text>
    </WebUI.VStack>
  )
}
