// @ts-ignore

import {z} from '@cheddarup/util'

import {paymentStatusSchema} from './common'

export const recurringPaymentInvoicePaymentSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  stripe_payment_source_id: z.string(),
  paid: z.boolean(),
  status: paymentStatusSchema,
  total: z.number(),
})

export const recurringOptionsSchema = z.object({
  repeatInterval: z.string(),
  repeatIntervalSeconds: z.number(),
  start: z.object({
    type: z.enum(['date', 'string', 'first_payment']),
    date: z.string().optional(),
  }),
  ends: z.object({
    type: z.enum(['never', 'payment_count']).or(z.string()),
    payment_count: z.number().optional(),
  }),
})

export const recurringPaymentContractSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullish(),
  metadata: z.object({
    amount_pennies: z.number(),
    field_values: z.any().array(),
    initiating_payment: z.number(),
    next_invoice: z.string(),
    scheduled_start: z.string(),
    total_invoices: z.number(),
    due_date: z.string().nullish(),
  }),
  options: recurringOptionsSchema,
  recurring_payment_invoices: z
    .object({
      id: z.number(),
      recurring_payment_contract_id: z.number(),
      status: z.enum(['paid', 'scheduled']),
      created_at: z.string(),
      updated_at: z.string(),
      metadata: z.object({
        paid_on: z.string(),
        due_date: z.string(),
        next_attempt: z.string().nullish(),
        total_attempts: z.number(),
      }),
      payments: recurringPaymentInvoicePaymentSchema.array(),
    })
    .array(),
  status: z.enum(['error', 'cancelled', 'completed']),
  stripe_source: z.string(),
  tab_item: z.object({
    id: z.number(),
    name: z.string(),
    tab: z.object({
      name: z.string(),
      slug: z.string(),
      user: z.object({name: z.string(), email: z.string()}),
    }),
  }),
  'first_payment_at_checkout?': z.boolean(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type RecurringPaymentContract = z.infer<
      typeof recurringPaymentContractSchema
    >
    // @ts-ignore
    type RecurringPaymentInvoicePayment = z.infer<
      typeof recurringPaymentInvoicePaymentSchema
    >
    // @ts-ignore
    type RecurringOptions = z.infer<typeof recurringOptionsSchema>
  }
}
