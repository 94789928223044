// @ts-ignore

import {z} from '@cheddarup/util'

export const stripeStatusSchema = z.enum(['pending', 'paid', 'confirmed'])
export const withdrawalStatusSchema = z.enum(['pending', 'approved'])

export const tangoCardSchema = z.object({
  detail: z.object({
    from: z.string(),
    message: z.string(),
    recipientName: z.string(),
    recipientEmail: z.string(),
    redemptionEmailSent: z.boolean().optional(),
  }),
  redemption_email: z
    .object({
      sent_to: z.string(),
      status: z.string(),
      created_at: z.string(),
    })
    .optional(),
})

export const withdrawalSchema = z.object({
  id: z.number(),
  tab_id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  amount: z.number(),
  status: withdrawalStatusSchema,
  note: z.string().nullish(),
  stripe_bank_account_id: z.string().nullish(),
  statement_descriptor: z.string().nullish(),
  stripe_status: stripeStatusSchema,
  payout_method: z.enum(['standard', 'tango']),
  tango_card: tangoCardSchema.optional(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type StripeStatus = z.infer<typeof stripeStatusSchema>
    // @ts-ignore
    type WithdrawalStatus = z.infer<typeof withdrawalStatusSchema>
    // @ts-ignore
    type Withdrawal = z.infer<typeof withdrawalSchema>
    // @ts-ignore
    type TangoCard = z.infer<typeof tangoCardSchema>
  }
}
